import React, { useState, useEffect, useCallback } from "react";
import { Card, Button } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import ClientMasterComponent from "./ClientMasterComponent";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
// Assuming you'll create this component

export default function ClientMaster() {
  const [clientData, setClientData] = useState([]);
  const [editClientData, setEditClientData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const { setIsLoading } = useLoadingContext();

  // useEffect(() => {
  //   getClientData();
  //   UserPermissions();
  // }, []);

  const UserPermissions = useCallback(async () => {
    await CheckRoleTemplatePermissions(
      "ClientMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  }, [setIsLoading]);

  // Fetch all clients
  const getClientData = useCallback(async () => {
    await getAllData("ClientMaster/AllClients", setIsLoading, setClientData);
  }, [setIsLoading]);

  useEffect(() => {
    getClientData();
    UserPermissions();
  }, [getClientData, UserPermissions]);

  const getSingleClient = async (id) => {
    await getSingleData(
      `ClientMaster/SingleClient?ClientMasterID=${id}`,
      setEditClientData,
      setIsLoading
    );
  };

  // Delete client
  const deleteClient = async (id) => {
    await deleteData(
      `ClientMaster/DeleteClient?ClientMasterID=${id}`,
      getClientData,
      setIsLoading
    );
  };

  const exportToExcel = () => {
    setIsLoading(true);
    setTimeout(() => {
      const fileName = "ClientData.xlsx";

      // Format the data to be exported
      const formattedData = clientData.map((item) => ({
        ClientName: item.clientName,
        MobileNumber: item.clientMobileNo,
        EmailID: item.clientEmailID,
      }));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Client Data");

      worksheet.columns = [
        { header: "Client Name", key: "ClientName", width: 30 },
        { header: "Mobile Number", key: "MobileNumber", width: 20 },
        { header: "Email ID", key: "EmailID", width: 30 },
      ];

      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });
      setIsLoading(false);
    }, 1000);
  };

  const columnDefs = [
    createColumnDef("Client Name", "clientName", 150),
    createColumnDef("Mobile Number", "clientMobileNo", 150),
    createColumnDef("Email ID", "clientEmailID", 150),

    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "clientMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleClient(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "clientMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteClient(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Client</span>
          </Card.Header>
          <Card.Body>
            <ClientMasterComponent
              returnID={getClientData}
              editedData={editClientData}
            />
          </Card.Body>
        </Card>
      )}

      <Card>
        <Card.Header className="d-flex justify-content-between">
          <span>Client List</span>
          <Button
            type="button"
            variant="success"
            className="mb-20px mr-4"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={clientData}
            columnDefs={columnDefs}
            height={300}
            paginationPageSize={100}
          />
        </Card.Body>
      </Card>
    </>
  );
}
