import { Children } from "react";

const commonColumnProperties = {
  sortable: true,
  filter: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  wrapText: true,
  autoHeight: true,
  flex: 1,
  headerClass: "widthTableColum",
};

export const createColumnDef = (
  headerName,
  field,
  minWidth,
  customProps = {}
) => ({
  headerName,
  field,
  minWidth,
  ...commonColumnProperties,
  ...customProps,
});

export const createColumnDefButton = (
  headerName,
  field,
  buttonRenderer,
  width
) => ({
  headerName: headerName,
  field: field,
  width: width ? width : 100,
  headerClass: "center-text widthTableColum",
  cellStyle: { textAlign: "center" },
  cellRenderer: ({ value }) => buttonRenderer(value),
  pinned: "right",
});

