import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import SMSApiComponent from "../SMSApi/SMSApiComponent";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import GlobalCheckbox from "../../Common/GlobalCheckbox";
import {
    getAllData,
} from "../../Utils/CallApiUtils";
import SMSTemplateFixComponent from "../SMSTemplateFix/SMSTemplateFixComponent";
import TextArea from "../../Common/TextArea";
import GlobalDDComponentWithOutPlus from "../../Common/GlobalDDComponentWithOutPlus";
import { showMessage } from "../../Utils/showMessageUtils";

const emptySMSTemplate = {
    SMSTemplateID: 0,
    SMSTemplateName: "",
    SMSText: "",
    SMSApiID: 0,
    CCNumber: "",
    IsActive: "",
    SMSTemplateFixID: 0,
};

export default function SMSTemplateComponent({ returnID, editedData, clearData, onClose }) {
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(emptySMSTemplate);
    const SMSTemplateFormRef = useRef(null);
    const { setIsLoading } = useLoadingContext();
    const [
        smsApiDDValidationError,
        setSMSApiDDValidationError,
    ] = useState(false);
    const [smsParameter, setSMSParameter] = useState([]);
    const [
        smsTemplateFixDDValidationError,
        setSMSTemplateFixDDValidationError,
    ] = useState(false);
    useEffect(() => {
        getAllParametersName();
    }, []);

    // Fetch all document types
    const getAllParametersName = async () => {
        
        await getAllData(
            "SMSTemplate/AllSMSParameter",
            setIsLoading,
            setSMSParameter
        );
    };

    useEffect(() => {
        if (editedData) {
            setFormData({
                SMSTemplateID: editedData.smsTemplateID,
                SMSApiID: editedData.smsApiID,
                SMSTemplateName: editedData.smsTemplateName,
                SMSText: editedData.smsText,
                CCNumber: editedData.ccNumber,
                IsActive: editedData.isActive,
                SMSTemplateFixID: editedData.smsTemplateFixID,
            });
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }, [editedData]);

    useEffect(() => {
        if (clearData === false) {
            resetForm();
        }
    }, [clearData]);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === "checkbox" ? checked : value;
        setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
    };

    const insertSMSTemplate = (event) => {
        
        setValidated(true);
        setIsLoading(true);
        const form = SMSTemplateFormRef.current;
        if (!form.checkValidity()) {
            setIsLoading(false);
            return;
        } else {
            const data = {
                SMSTemplateID: formData?.SMSTemplateID || 0,
                SMSApiID: formData.SMSApiID,
                SMSTemplateName: formData.SMSTemplateName,
                SMSText: formData.SMSText,
                CCNumber: formData.CCNumber,
                IsActive: formData.IsActive,
                smsTemplateFixID: formData.SMSTemplateFixID
            };

            const endpoint =
                formData.SMSTemplateID === 0
                    ? "SMSTemplate/InsertSMSTemplate"
                    : "SMSTemplate/EditSMSTemplate";

            api[formData.SMSTemplateID === 0 ? "post" : "patch"](endpoint, data)
                .then((result) => {
                    showMessage(result.data.statusCode,result.data.message)
                    if (result.status === 200) {
                        setValidated(false);
                        if (returnID) {
                            returnID(result.data.data);
                        }
                    } 
                })
                .catch((error) => {
                    console.log(error);
                    showMessage(400,"An error occurred")
                })
                .finally(() => {
                    resetForm();
                    setIsLoading(false);
                });
        }
    };

    const resetForm = () => {
        setFormData(emptySMSTemplate);
    };

    return (
        <>
            <GlobalFormTagWithSubmit validated={validated} refName={SMSTemplateFormRef} formName="SMSTemplateForm">
                <Row>
                    <Col md={12} lg={12} sm={12} className="mb-4">
                        {
                            smsParameter.map((item) => (
                                <label key={item.smsParameterName} className="hastags">{item.smsParameterName}</label>
                            ))
                        }
                    </Col>
                </Row>
                <Row>
                    <GlobalDDComponent
                        ColSize={{ sm: 12, md: 6, lg: 3 }}
                        formLabel="SMS API Name"
                        apiEndpoint="SMSApi/AllSMSApi"
                        valueOfDD="smsApiID"
                        labelOfDD="smsCompanyName"
                        onSelectOption={(smsApiID) => {
                            setFormData((prevData) => ({
                                ...prevData,
                                SMSApiID: smsApiID,
                            }));
                            setSMSApiDDValidationError(false);
                        }}
                        selectedOptionOfComponent={formData.SMSApiID}
                        ValidationError={smsApiDDValidationError}
                        offcanvasTitle="Add Sms API Name"
                        offcanvasBodyComponent={<SMSApiComponent />}
                    />
                    <GlobalDDComponentWithOutPlus
                        ColSize={{ sm: 12, md: 6, lg: 3 }}
                        formLabel="SMS Template Header Name"
                        apiEndpoint="SMSTemplateFix/AllSMSTemplateFixs"
                        valueOfDD="smsTemplateFixID"
                        labelOfDD="smsTemplateFixName"
                        onSelectOption={(smsTemplateFixID) => {
                            setFormData((prevData) => ({
                                ...prevData,
                                SMSTemplateFixID: smsTemplateFixID,
                            }));
                            setSMSTemplateFixDDValidationError(false);
                        }}
                        selectedOptionOfComponent={formData.SMSTemplateFixID}
                        ValidationError={smsTemplateFixDDValidationError}
                    />
                    <InputGroupWithIcon
                        ColSize={{ sm: 12, md: 6, lg: 3 }}
                        Label="Template Name"
                        icon="fas fa-tag"
                        type="text"
                        name="SMSTemplateName"
                        placeholder="Enter Template Name"
                        value={formData.SMSTemplateName}
                        onChange={handleInputChange}
                        required
                    />
                    <InputGroupWithIcon
                        ColSize={{ sm: 12, md: 6, lg: 3 }}
                        Label="CC Number"
                        icon="fas fa-mobile"
                        type="text"
                        name="CCNumber"
                        placeholder="Enter CC Number"
                        value={formData.CCNumber}
                        onChange={handleInputChange}
                    />
                </Row>
                <Row>
                    <TextArea
                        ColSize={{ sm: 12, md: 12, lg: 12 }}
                        rows={5}
                        Label="SMS Text"
                        icon="fas fa-pencil-alt"
                        name="SMSText"
                        placeholder="SMS Text"
                        value={formData.SMSText}
                        onChange={handleInputChange}
                    />
                </Row>
                <Row>
                    <GlobalCheckbox
                        label="Active"
                        name="IsActive"
                        checked={formData.IsActive}
                        onChange={handleInputChange}
                    />
                </Row>
                <OnClickSubmitButtonOfForm onClick={insertSMSTemplate} HeadID={formData?.SMSTemplateID} />
            </GlobalFormTagWithSubmit>
        </>
    );
}
