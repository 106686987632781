import React, { useState, useEffect,useCallback } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import TPAMasterComponent from "./TPAMasterComponent";
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function TPAMaster() {
  const [tpaData, setTpaData] = useState([]);
  const [editTpaData, setEditTpaData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  // useEffect(() => {
  //   getTpaData();
  //   UserPermissions();
  // }, []);

  // const UserPermissions = async () => {
  //   await CheckRoleTemplatePermissions(
  //     "TPAMaster",
  //     setRoleTemplatePermissions,
  //     setIsLoading
  //   );
  // };
  // // Fetch all TPAs
  // const getTpaData = async () => {
  //   await getAllData("TPAMaster/AllTPAMasters", setIsLoading, setTpaData);
  // };

  const getTpaData = useCallback(async () => {
    await getAllData("TPAMaster/AllTPAMasters", setIsLoading, setTpaData);
  }, [setIsLoading]);

  const UserPermissions = useCallback(async () => {
    await CheckRoleTemplatePermissions(
      "TPAMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  }, [setIsLoading]);

  useEffect(() => {
    getTpaData();
    UserPermissions();
  }, [getTpaData, UserPermissions]);

  const getSingleTpa = async (id) => {
    await getSingleData(
      `TPAMaster/SingleTPAMaster?TPAMasterID=${id}`,
      setEditTpaData,
      setIsLoading
    );
  };

  // Delete TPA
  const deleteTpa = async (id) => {
    await deleteData(
      `TPAMaster/DeleteTPAMaster?TPAMasterID=${id}`,
      getTpaData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("TPAMaster Name", "tpaMasterName", 200),
    createColumnDef("TPAMaster Email", "tpaMasterEmail", 500),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "tpaMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleTpa(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "tpaMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteTpa(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New TPA</span>
          </Card.Header>
          <Card.Body>
            <TPAMasterComponent
              returnID={getTpaData}
              editedData={editTpaData}
            />
          </Card.Body>
        </Card>
      )}

      <Card>
        <Card.Header>
          <span>TPA List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={tpaData} columnDefs={columnDefs} height={500} paginationPageSize={25}/>
        </Card.Body>
      </Card>
    </>
  );
}
