import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import UserKeys from "../UserKeys.json";

const { DEFAULT_KEY, DEFAULT_IV, DEFAULT_PROJECT } = UserKeys;

// Utility functions for encryption and decryption
const parseUtf8 = (text) => CryptoJS.enc.Utf8.parse(text);
const stringifyUtf8 = (bytes) => bytes.toString(CryptoJS.enc.Utf8);
const encryptAES = (text, key, iv) => {
  try {
    return CryptoJS.AES.encrypt(text, parseUtf8(key), {
      iv: parseUtf8(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  } catch (error) {
    console.error("Encryption error:", error);
  }
  return null;
};
const decryptAES = (ciphertext, key, iv) => {
  try {
    return stringifyUtf8(CryptoJS.AES.decrypt(ciphertext, parseUtf8(key), {
      iv: parseUtf8(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }));
  } catch (error) {
    console.error("Decryption error:", error);
  }
  return null;
};

// Utility functions for serialization and deserialization
const serializeValue = (value) => {
  const type = typeof value;
  return JSON.stringify({ type, value });
};

const deserializeValue = (serializedValue) => {
  const { type, value } = JSON.parse(serializedValue);
  switch (type) {
    case 'boolean':
      return value;
    case 'number':
      return parseFloat(value);
    case 'string':
      return value;
    default:
      return value;
  }
};

// Utility function to hash the header
const hashHeader = (header) => {
  return CryptoJS.SHA256(`${DEFAULT_PROJECT}_${header}`).toString(CryptoJS.enc.Base64).substring(0, 12); // Shorten to 12 characters
};

// Core functions for encryption, decryption, and removal
export const encryptAndSetItemCookiesStorage = (header, value) => {
  const hashedHeader = hashHeader(header);
  const serializedValue = serializeValue(value);
  const encryptedValue = encryptAES(serializedValue, DEFAULT_KEY, DEFAULT_IV);
  Cookies.set(hashedHeader, encryptedValue, { expires: 1 });
};

export const decryptAndGetItemCookiesStorage = (header) => {
  const hashedHeader = hashHeader(header);
  const encryptedValue = Cookies.get(hashedHeader);
  if (!encryptedValue) {
    return null;
  }
  const decryptedValue = decryptAES(encryptedValue, DEFAULT_KEY, DEFAULT_IV);
  return deserializeValue(decryptedValue);
};

export const removeEncryptCookiesStorage = (header) => {
  const hashedHeader = hashHeader(header);
  Cookies.remove(hashedHeader);
};
