import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyAgentType = {
  agentMasterID: 0,
  agentMasterName: "",
  agentMasterMobileNo: "",
  agentMasterEmail: "",
};

export default function AgentMasterComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyAgentType);
  const agentFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        agentMasterID: editedData.agentMasterID,
        agentMasterName: editedData.agentMasterName,
        agentMasterMobileNo: editedData.agentMasterMobileNo,
        agentMasterEmail: editedData.agentMasterEmail,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertAgent = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = agentFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        AgentMasterID: formData?.agentMasterID || 0,
        AgentMasterName: formData.agentMasterName,
        AgentMasterMobileNo: formData.agentMasterMobileNo,
        AgentMasterEmail: formData.agentMasterEmail,
      };

      const endpoint =
        formData.agentMasterID === 0
          ? "AgentMaster/InsertAgent"
          : "AgentMaster/EditAgent";

      api[formData.agentMasterID === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode , result.data.message);
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          showMessage(400 , "An error occurred");
          console.log(error);
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyAgentType);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={agentFormRef}
        formName="AgentForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Agent Name"
            icon="fas fa-user"
            type="text"
            name="agentMasterName"
            placeholder="Enter Agent Name"
            value={formData.agentMasterName}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Mobile Number"
            icon="fas fa-phone"
            type="text"
            name="agentMasterMobileNo"
            placeholder="Enter Mobile Number"
            value={formData.agentMasterMobileNo}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Email"
            icon="fas fa-envelope"
            type="email"
            name="agentMasterEmail"
            placeholder="Enter Email"
            value={formData.agentMasterEmail}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={insertAgent}
          HeadID={formData?.agentMasterID}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
