import React from "react";
import { Button } from "react-bootstrap";

export default function OnClickSubmitButtonOfForm({ HeadID, onClick }) {
  return (
    <div className="margin_top10 items-end flex justify-end">
      <Button onClick={onClick} variant="primary" className="mb-20px btn-tapti">
        {HeadID > 0 ? "Update" : "Save"}
      </Button>
    </div>
  );
}
