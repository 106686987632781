import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form, InputGroup, Button } from "react-bootstrap";
import api from "../../Utils/api";
import { useLoadingContext } from "../../Loading/LoadingContext";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import { getCurrentDate } from "../../Utils/commonFunction";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import ImageAddAndPreview from "../../Common/ImageAddAndPreview";
import TextArea from "../../Common/TextArea";
import UserSetting from "../../UserSettings.json";
import { format } from "date-fns";
import CustomCheckbox from "../../Common/CustomCheckbox";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyEndorsement = {
  EndorsementID: 0,
  EndorsementName: "",
  policyID: 0,
  EndorsementDate: getCurrentDate(),
  Remarks: "",
  NetAmount: "",
  ServiceTax: 18,
  PremiumAmt: "",
  policyNo: "",
  clientName: "",
  ServiceTaxAmount: "",
  holderName: "",
  SendWhatsAppSMS: true,
  SendTextSMS: true,
  SendEmail: true,
};

export default function EndorsementComponent({
  editedData,
  returnID,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const EndorsementFormRef = useRef(null);
  const [formData, setFormData] = useState(emptyEndorsement);
  const { setIsLoading } = useLoadingContext();
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedImage, setSelectedImage] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [showImage, setShowImage] = useState(false); // Key for refreshing the dropdown

  useEffect(() => {
    if (editedData) {
      setFormData((prevData) => ({
        ...prevData,
        EndorsementID: editedData.endorsementID,
        EndorsementName: editedData.endorsementName,
        policyID: editedData.policyID,
        EndorsementDate: format(editedData.endorsementDate, "yyyy-MM-dd"),
        Remarks: editedData.remarks,
        NetAmount: editedData.netAmount,
        ServiceTax: editedData.serviceTax,
        PremiumAmt: editedData.premiumAmt,
        ServiceTaxAmount: editedData.serviceTaxAmount,
        policyNo: editedData.policyNo,
        clientName: editedData.clientName,
        holderName: editedData.policyHolderName,
      }));
      {
        editedData.imagePath &&
          setImagePreview([UserSetting.imgURL + editedData.imagePath]);
        setShowImage(true);
      }
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      reset();
    }
  }, [clearData]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchEndorsementData(event.target.value);
    }
  };

  // Fetch courier data based on policy number
  const fetchEndorsementData = (pno) => {
    const policyNo = formData.policyNo;
    if (!policyNo) {
      showMessage(400,"Please enter a valid policy number.");
      return;
    }

    setIsLoading(true);

    api
      .get(`Courier/FindCourierDataByPolicyNo?PolicyNo=${policyNo}`)
      .then((result) => {
        const data = result.data;
        if (!data.flag) {
        showMessage(400,data.message);
          setIsLoading(false);
          return;
        }

        const courierDetails = data.data;
        setFormData((prevData) => ({
          ...prevData,
          policyID: courierDetails.policyID,
          policyNo: courierDetails.policyNo,
          clientName: courierDetails.clientName,
          holderName: courierDetails.policyHolderName,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
       showMessage(400,"An error occurred while fetching courier details.");
        setIsLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: inputValue };
      if (name === "NetAmount" || name === "ServiceTax") {
        return calculateTaxAndPremium(updatedData);
      }
      return updatedData;
    });
  };

  const calculateTaxAndPremium = (data) => {
    const totalPrice = parseFloat(data.NetAmount) || 0;
    const gstRate = parseFloat(data.ServiceTax) || 0;
    const gstRateDecimal = gstRate / 100;
    // Calculate the Pre-GST Price
    const preGstPrice = totalPrice / (1 + gstRateDecimal);

    const gstAmount = totalPrice - preGstPrice;

    return {
      ...data,
      ServiceTaxAmount: gstAmount.toFixed(2),
      PremiumAmt: preGstPrice.toFixed(2),
    };
  };

  const insertEndorsement = (event) => {
    
    setValidated(true);
    setIsLoading(true);
    event.preventDefault();
    const form = EndorsementFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("EndorsementID", formData?.EndorsementID || 0);
      form_Data.append("EndorsementName", formData.EndorsementName);
      form_Data.append("PolicyID", formData.policyID);
      form_Data.append("EndorsementDate", formData.EndorsementDate);
      form_Data.append("Remarks", formData.Remarks);
      form_Data.append("PremiumAmt", formData.PremiumAmt);
      form_Data.append("ServiceTax", formData.ServiceTax);
      form_Data.append("NetAmount", formData.NetAmount);
      form_Data.append("ServiceTaxAmount", formData.ServiceTaxAmount);
      form_Data.append("SendWhatsAppSMS", formData.SendWhatsAppSMS);
      form_Data.append("SendTextSMS", formData.SendTextSMS);
      form_Data.append("SendEmail", formData.SendEmail);
      form_Data.append("image", selectedImage);

      // for (let i = 0; i < selectedImage.length; i++) {
      //   form_Data.append(`image[${i}]`, selectedImage[i]);
      // }

      const endpoint =
        formData.EndorsementID === 0
          ? "Endorsement/InsertEndorsement"
          : "Endorsement/EditEndorsement";

      api[formData.EndorsementID === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          reset();
          setValidated(false);
          setIsLoading(false);
        });
    }
  };

  const reset = () => {
    setFormData(emptyEndorsement);
    setImagePreview(null);
    setSelectedImage([]);
    setShowImage(false);
    setRefreshKey(refreshKey + 1);
  };

  const handleImageUpload = async (imageFile) => {
    setSelectedImage(imageFile);
  };
  return (
    <>
      {/* <Card>
        <Card.Body>
          <form ref={EndorsementFormRef} noValidate validated={validated}> */}
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={EndorsementFormRef}
        formName="EndorsementForm"
      >
        <Row>
          {/* <InputGroupWithIcon
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            Label="Policy No"
            icon="fas fa-file-alt"
            type="text"
            name="policyNo"
            placeholder="Enter Policy No"
            value={formData.policyNo}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            required
          /> */}
          <Col md={6} sm={12} lg={3}>
            <Form.Group controlId="PolicyNo">
              <Form.Label>PolicyNo:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-file-alt"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="policyNo"
                  placeholder="Enter Policy No"
                  value={formData.policyNo}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  className="RemoveBorderRadiusTextBox"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter policyNo
                </Form.Control.Feedback>
                <InputGroup.Text id="basic-addon1" style={{ padding: "1px" }}>
                  <Button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#20336b",
                      color: "white",
                      borderRadius: "0px",
                    }}
                    variant="primary"
                    onClick={fetchEndorsementData}
                  >
                    <i className="fas fa-search"></i>
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            Label="Client Name"
            icon="fas fa-user"
            type="text"
            name="clientName"
            placeholder="Enter Client Name"
            value={formData.clientName}
            onChange={handleInputChange}
            disabled
          />
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            Label="Holder Name"
            icon="fas fa-user"
            type="text"
            name="holderName"
            placeholder="Enter Holder Name"
            value={formData.holderName}
            onChange={handleInputChange}
            disabled
          />
          <InputGroupWithIcon
            Label="Endorsement Date"
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            icon="far fa-calendar-alt"
            type="date"
            name="EndorsementDate"
            value={formData.EndorsementDate}
            onChange={handleInputChange}
            required
          />
        </Row>
        <InputGroupWithIcon
          Label="Endorsement Name"
          ColSize={{ sm: 12, md: 6, lg: 3 }}
          icon="fas fa-user"
          type="text"
          placeholder="Enter EndorsementName"
          name="EndorsementName"
          value={formData.EndorsementName}
          onChange={handleInputChange}
          required
        />
        <Row className="mt-4 ">
          <Col lg={6} md={12} sm={12} className="p-0">
            <Card className="p-3">
              <Row>
                <InputGroupWithIcon
                  Label="NetAmount"
                  ColSize={{ sm: 6, md: 6, lg: 6 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="NetAmount"
                  placeholder="Enter NetAmount"
                  value={formData.NetAmount}
                  onChange={handleInputChange}
                  required
                />
                <InputGroupWithIcon
                  Label="Service Tax"
                  ColSize={{ sm: 6, md: 6, lg: 6 }}
                  icon="fas fa-percentage"
                  type="number"
                  name="ServiceTax"
                  placeholder="Enter Service Tax"
                  value={formData.ServiceTax}
                  onChange={handleInputChange}
                  required
                />
              </Row>
              <Row>
                <InputGroupWithIcon
                  Label="Service TaxAmount"
                  ColSize={{ sm: 6, md: 6, lg: 6 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="ServiceTaxAmount"
                  placeholder="Enter Service TaxAmount"
                  value={formData.ServiceTaxAmount}
                  onChange={handleInputChange}
                  disabled
                />

                <InputGroupWithIcon
                  Label="Premium Amount"
                  ColSize={{ sm: 6, md: 6, lg: 6 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="PremiumAmt"
                  placeholder="Enter Premium Amount"
                  value={formData.PremiumAmt}
                  onChange={handleInputChange}
                  disabled
                />
                <Row>
                  <ImageAddAndPreview
                    imagePreview={imagePreview}
                    ClearImages={refreshKey}
                    Label="Image"
                    onFileUpload={handleImageUpload}
                    ColSize={showImage ? "9" : "12"}
                  />
                  {showImage && (
                    <Col md={3}>
                      <a
                        href={imagePreview}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="mb-3">Show Policy</Button>
                      </a>
                    </Col>
                  )}
                </Row>
              </Row>
            </Card>
          </Col>

          <Col lg={6} md={12} sm={12} className="p-0">
            <Card className="p-3">
              <TextArea
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                rows={5}
                Label=" Remarks"
                icon="fas fa-pencil-alt"
                name="Remarks"
                placeholder=" Remarks"
                value={formData.Remarks}
                onChange={handleInputChange}
              />
              <Col md={12} lg={12} sm={12}>
                {!editedData && (
                  <div className="d-flex">
                    <CustomCheckbox
                      icon="fab fa-whatsapp"
                      iconColor="Green"
                      name="SendWhatsAppSMS"
                      checked={formData.SendWhatsAppSMS}
                      onChange={handleInputChange}
                    />
                    <CustomCheckbox
                      icon="fas fa-sms"
                      iconColor="#4290f5"
                      name="SendTextSMS"
                      checked={formData.SendTextSMS}
                      onChange={handleInputChange}
                    />
                    <CustomCheckbox
                      icon="fas fa-envelope"
                      iconColor="#ed4a3d"
                      name="SendEmail"
                      checked={formData.SendEmail}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </Col>
            </Card>
          </Col>
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={insertEndorsement}
          HeadID={formData?.EndorsementID}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
