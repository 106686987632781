import React, { useState, useEffect, useCallback } from "react";
import { Card, Table } from "react-bootstrap";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { usePolicyDetailContext } from "./PolicyDetailContext";
import { convertDateFormat } from "../../Utils/commonFunction";

export default function CourierDetailModel({ selectedPolicyID, PolicyID }) {
  const [courierData, setCourierData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const { activeKey } = usePolicyDetailContext();
  const [policyHeader, setPolicyHeader] = useState([]);

  useEffect(() => {
    getCourierDetail();
    getPolicyHeader();
  }, [activeKey]);

  const getPolicyHeader = async () => {
    let policyIdToUse = PolicyID ? PolicyID : selectedPolicyID;
    await getSingleData(
      `Policy/AllPolicyDataDetailModel?PolicyID=${policyIdToUse}`,
      setPolicyHeader,
      setIsLoading
    );
  };

  const getCourierDetail = async () => {
    let policyIdToUse = PolicyID ? PolicyID : selectedPolicyID;
    await getSingleData(
      `Courier/AllCourierDetailModel?PolicyID=${policyIdToUse}`,
      setCourierData,
      setIsLoading
    );
  };

  return (
    <>
      <Card>
        <Card.Header>
          <span>
            PolicyHolder - {policyHeader[0]?.policyHolderName} , PolicyNo -{" "}
            {policyHeader[0]?.policyNo}
          </span>
        </Card.Header>
        <Card.Body>
          {courierData && courierData.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>PolicyNo </th>
                  <th>Courier Name</th>
                  <th>Tracking Number</th>
                  <th>Courier Status</th>
                  <th>Dispatched DateTime</th>
                  <th>Tracking Link</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {courierData.map((courier) => (
                  <tr key={courier.CourierID}>
                    <td>{courier.policyNo}</td>
                    <td>{courier.courierName}</td>
                    <td>{courier.trackingNumber}</td>
                    <td>{courier.courierStatus}</td>
                    <td>{convertDateFormat(courier.dispatchedDateTime)}</td>
                    <td>
                      <a
                        href={`http://www.anjanicourier.in/Doc_Track.aspx?No=${courier.trackingNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-700 underline"
                      >
                        Track your shipment
                      </a>
                    </td>
                    <td>{courier.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No courier data available</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
