import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";

const TextArea = ({
  icon,
  Label,
  name,
  placeholder,
  value,
  onChange,
  required,
  ColSize = { sm: 4, md: 4, lg: 4 },
  rows,
  disabled
}) => {
  return (
    <Col md={ColSize.md} sm={ColSize.sm} lg={ColSize.lg}>
      <Form.Group controlId={name}>
      {Label &&  <Form.Label>{Label}:</Form.Label>}
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <i className={icon}></i>
          </InputGroup.Text>
          <Form.Control
            as="textarea"
            name={name}
            rows={rows}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Enter ${Label}.`}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
  );
};

export default TextArea;
