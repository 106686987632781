import React, { useState, useEffect, useCallback } from "react";
import { Card, ListGroup, Table } from "react-bootstrap";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { getSingleDataReturn } from "../../Utils/CallApiOnlyReturn";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { usePolicyDetailContext } from "./PolicyDetailContext";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";
import UserSetting from "../../UserSettings.json";

export default function ClaimDetailModel({ selectedPolicyID, PolicyID }) {
  const [claimData, setClaimData] = useState([]);
  const { setIsLoading } = useLoadingContext();
  const { activeKey } = usePolicyDetailContext();
  const [policyHeader, setPolicyHeader] = useState([]);

  useEffect(() => {
    getClaimDetail();
    getPolicyHeader();
  }, [activeKey]);

  const getPolicyHeader = async () => {
    let policyIdToUse = PolicyID ? PolicyID : selectedPolicyID;
    await getSingleData(
      `Policy/AllPolicyDataDetailModel?PolicyID=${policyIdToUse}`,
      setPolicyHeader,
      setIsLoading
    );
  };

  const getClaimDetail = async () => {
    let policyIdToUse = PolicyID ? PolicyID : selectedPolicyID;
    const List = await getSingleDataReturn(
      `InsuranceClaim/AllClaimDetailModel?PolicyID=${policyIdToUse}`,
      setIsLoading
    );
    if (List) {
      setClaimData(List);
    }
  };

  return (
    <>
      <div className="claim-container">
        <Card>
          <Card.Header>
            <span>
              PolicyHolder - {policyHeader[0]?.policyHolderName} , PolicyNo -{" "}
              {policyHeader[0]?.policyNo}
            </span>
          </Card.Header>
          {claimData.length > 0 ? (
            claimData.map((claim) => (
              <Card key={claim.insuranceClaimID} className="claim-card mb-3">
                <Card.Body className="cardbody">
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="main-claim-table"
                  >
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Our Claim Number</th>
                        <th>Company Claim Number</th>
                        <th>TPA FileDate</th>
                        <th>TPA Master Name</th>
                        <th>Hospital Name</th>
                        <th>Intimation Status Name</th>
                        <th>Patient Name</th>
                        <th>Ailment</th>
                        <th>Admission Date</th>
                        <th>Claim Amount</th>
                        <th>Decut Amount</th>
                        <th>Payable Amount</th>
                        <th>UTR</th>
                        <th>Payment Date</th>
                        <th>Mobile No</th>
                        <th>Email To</th>
                        <th>Email CC</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="text-center"
                          style={{
                            verticalAlign: "middle",
                            letterSpacing: "10px",
                            cursor: "pointer",
                          }}
                        >
                          {claim.imagePath  && (
                            <a
                              href={UserSetting.imgURL + claim.imagePath}
                              target="_blank"
                            >
                              <i
                                style={{ fontSize: "1.6em", color: "black" }}
                                className="fas fa-file-pdf"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View PDF"
                                onMouseOver={(e) => {
                                  e.target.style.color = "red";
                                }}
                                onMouseOut={(e) => {
                                  e.target.style.color = "black";
                                }}
                              ></i>
                            </a>
                          )}
                        </td>
                        <td>{claim.ourClaimNumber}</td>
                        <td>{claim.companyClaimNumber}</td>
                        <td>
                          {convertDateFormatWithOutTime(claim.tpaFileDate)}
                        </td>
                        <td>{claim.tpaMasterName}</td>
                        <td>
                          {claim.hospitalName},({claim.hospitalAddress})
                        </td>
                        <td>{claim.intimationStatusName}</td>
                        <td>{claim.patientName}</td>
                        <td>{claim.ailment}</td>
                        <td>
                          {convertDateFormatWithOutTime(claim.dateOfAdmission)}
                        </td>
                        <td>{claim.claimAmount}</td>
                        <td>{claim.decutAmount}</td>
                        <td>{claim.payableAmount}</td>
                        <td>{claim.utr}</td>
                        <td>{convertDateFormatWithOutTime(claim.date)}</td>
                        <td>{claim.mobileNo}</td>
                        <td>{claim.emailTo}</td>
                        <td>{claim.emailIdCC}</td>
                        <td>{claim.remarks}</td>
                      </tr>
                    </tbody>
                  </Table>
                  {claim.subClaim && claim.subClaim.length > 0 && (
                    <div className="sub-claim-cards">
                      {/* <h6>Sub Claims:</h6> */}
                      {claim.subClaim.map((subClaim) => (
                        <Card
                          key={subClaim.subInsuranceClaimID}
                          className="sub-claim-card mb-3"
                        >
                          <Card.Body>
                            <ListGroup>
                              <ListGroup.Item>
                                <strong>TPA File Date:</strong>{" "}
                                {convertDateFormatWithOutTime(
                                  subClaim.tpaFileDate
                                )}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Claim Amount:</strong>{" "}
                                {subClaim.claimAmount}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Deduct Amount:</strong>{" "}
                                {subClaim.decutAmount}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Payable Amount:</strong>{" "}
                                {subClaim.payableAmount}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>UTR:</strong> {subClaim.utr}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Imitation Status:</strong>{" "}
                                {subClaim.intimationStatusName}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Payment Date:</strong>{" "}
                                {convertDateFormatWithOutTime(subClaim.date)}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Remark:</strong> {subClaim.remark}
                                <a
                                  href={UserSetting.imgURL + subClaim.imagePath}
                                  target="_blank"
                                  className="float-right"
                                >
                                  <i
                                    style={{
                                      fontSize: "1.6em",
                                      color: "black",
                                      textAlign: "end",
                                    }}
                                    className="fas fa-file-pdf"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View PDF"
                                    onMouseOver={(e) => {
                                      e.target.style.color = "red";
                                    }}
                                    onMouseOut={(e) => {
                                      e.target.style.color = "black";
                                    }}
                                  ></i>
                                </a>
                              </ListGroup.Item>
                            </ListGroup>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  )}
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>No claim data available</p>
          )}
        </Card>
      </div>
    </>
  );
}
