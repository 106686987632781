import React, { useEffect, useState } from "react";
import { getSingleDataReturn } from "../../Utils/CallApiOnlyReturn";
import Loader from "../../Common/Loader ";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import CustomCheckbox from "../../Common/CustomCheckbox";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import api from "../../Utils/api";
import { Button } from "react-bootstrap";
import { showMessage } from "../../Utils/showMessageUtils";

export default function SendSMSComponent({ policyID, onCloseSendSMS }) {
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (policyID) {
      getPolicyData();
    }
  }, [policyID]);

  const getPolicyData = async () => {
    const result = await getSingleDataReturn(
      `Policy/SinglePolicy?PolicyID=${policyID}`,
      setIsLoading
    );
    if (result) {
      setFormData({
        ...result,
        SendWhatsAppSMS: true,
        SendTextSMS: true,
        SendEmail: true,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };                                  

  const handleSubmit = () => {
    
    setIsLoading(true);
    api
      .post("Policy/SendSMSToAnyPolicy", formData)
      .then((result) => {
        showMessage(result.data.statusCode,result.data.message)
        onCloseSendSMS();
      })
      .catch((error) => {
        showMessage(400,"Failed to submit form data");
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading || formData === null) {
    return <Loader />;
  }

  return (
    <>
      <InputGroupWithIcon
        Label="Mobile Number"
        ColSize={{ sm: 12, md: 12, lg: 12 }}
        icon="fas fa-mobile-alt"
        type="text"
        name="mobile"
        placeholder="Enter Mobile Number"
        value={formData.mobile ? formData.mobile : ""}
        onChange={handleInputChange}
        required
      />
      <InputGroupWithIcon
        Label="Email To"
        ColSize={{ sm: 12, md: 12, lg: 12 }}
        icon="fas fa-envelope"
        type="email"
        name="emailIdTo"
        placeholder="Enter Email To"
        value={formData.emailIdTo ? formData.emailIdTo : ""}
        onChange={handleInputChange}
        required
      />
      <div className="d-flex">
        <CustomCheckbox
          icon="fab fa-whatsapp"
          iconColor="Green"
          name="SendWhatsAppSMS"
          checked={formData.SendWhatsAppSMS}
          onChange={handleInputChange}
        />
        <CustomCheckbox
          icon="fas fa-sms"
          iconColor="#4290f5"
          name="SendTextSMS"
          checked={formData.SendTextSMS}
          onChange={handleInputChange}
        />
        <CustomCheckbox
          icon="fas fa-envelope"
          iconColor="#ed4a3d"
          name="SendEmail"
          checked={formData.SendEmail}
          onChange={handleInputChange}
        />
      </div>
      {/* <OnClickSubmitButtonOfForm
        onClick={handleSubmit}
        HeadID={formData.PolicyID}
      /> */}
      <div className="margin_top10 items-end flex justify-end">
        <Button
          onClick={handleSubmit}
          variant="primary"
          className="mb-20px btn-tapti"
        >
          Send&nbsp;&nbsp;<ion-icon name="send-sharp" class="iconsFonts"></ion-icon>
        </Button>
      </div>
    </>
  );
}
