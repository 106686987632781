import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LOGO from "../assets/logo.png";
import api from "../Utils/api";
import { useLoadingContext } from "../Loading/LoadingContext";
import { decryptAndGetItemCookiesStorage } from "../Utils/CookiesStorage";

export default function Header({ userName }) {
  const [rolePermissionData, setRolePermissionData] = useState([]);
  const { setIsLoading } = useLoadingContext();
  const UserName = decryptAndGetItemCookiesStorage('userName');


  useEffect(() => {
    getRolePermission();
  }, []);

  // Fetch all departments
  const getRolePermission = () => {
    setIsLoading(true)
    api
      .get("RoleTemplateDetail/ViewPermissionWiseFormShow")
      .then((result) => {
        setRolePermissionData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=>{
        setIsLoading(false)
      })
  };

  const formGroupNames = [
    ...new Set(rolePermissionData.map((item) => item.formGroupName)),
  ];

  return (
    <>
      {/* <Navbar expand="lg" className="custom-bg-color">
        <Container fluid>
          <Navbar.Brand href="#">
            <NavLink className="navbar-brand logo_back" to="/">
              <img
                src={LOGO}
                alt="TAPTI TECHNOLOGY"
                loading="lazy"
                style={{display:"inline-flex" , height:"30px"}}
              />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
            
              {rolePermissionData && rolePermissionData.length > 0 ? (
                formGroupNames.filter((item)=> item !== "NOT Navbar").map((formGroupName , idx) => (
                  <NavDropdown
                    title={formGroupName}
                    id="basic-nav-dropdown"
                     key={idx}
                  >
                    {rolePermissionData
                      .filter(
                        (item) =>
                          item.viewPermission &&
                          item.formGroupName === formGroupName &&
                          item.formGroupName !== "NOT Navbar"
                      )
                      .map((item , index) => (
                        <NavDropdown.Item key={index}>
                          <NavLink to={item.actionName}>
                            {item.formName}
                          </NavLink>
                        </NavDropdown.Item>
                      ))}
                  </NavDropdown>
                ))
              ) : (
                <li>No menu items available</li>
              )}
            </Nav>
            <div className="d-flex">
            {userName && (
                <NavLink className="navbar-brand" to="/UserProfile">
                  <i className="fas fa-user"></i> {userName}
                </NavLink>
              )}
              <NavLink className="navbar-brand" to="/logout">
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      <Navbar expand="lg" className="custom-bg-color">
        <Container fluid>
          <Navbar.Brand href="#">
            <NavLink className="navbar-brand logo_back" to="/">
              <img
                src={LOGO}
                alt="TAPTI TECHNOLOGY"
                loading="lazy"
                style={{ display: "inline-flex", height: "30px" }}
              />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              navbarScroll
            >
              {formGroupNames.map((formGroupName, idx) => {
                
                if (formGroupName === "NotInAnyGroup") {
                  return (
                    rolePermissionData
                      .filter(
                        (item) =>
                          item.viewPermission &&
                          item.formGroupName === formGroupName
                      )
                      .map((item, index) => (
                        <NavLink
                          className="nav-link"
                          to={item.actionName}
                          key={index}
                        >
                          {item.formName}
                        </NavLink>
                      ))
                  );
                } else if (formGroupName !== "NotInNavbar") {
                  return (
                    <NavDropdown
                      title={formGroupName}
                      id="basic-nav-dropdown"
                      key={idx}
                    >
                      {rolePermissionData
                        .filter(
                          (item) =>
                            item.viewPermission &&
                            item.formGroupName === formGroupName &&
                            item.formGroupName !== "NotInNavbar"
                        )
                        .map((item, index) => (
                          <NavDropdown.Item key={index}>
                            <NavLink to={item.actionName}>
                              {item.formName}
                            </NavLink>
                          </NavDropdown.Item>
                        ))}
                    </NavDropdown>
                  );
                }
                return null;
              })}
            </Nav>
            <div className="d-flex">
              {UserName && (
                <NavLink className="navbar-brand" to="/UserProfile">
                  <i className="fas fa-user"></i> {UserName}
                </NavLink>
              )}
              <NavLink className="navbar-brand" to="/logout">
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
