import React, { useState, useEffect, useCallback } from "react";
import { Card, Button } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import AgentMasterComponent from "./AgentMasterComponent";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

export default function AgentMaster() {
  const [agentData, setAgentData] = useState([]);
  const [editAgentData, setEditAgentData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getAgentData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "AgentMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all agent data
  const getAgentData = async () => {
    await getAllData("AgentMaster/AllAgents", setIsLoading, setAgentData);
  };

  const getSingleAgent = async (id) => {
    await getSingleData(
      `AgentMaster/SingleAgent?AgentMasterID=${id}`,
      setEditAgentData,
      setIsLoading
    );
  };

  // Delete agent
  const deleteAgent = async (id) => {
    await deleteData(
      `AgentMaster/DeleteAgent?AgentMasterID=${id}`,
      getAgentData,
      setIsLoading
    );
  };

  const exportToExcel = () => {
    setIsLoading(true);
    setTimeout(() => {
      const fileName = "AgentData.xlsx";

      // Format the data to be exported
      const formattedData = agentData.map((item) => ({
        AgentName: item.agentMasterName,
        MobileNumber: item.agentMasterMobileNo,
        EmailID: item.agentMasterEmail,
      }));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Agent Data");

      worksheet.columns = [
        { header: "Agent Name", key: "AgentName", width: 30 },
        { header: "Mobile Number", key: "MobileNumber", width: 20 },
        { header: "Email ID", key: "EmailID", width: 30 },
      ];

      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });
      setIsLoading(false);
    }, 1000);
  };

  const columnDefs = [
    createColumnDef("Agent Name", "agentMasterName", 150),
    createColumnDef("Mobile Number", "agentMasterMobileNo", 150),
    createColumnDef("Email ID", "agentMasterEmail", 200),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "agentMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleAgent(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "agentMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteAgent(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Agent</span>
          </Card.Header>
          <Card.Body>
            <AgentMasterComponent
              returnID={getAgentData}
              editedData={editAgentData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <span>Agent List</span>
          <Button
            type="button"
            variant="success"
            className="mb-20px mr-4"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={agentData}
            columnDefs={columnDefs}
            height={500}
            paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
