import React, { useState, useEffect, useCallback } from "react";
import { Card, Table } from "react-bootstrap";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { usePolicyDetailContext } from "./PolicyDetailContext";
import UserSetting from "../../UserSettings.json";
import SendSMSComponent from "../Policy/SendSMSComponent";
import GlobalModal from "../../Common/GlobalModal";
import SendSMSEndorsement from "../Endorsement/SendSMSEndorsement";

export default function Endorsement({ selectedPolicyID, PolicyID }) {
  const [endorsementData, setEndorsementData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const { activeKey } = usePolicyDetailContext();
  const [policyHeader, setPolicyHeader] = useState([]);
  const [showSendSMSModel, setShowSendSMSModel] = useState(false);
  const [EndorsementIDForSendSMS, setEndorsementIDForSendSMS] = useState(null);

  useEffect(() => {
    getendorsementDetail();
    getPolicyHeader();
  }, [activeKey]);

  const getendorsementDetail = async () => {
    let policyIdToUse = PolicyID ? PolicyID : selectedPolicyID;
    await getSingleData(
      `Endorsement/AllEndorsementDataDetailModel?PolicyID=${policyIdToUse}`,
      setEndorsementData,
      setIsLoading
    );
  };

  const getPolicyHeader = async () => {
    let policyIdToUse = PolicyID ? PolicyID : selectedPolicyID;
    await getSingleData(
      `Policy/AllPolicyDataDetailModel?PolicyID=${policyIdToUse}`,
      setPolicyHeader,
      setIsLoading
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const HandleSendSMSModel = () => {
    setShowSendSMSModel(false);
  };

  return (
    <>
      <Card>
        <Card.Header>
          <span>
            PolicyHolder - {policyHeader[0]?.policyHolderName} , PolicyNo -{" "}
            {policyHeader[0]?.policyNo}
          </span>
        </Card.Header>
        <Card.Body>
          {endorsementData && endorsementData.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>PolicyNo</th>
                  <th>Endorsement Name</th>
                  <th>Endorsement Date</th>
                  <th>Remarks</th>
                  <th>NetAmount</th>
                  <th>ServiceTax</th>
                  <th>ServiceTaxAmount</th>
                  <th>PremiumAmt</th>
                </tr>
              </thead>
              <tbody>
                {endorsementData.map((endorsement) => (
                  <tr key={endorsement.endorsementID}>
                    <td
                      className="text-center"
                      style={{
                        verticalAlign: "middle",
                        letterSpacing: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {endorsement.imagePath  && (
                        <a
                          href={UserSetting.imgURL + endorsement.imagePath}
                          target="_blank"
                        >
                          <i
                            style={{ fontSize: "1.6em", color: "black" }}
                            className="fas fa-file-pdf"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View PDF"
                            onMouseOver={(e) => {
                              e.target.style.color = "red";
                            }}
                            onMouseOut={(e) => {
                              e.target.style.color = "black";
                            }}
                          ></i>
                        </a>
                      )}
                      <i
                        className="fas fa-paper-plane"
                        style={{
                          fontSize: "1.6em",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowSendSMSModel(true);
                          setEndorsementIDForSendSMS(endorsement.endorsementID);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.color = "blue";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "black";
                        }}
                      ></i>
                    </td>
                    <td>{endorsement.policyNo}</td>
                    <td>{endorsement.endorsementName}</td>
                    <td>{formatDate(endorsement.endorsementDate)}</td>
                    <td>{endorsement.remarks}</td>
                    <td>{endorsement.netAmount}</td>
                    <td>{endorsement.serviceTax}</td>
                    <td>{endorsement.serviceTaxAmount}</td>
                    <td>{endorsement.premiumAmt}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No Endorsement data available</p>
          )}
        </Card.Body>
        <GlobalModal
          title="Send SMS"
          bodyComponent={
            <SendSMSEndorsement
              EndorsementID={EndorsementIDForSendSMS}
              onCloseSendSMS={() => {
                setShowSendSMSModel(false);
              }}
            />
          }
          isOpen={showSendSMSModel}
          onClose={HandleSendSMSModel}
        />
      </Card>
    </>
  );
}
