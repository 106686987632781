import React from "react";
import { Col, Form, InputGroup, Row, Button, Table } from "react-bootstrap";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const InputGroupWithIconAndUpperLabel = ({
  icon,
  type,
  name,
  placeholder,
  value,
  onChange,
  required,
  ColSize,
  className,
  style,
  disabled,
}) => {
  return (
    <Col
      md={ColSize ? ColSize : 4}
      sm={ColSize ? ColSize : 4}
      lg={ColSize ? ColSize : 4}
    >
      <Form.Group controlId={name}>
        <Form.Label>{name}:</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <i className={icon}></i>
          </InputGroup.Text>
          <Form.Control
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            className={className}
            style={style}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Enter ${name}.`}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
  );
};

export const InputGroupWithOutIconAndUpperLabel = ({
  style,
  type,
  name,
  placeholder,
  value,
  onChange,
  required,
  ColSize,
  className,
  disabled,
}) => {
  return (
    <Col
      md={ColSize ? ColSize : 4}
      sm={ColSize ? ColSize : 4}
      lg={ColSize ? ColSize : 4}
    >
      <Form.Group controlId={name}>
        <Form.Label>{name}:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            style={style}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            className={className}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Enter ${name}.`}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
  );
};

// How to use
// const emptyData= {
//   Field....
// }
// const MyComponent = () => {
// const [formData, setFormData] = useState(emptyData);

// const handleInputChange = (UserMaster) => {
//   const { name, value, type, checked } = UserMaster.target;
//   const inputValue = type === "checkbox" ? checked : value;
//   setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
// };

//   return (
//   <Row>
//   <InputGroupWithIconAndUpperLabel || InputGroupWithOutIconAndUpperLabel
//   ColSize={8}
//     icon="fas fa-building"
//     type="text"
//     name="Name"
//     placeholder="Enter  Name"
//     value={formData.Name}
//     onChange={handleInputChange}
//     required
//   />
// </Row>
//   );
// };

// export default MyComponent;

export const InputGroupWithIconAndInLineLabel = ({
  icon,
  type,
  name,
  placeholder,
  value,
  onChange,
  required,
  LabelCol,
  TextBoxCol,
  className,
  style,
  disabled,
  Label,
  text,
}) => {
  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
      {Label && (
        <Form.Label column sm={LabelCol} className="text-end">
          {Label}:
        </Form.Label>
      )}
      <Col sm={TextBoxCol}>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <i className={icon}></i>
            <b className="">{text}</b>
          </InputGroup.Text>
          <Form.Control
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            className={className}
            style={style}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Enter ${name}.`}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

export const InputGroupWithOutIconAndInLineLabel = ({
  icon,
  type,
  name,
  placeholder,
  value,
  onChange,
  required,
  LabelCol,
  TextBoxCol,
  className,
  style,
  disabled,
}) => {
  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
      <Form.Label column sm={LabelCol} className="text-end">
        {name}:
      </Form.Label>
      <Col sm={TextBoxCol}>
        <InputGroup className="mb-3">
          <Form.Control
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            className={className}
            style={style}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Enter ${name}.`}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

// How to use
// const emptyData= {
//   Field....
// }
// const MyComponent = () => {
//   const [formData, setFormData] = useState(emptyData);

//   const handleInputChange = (UserMaster) => {
//     const { name, value, type, checked } = UserMaster.target;
//     const inputValue = type === "checkbox" ? checked : value;
//     setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
//   };

//   return (
//     <Row>
//       <InputGroupWithOutIconAndInLineLabel
//         LabelCol={2}
//         TextBoxCol={4}
//         icon="fas fa-building"
//         type="text"
//         name="EventName"
//         placeholder="Enter Event Name"
//         value={formData.EventName}
//         onChange={handleInputChange}
//         required
//       />
//     </Row>
//   );
// };

// export default MyComponent;

export function SubmitButtonOfForm({ HeadID }) {
  return (
    <div className="margin_top10 items-end flex justify-end">
      <Button type="submit" variant="primary" className="mb-20px btn-tapti">
        {HeadID > 0 ? "Update" : "Save"}
      </Button>
    </div>
  );
}

// How to use
// const MyComponent = () => {

//   return (
//   <GlobalFormTagWithSubmit

// >
// <SubmitButtonOfForm HeadID={formData?.ID} />
// </GlobalFormTagWithSubmit>
//   );
// };

// export default MyComponent;

export function GlobalFormTagWithSubmit({
  validated,
  onSubmit,
  refName,
  children,
  formName,
}) {
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => {
        if (e) {
          e.preventDefault();
        }

        if (refName.current && e) {
          if (e.target.name === formName) {
            onSubmit(e);
          }
        }
      }}
      ref={refName}
      name={formName}
    >
      {children}
    </Form>
  );
}

// How to use
// const MyComponent = () => {
// const [validated, setValidated] = useState(false);
// const EventFormRef = useRef(null);
//   return (
// <GlobalFormTagWithSubmit
//       validated={validated}
//       onSubmit={insertEventMaster}
//       refName={EventFormRef}
//       formName="EventForm"
//     >
//       Data
//     </GlobalFormTagWithSubmit>
//   );
// };

// export default MyComponent;

const commonColumnProperties = {
  sortable: true,
  filter: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  wrapText: true,
  autoHeight: true,
  flex: 1,
  headerClass: "widthTableColum",
};

export const createColumnDef = (
  headerName,
  field,
  minWidth,
  customProps = {}
) => ({
  headerName,
  field,
  minWidth,
  ...commonColumnProperties,
  ...customProps,
});

//   How To Use

// createColumnDef("MasterMenu Name", "masterMenuName", 150), Not :- Follow The This Way Foe Pass Data

export const createColumnDefButton = (
  headerName,
  field,
  buttonRenderer,
  width
) => ({
  headerName: headerName,
  field: field,
  width: width ? width : 100,
  headerClass: "center-text widthTableColum",
  cellStyle: { textAlign: "center" },
  cellRenderer: ({ value }) => buttonRenderer(value),
  pinned: "right",
});

//   How To Use
//   createColumnDefButton(
//     "Edit",
//     "masterMenuID",
//     (value) => (
//       <button onClick={() => getSingleCompony(value)}>
//         <Edit />
//       </button>
//     )
//   ),
// Not :- Follow The This Way Foe Pass Data

export const AgGridComponent = ({
  grid,
  rowData,
  columnDefs,
  onSelectionChanged,
  autoGroupColumnDef,
  onGridReady,
  paginationPageSize,
}) => {
  return (
    <div className="ag-theme-alpine ag-responsive-grid">
      <AgGridReact
        ref={grid}
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={paginationPageSize}
        domLayout="autoHeight"
        rowSelection="multiple"
        animateRows={true}
        onGridReady={onGridReady}
      ></AgGridReact>
    </div>
  );
};

export function CKEditorInput({ ColSize, label, data, onChange, Validation }) {
  return (
    <>
      <Col
        md={ColSize ? ColSize : 4}
        sm={ColSize ? ColSize : 4}
        lg={ColSize ? ColSize : 4}
      >
        <Form.Group controlId="HeaderField">
          <Form.Label>{label}:</Form.Label>
          <InputGroup className="mb-3">
            <div style={{ height: "400px", width: "100%" }}>
              <CKEditor
                className={`${Validation ? "borderRed" : ""}`}
                editor={ClassicEditor}
                data={data}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onChange(data);
                }}
              />
            </div>
            {Validation && (
              <div
                className="ValidationErrorMsg"
                style={{ marginTop: "-3rem" }}
              >
                Please select a {label}.
              </div>
            )}
          </InputGroup>
        </Form.Group>
      </Col>
    </>
  );
}

// hOW TO USE

// const MyComponent = () => {
// const [details, setDetails] = useState("");
// const [ckValidated,setCkValidated] = useState(false);

// const input function {
//   setCkValidated(details == []);
// }

//   return (
//      <CKEditorInput
// ColSize={8}
// label="Details"
// data={details}
// onChange={setDetails}
// Validation = {ckValidated}
// />
//   );
// };

export const DynamicTable = ({ columns, data, buttons }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
          {buttons && buttons.length > 0 && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex} style={column.style}>
                {typeof row[column.field] === "string" &&
                row[column.field].includes("<") ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: row[column.field] }}
                  />
                ) : (
                  row[column.field]
                )}
              </td>
            ))}
            {buttons && buttons.length > 0 && (
              <td>
                {buttons.map((button, index) => (
                  <button
                    key={index}
                    onClick={() => button.onClick(row.id)}
                    {...button.props}
                  >
                    {button.icon && (
                      <span dangerouslySetInnerHTML={{ __html: button.icon }} />
                    )}{" "}
                    {button.text}
                  </button>
                ))}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

// HOW TO USE

// const MyComponent = () => {
//   const columns = [
//     { header: 'First Name', field: 'firstName' },
//     { header: 'Last Name', field: 'lastName' },
//     { header: 'Username', field: 'username' },
//   ];

//   const data = [
//     { id: 1, firstName: 'John', lastName: 'Doe', username: '@johnny' },
//     { id: 2, firstName: 'Jane', lastName: 'Doe', username: '@jane' },
//     { id: 3, firstName: 'Jim', lastName: 'Smith', username: '@jimmy' },
//   ];

//   const buttons = [
//     {
//       icon: '<i class="fas fa-edit"></i>',
//       onClick: (id) => handleEdit(id),
//       props: { className: 'btn btn-primary mr-2' },
//     },
//     {
//       icon: '<i class="fas fa-trash"></i>',
//       onClick: (id) => handleDelete(id),
//       props: { className: 'btn btn-danger' },
//     },
//   ];

//   return (
//     <div>
//       <h2>Dynamic Table</h2>
//       <DynamicTable columns={columns} data={data} buttons={buttons} />
//     </div>
//   );
// };

export const DynamicEditableTable = ({ columns, data }) => {
  const handleInputChange = (e, rowIndex, column) => {
    if (column.onChange) {
      column.onChange(e, rowIndex, column.field); // Pass the event, row index, and column field name
    }
  };

  return (
    <Table bordered hover>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex} style={column.style}>
                <Form.Control
                  type={column.inputType || "text"}
                  name={column.field}
                  value={row[column.field]}
                  onChange={(e) => handleInputChange(e, rowIndex, column)}
                  disabled={
                    column.disabled !== undefined ? column.disabled : false
                  }
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

// HOW TO USE

// const MyComponent = () => {
//   const [data, setData] = useState([
//     { id: 1, firstName: 'John', lastName: 'Doe', username: '@johnny' },
//     { id: 2, firstName: 'Jane', lastName: 'Doe', username: '@jane' },
//     { id: 3, firstName: 'Jim', lastName: 'Smith', username: '@jimmy' },
//   ]);

//   const columns = [
//     { header: 'First Name', field: 'firstName', onChange: handleFirstNameChange },
//     { header: 'Last Name', field: 'lastName', onChange: handleLastNameChange },
//     { header: 'Username', field: 'username', onChange: handleUsernameChange },
//     // Example of using custom input component for a column
//     {
//       header: 'Custom Input',
//       field: 'customInput',
//       inputComponent: InputGroupWithIconWithOutIcon, // Pass the custom input component
//     },
//   ];

//   return (
//     <div>
//       <h2>Editable Table</h2>
//       <DynamicEditableTable columns={columns} data={data} />
//     </div>
//   );
// };

// export default MyComponent;
