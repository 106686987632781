import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import TextArea from "../../Common/TextArea";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyTPA = {
  tpa_id: 0,
  tpa_name: "",
  tpaMaster_email: "",
};

export default function TPAMasterComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyTPA);
  const TPAFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        tpa_id: editedData.tpaMasterID,
        tpa_name: editedData.tpaMasterName,
        tpaMaster_email: editedData.tpaMasterEmail,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertTPAMaster = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = TPAFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        TPAMasterID: formData?.tpa_id || 0,
        TPAMasterName: formData.tpa_name,
        TPAMasterEmail: formData.tpaMaster_email,
        // Add other fields as per your TPA data model
      };

      const endpoint =
        formData.tpa_id === 0
          ? "TPAMaster/InsertTPAMaster"
          : "TPAMaster/EditTPAMaster";

      api[formData.tpa_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyTPA);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={TPAFormRef}
        formName="TPAForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="TPA Name"
            icon="fas fa-user"
            type="text"
            name="tpa_name"
            placeholder="Enter TPA Name"
            value={formData.tpa_name}
            onChange={handleInputChange}
            required
          />
          </Row>
          <Row>
          <TextArea
              ColSize={{ sm: 12, md: 8, lg: 6 }}
            rows={3}
            Label="TPA Email Address"
            icon="fas fa-comment"
            name="tpaMaster_email"
            placeholder="Enter TPAEmail Address"
            value={formData.tpaMaster_email}
            onChange={handleInputChange}
          />
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={insertTPAMaster}
          HeadID={formData?.tpa_id}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
