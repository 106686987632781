import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyDocumentType = {
  courierStatusMasterID: 0,
  courierStatus: "",
};

export default function CourierStatusMasterComponent({ returnID, editedData, clearData, onClose }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyDocumentType);
  const courierStatusFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        courierStatusMasterID: editedData.courierStatusMasterID,
        courierStatus: editedData.courierStatus,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertCourierStatus = (event) => {
    
    setValidated(true);
    setIsLoading(true);
    const form = courierStatusFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        CourierStatusMasterID: formData?.courierStatusMasterID || 0,
        CourierStatus: formData.courierStatus,
      };

      const endpoint =
        formData.courierStatusMasterID === 0
          ? "CourierStatusMaster/InsertCourierStatus"
          : "CourierStatusMaster/EditCourierStatus";

      api[formData.courierStatusMasterID === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyDocumentType);
  };

  return (
    <>
      <GlobalFormTagWithSubmit validated={validated} refName={courierStatusFormRef} formName="CourierStatusForm">
        <Row>
          <InputGroupWithIcon
             ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="CourierStatus"
            icon="fas fa-file-alt"
            type="text"
            name="courierStatus"
            placeholder="Enter CourierStatus"
            value={formData.courierStatus}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm onClick={insertCourierStatus} HeadID={formData?.courierStatusMasterID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
