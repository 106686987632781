import api from "./api";
  import Swal from "sweetalert2";
  
export const getAllDataReturn = async (endpoint, setIsLoading) => {
    setIsLoading(true);
    try {
      const result = await api.get(endpoint);
      return result.data.data; 
    } catch (error) {
      throw error; 
    } finally {
      setIsLoading(false);
    }
  };
  
  export const getSingleDataReturn = async (endpoint, setIsLoading, ) => {
    setIsLoading(true);
    try {
      const result = await api.get(`${endpoint}`);
      return result.data.data
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  export const deleteDataOnly = async (endpoint, setIsLoading) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  
    if (result.isConfirmed) {
      setIsLoading(true);
      try {
        const response = await api.patch(`${endpoint}`);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Your record has been deleted.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire("Error", "An error occurred.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  export const CheckRoleTemplatePermissionsReturn = async (formName ,setIsLoading) => {
      setIsLoading(true);
      try {
        const result = await api.get(`RoleTemplateDetail/CheckRoleTemplatePermissions?FormName=${formName}`)
        return result.data.data
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
  };

