import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Form, InputGroup, Col } from "react-bootstrap";
// import { HtmlComment } from 'ckeditor5';

export default function CKEditorInput({
  label,
  data,
  onChange,
  Validation,
  ColSize,
}) {
  const editorContainerRef = useRef(null);
  const [editorHeight, setEditorHeight] = useState("350px");

  useEffect(() => {
    const editorContainerElement = editorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      const { height } = entries[0].contentRect;
      setEditorHeight(`${height}px`);
    });
    resizeObserver.observe(editorContainerElement);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Col
      md={ColSize ? ColSize : 4}
      sm={ColSize ? ColSize : 4}
      lg={ColSize ? ColSize : 4}
    >
      <Form.Group controlId="HeaderField">
        <Form.Label>{label}:</Form.Label>
        <InputGroup className="mb-3">
          <div ref={editorContainerRef} style={{ width: "100%" }}>
            <CKEditor
              editor={ClassicEditor}
              config={{
                // plugins: [ HtmlComment],
                toolbar: {
                  items: [
                    "sourceEditing",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "|",
                  ],
                },
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "imageStyle:full",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                  ],
                },
                language: "en",
              }}
              data={data}
              onChange={(event, editor) => {
                const data = editor.getData();
                onChange(data);
              }}
            />
          </div>
          {Validation && (
            <div className="ValidationErrorMsg" style={{ marginTop: "-3rem" }}>
              Please select a {label}.
            </div>
          )}
        </InputGroup>
      </Form.Group>
    </Col>
  );
}

// hOW TO USE

// const MyComponent = () => {
// const [details, setDetails] = useState("");
// const [ckValidated,setCkValidated] = useState(false);

// const input function {
//   setCkValidated(details == []);
// }

//   return (
//      <CKEditorInput
// ColSize={8}
// label="Details"
// data={details}
// onChange={setDetails}
// Validation = {ckValidated}
// />
//   );
// };

// export default MyComponent;
