import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, InputGroup, Form } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import InsuranceClaimComponent from "./InsuranceClaimComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";
import UserSetting from "../../UserSettings.json";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { convertDateFormat } from "../../Utils/commonFunction";
import {
  GetPre7DayDate,
  getCurrentDate,
  GetPre30DayDate,
  filterParams
} from "../../Utils/commonFunction";
import api from "../../Utils/api";
import { InputGroupWithIconAndInLineLabel } from "../../Common/MyComponent";
import GlobalDDComponentWithOutPlus from "../../Common/GlobalDDComponentWithOutPlus";

const emtSearchFormData = {
  start_date: GetPre30DayDate(),
  end_date: getCurrentDate(),
  policyNo: "",
  FinalStatusID: 0,
};
export default function InsuranceClaim() {
  const [insuranceClaimData, setInsuranceClaimData] = useState([]);
  const [editInsuranceClaimData, setEditInsuranceClaimData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const [showInsuranceClaimComponent, setShowInsuranceClaimComponent] =
    useState(false);
  const [formData, setFormData] = useState(emtSearchFormData);
  const [FinalStatusDDOption, setFinalStatusDDOption] = useState([]);
  const [agGridLoading, setAgGridLoading] = useState(false);

  useEffect(() => {
    UserPermissions();
    getInsuranceClaimData();
    getIntimationStatusData();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "InsuranceClaim",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  const getIntimationStatusData = async () => {
    await getAllData(
      "IntimationStatusMaster/AllIntimationStatusMasters",
      setIsLoading,
      setFinalStatusDDOption
    );
  };
  // Fetch all insurance claims
  // const getInsuranceClaimData = async () => {
  //   await getAllData(
  //     "InsuranceClaim/AllInsuranceClaims",
  //     setIsLoading,
  //     setInsuranceClaimData
  //   );
  // };

  const getInsuranceClaimData = async () => {
    const { start_date, end_date, policyNo } = formData;
    if (new Date(start_date) > new Date(end_date)) {
      alert("Start date cannot be after the end date.");
      return;
    }
    ;
    try {
      setAgGridLoading(true);
      const { data } = await api.get(
        `InsuranceClaim/AllInsuranceClaimsDateWiseFilter?START_DATE=${start_date}&END_DATE=${end_date}&PolicyNo=${policyNo}&FinalStatusID=${formData.FinalStatusID}`
      );
      setInsuranceClaimData(Array.isArray(data.data) ? data.data : []);
    } catch (error) {
      console.error("Not Found");
      setInsuranceClaimData([]);
    } finally {
      setAgGridLoading(false);
    }
  };

  const handleReset = () => {
    setFormData(emtSearchFormData);
  };

  useEffect(() => {
    if (formData === emtSearchFormData) {
      getInsuranceClaimData();
    }
  }, [formData]);

  const getSingleInsuranceClaim = async (id) => {
    const Result = await getSingleData(
      `InsuranceClaim/SingleInsuranceClaim?InsuranceClaimID=${id}`,
      setEditInsuranceClaimData,
      setIsLoading
    );
    {
      Result && setShowInsuranceClaimComponent(true);
    }
  };

  // Delete insurance claim
  const deleteInsuranceClaim = async (id) => {
    await deleteData(
      `InsuranceClaim/DeleteInsuranceClaim?InsuranceClaimID=${id}`,
      getInsuranceClaimData,
      setIsLoading
    );
  };

  const exportToExcel = () => {
    setIsLoading(true);
    setTimeout(() => {
      const fileName = "InsuranceClaim_data.xlsx";

      const formattedData = insuranceClaimData.map((item) => ({
        CreatedDate: convertDateFormat(item.createdDate),
        PolicyNo: item.policyNo,
        OurClaimNumber: item.ourClaimNumber,
        ClientName: item.clientName,
        PolicyHolderName: item.policyHolderName,
        TPAMasterName: item.tpaMasterName,
        HospitalName: item.hospitalName,
        HospitalAddress : item.hospitalAddress,
        TPAClaimNo : item.companyClaimNumber,
        FinalClaimStatusName: item.finalClaimStatusName,
        PatientName: item.patientName,
        Ailment: item.ailment,
        DateOfAdmission:item.dateOfAdmission && convertDateFormatWithOutTime(item.dateOfAdmission) ,
        ClaimAmount: item.claimAmount,
        Deduction : item.decutAmount,
        PayableAmount : item.payableAmount,
        utr : item.utr,
        UTRDate : item.date && convertDateFormatWithOutTime(item.date),
        ClientMobile : item.mobileNo,
        Remarks : item.remarks,
        createdDate : item.createdDate && convertDateFormat(item.createdDate),
        updatedDate : item.date && convertDateFormat(item.updatedDate),
        ImageURL: `View Image`, // Placeholder text for clickable link
        ImagePath: UserSetting.imgURL + item.imagePath, // Store the actual URL separately
      }));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Insurance Claim Data");

      worksheet.columns = [
        { header: "Our Claim Number", key: "OurClaimNumber", width: 20 },
        { header: "Policy No", key: "PolicyNo", width: 20 },
        { header: "Client Name", key: "ClientName", width: 20 },
        { header: "Holder Name", key: "PolicyHolderName", width: 20 },
        { header: "Patient", key: "PatientName", width: 20 },
        { header: "Ailment", key: "Ailment", width: 20 },
        { header: "Date Of Admission", key: "DateOfAdmission", width: 20 },
        { header: "Hospital", key: "HospitalName", width: 20 },
        { header: "Hospital Address", key: "HospitalAddress", width: 20 },
        { header: "TPA ClaimNo", key: "TPAClaimNo", width: 20 },
        { header: "TPA", key: "TPAMasterName", width: 20 },
        {
          header: "Final Claim Status",
          key: "FinalClaimStatusName",
          width: 20,
        },
        { header: "Claim Amount", key: "ClaimAmount", width: 20 },
        { header: "Deduction", key: "Deduction", width: 20 },
        { header: "Payable Amount", key: "PayableAmount", width: 20 },
        { header: "UTR No", key: "utr", width: 20 },
        { header: "UTR Date", key: "UTRDate", width: 20 },
        { header: "Client Mobile", key: "ClientMobile", width: 20 },
        { header: "Remarks", key: "Remarks", width: 20 },
        { header: "CreatedDate", key: "createdDate", width: 20 },
        { header: "UpdatedDate", key: "updatedDate", width: 20 },
       
       
        { header: "Image URL", key: "ImageURL", width: 40 }, // Column for image URL
      ];

      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Add hyperlink formula to each cell in Image URL column
      worksheet.getColumn("ImageURL").eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          // Skip header row
          const imagePath = formattedData[rowNumber - 2].ImagePath;
          cell.value = { formula: `HYPERLINK("${imagePath}","View Image")` };
        }
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });
      setIsLoading(false);
    }, 1000);
  };

  const columnDefs = [
    createColumnDef("Our ClaimNumber", "ourClaimNumber", 220),
    createColumnDef("PolicyNo", "policyNo", 300),
    createColumnDef("ClientName", "clientName", 300),
    createColumnDef("Holder Name", "policyHolderName", 300),
    createColumnDef("Patient ", "patientName", 300),
    createColumnDef("Ailment", "ailment", 250),

    {
      headerName: "DateOfAdmission",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 150,
      headerClass: "widthTableColum centered-header",
      field: "dateOfAdmission",
      cellRenderer: ({ value }) => value && convertDateFormatWithOutTime(value),
    },
    createColumnDef("Hospital", "hospitalName", 300),

    createColumnDef("Hospital Address", "hospitalAddress", 500),

    createColumnDef("TPA ClaimNo", "companyClaimNumber", 250),
    createColumnDef("TPA", "tpaMasterName", 290),
    createColumnDef("Final Claim Status", "finalClaimStatusName", 130),
    createColumnDef("Claim Amount", "claimAmount", 150),
    createColumnDef("Deduction", "decutAmount", 150),
    createColumnDef("Payable Amount", "payableAmount", 150),
    createColumnDef("UTR No", "utr", 150),
    {
      headerName: "UTR Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 150,
      headerClass: "widthTableColum centered-header",
      field: "date",
      cellRenderer: ({ value }) => value && convertDateFormatWithOutTime(value),
    },
    createColumnDef("Client Mobile", "mobileNo", 150),
    createColumnDef("Remarks", "remarks", 250),

    {
      headerName: "Created Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 200,
      headerClass: "widthTableColum centered-header",
      field: "createdDate",
      cellRenderer: ({ value }) => value && convertDateFormat(value),
    },
    {
      headerName: "Updated Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 200,
      headerClass: "widthTableColum centered-header",
      field: "updatedDate",
      cellRenderer: ({ value }) => value && convertDateFormat(value),
    },
    {
      headerName: "View",
      width: 80,
      field: "imagePath",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => {
        if (value) {
          return (
            <a
              href={UserSetting.imgURL + value}
              target="_blank"
              rel="noreferrer"
            >
              <ion-icon name="eye-outline" class="iconsFonts"></ion-icon>
            </a>
          );
        } else {
          return <ion-icon name="eye-off" class="iconsFonts"></ion-icon>;
        }
      },
      pinned: window.innerWidth > 768 ? "right" : "",
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "insuranceClaimID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleInsuranceClaim(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "insuranceClaimID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteInsuranceClaim(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [name]: value };
      if (name === "policyNo") {
        updatedFormData.FinalStatusID = 0;
      }
      return updatedFormData;
    });
  };

  const HandleModelClose = () => {
    setShowInsuranceClaimComponent(false);
    setEditInsuranceClaimData(null);
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <span>
            {showInsuranceClaimComponent
              ? editInsuranceClaimData
                ? "Edit Insurance Claim"
                : "Add Insurance Claim"
              : "Insurance Claim List"}
          </span>
          <div>
            <Button
              type="button"
              variant="success"
              className="mb-20px mr-4"
              onClick={exportToExcel}
            >
              <i className="fa fa-file-excel"></i> Export to Excel
            </Button>

            <Button
              className="btn-tapti"
              onClick={() => {
                if (showInsuranceClaimComponent) {
                  HandleModelClose();
                } else {
                  setShowInsuranceClaimComponent(true);
                }
              }}
            >
              {showInsuranceClaimComponent
                ? "Show Data"
                : "Add Insurance Claim"}
            </Button>
          </div>
        </Card.Header>
        {RoleTemplatePermissions &&
          RoleTemplatePermissions.addPermission &&
          showInsuranceClaimComponent && (
            <Card.Body>
              <InsuranceClaimComponent
                onClose={HandleModelClose}
                returnID={getInsuranceClaimData}
                editedData={editInsuranceClaimData}
                clearData={showInsuranceClaimComponent}
              />
            </Card.Body>
          )}

        {!showInsuranceClaimComponent && (
          <Card.Body>
            <Row className="align-items-center mb-2">
              <Col md={6} lg={3} sm={12} className="my-0.5">
                <InputGroup className="PolicySelectDateInputGroup">
                  <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={6} lg={3} sm={12} className="my-0.5">
                <InputGroup className="PolicySelectDateInputGroup">
                  <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={6} lg={3} sm={12} className="my-0.5">
                <InputGroup className="PolicySelectDateInputGroup">
                  <InputGroup.Text id="basic-addon1">Policy No</InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="policyNo"
                    placeholder="Enter Policy No"
                    value={formData.policyNo}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </Col>
              <GlobalDDComponentWithOutPlus
                ColSize={{ sm: 12, md: 6, lg: 2 }}
                placeholder=" Select Status"
                apiEndpoint="IntimationStatusMaster/AllIntimationStatusMasters"
                valueOfDD="intimationStatusID"
                labelOfDD="intimationStatusName"
                onSelectOption={(intimationStatusID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    FinalStatusID: intimationStatusID,
                    policyNo: "",
                  }));
                }}
                selectedOptionOfComponent={formData.FinalStatusID}
              />

              <Col lg={1} className="d-flex justify-between items-center">
                <Button variant="success" onClick={getInsuranceClaimData}>
                  <i class="fa fa-search"></i>
                </Button>
                <Button variant="danger" onClick={handleReset}>
                  <i class="	fa fa-refresh"></i>
                </Button>
              </Col>
            </Row>
            <AgGridComponent
              rowData={insuranceClaimData}
              columnDefs={columnDefs}
              height={"75vh"}
              paginationPageSize={25}
              loading={agGridLoading}
            />
          </Card.Body>
        )}
      </Card>
    </>
  );
}
