import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import InsuranceCompanyComponent from "./InsuranceCompanyComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function InsuranceCompany() {
  const [insuranceCompanyData, setInsuranceCompanyData] = useState([]);
  const [editInsuranceCompanyData, setEditInsuranceCompanyData] =
    useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getInsuranceCompanyData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "HospitalMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all insurance companies
  const getInsuranceCompanyData = async () => {
    await getAllData(
      "InsuranceCompany/AllInsuranceCompanies",
      setIsLoading,
      setInsuranceCompanyData
    );
  };

  const getSingleInsuranceCompany = async (id) => {
    await getSingleData(
      `InsuranceCompany/SingleInsuranceCompany?InsuranceCompanyID=${id}`,
      setEditInsuranceCompanyData,
      setIsLoading
    );
  };

  // Delete insurance company
  const deleteInsuranceCompany = async (id) => {
    // You'll need to replace "InsuranceCompany/DeleteInsuranceCompany" with the appropriate API endpoint
    await deleteData(
      `InsuranceCompany/DeleteInsuranceCompany?InsuranceCompanyID=${id}`,
      getInsuranceCompanyData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("Insurance CompanyName", "insuranceCompanyName", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "insuranceCompanyID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleInsuranceCompany(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "insuranceCompanyID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteInsuranceCompany(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Insurance Company</span>
          </Card.Header>
          <Card.Body>
            <InsuranceCompanyComponent
              returnID={getInsuranceCompanyData}
              editedData={editInsuranceCompanyData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>Insurance Company List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={insuranceCompanyData}
            columnDefs={columnDefs}
            height={500} paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
