import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import SMSApiComponent from "../SMSApi/SMSApiComponent";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import CKEditorInput from "../../Common/CKEditorInput";
import GlobalCheckbox from "../../Common/GlobalCheckbox";
import { getAllData } from "../../Utils/CallApiUtils";
import GlobalDDComponentWithOutPlus from "../../Common/GlobalDDComponentWithOutPlus";
import TextArea from "../../Common/TextArea";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyEmailTemplate = {
  EmailTemplateID: 0,
  EmailTemplateName: "",
  EmailSubject: "",
  CCEmail: "",
  EmailBody: "",
  IsActive: "",
  SMSTemplateFixID: 0,
};

export default function EmailTemplateComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyEmailTemplate);
  const EmailTemplateFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();
  const [smsApiDDValidationError, setSMSApiDDValidationError] = useState(false);
  const [smsParameter, setSMSParameter] = useState([]);
  const [smsTemplateFixDDValidationError, setSMSTemplateFixDDValidationError] =
    useState(false);

  useEffect(() => {
    if (editedData) {
      setFormData({
        EmailTemplateID: editedData.emailTemplateID,
        EmailTemplateName: editedData.emailTemplateName,
        EmailSubject: editedData.emailSubject,
        CCEmail: editedData.ccEmail,
        EmailBody: editedData.emailBody,
        IsActive: editedData.isActive,
        SMSTemplateFixID: editedData.smsTemplateFixID,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertEmailTemplate = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = EmailTemplateFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        EmailTemplateID: formData?.EmailTemplateID || 0,
        EmailTemplateName: formData.EmailTemplateName,
        EmailSubject: formData.EmailSubject,
        CCEmail: formData.CCEmail,
        EmailBody: formData.EmailBody,
        IsActive: formData.IsActive,
        smsTemplateFixID: formData.SMSTemplateFixID,
      };

      const endpoint =
        formData.EmailTemplateID === 0
          ? "EmailTemplate/InsertEmailTemplate"
          : "EmailTemplate/EditEmailTemplate";

      api[formData.EmailTemplateID === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const handleEditorChange = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      EmailBody: data,
    }));
  };

  const resetForm = () => {
    setFormData(emptyEmailTemplate);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={EmailTemplateFormRef}
        formName="EmailTemplateForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 12, lg: 4 }}
            Label="Template Name"
            icon="fas fa-tag"
            type="text"
            name="EmailTemplateName"
            placeholder="Enter Template Name"
            value={formData.EmailTemplateName}
            onChange={handleInputChange}
            required
          />
          <GlobalDDComponentWithOutPlus
            ColSize={{ sm: 12, md: 6, lg: 4 }}
            formLabel="SMS Template Header Name"
            apiEndpoint="SMSTemplateFix/AllSMSTemplateFixs"
            valueOfDD="smsTemplateFixID"
            labelOfDD="smsTemplateFixName"
            onSelectOption={(smsTemplateFixID) => {
              setFormData((prevData) => ({
                ...prevData,
                SMSTemplateFixID: smsTemplateFixID,
              }));
              setSMSTemplateFixDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.SMSTemplateFixID}
            ValidationError={smsTemplateFixDDValidationError}
          />
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 12, lg: 4 }}
            Label="CC Email"
            icon="fas fa-envelope"
            type="text"
            name="CCEmail"
            placeholder="Enter CC Email"
            value={formData.CCEmail}
            onChange={handleInputChange}
          />
          <TextArea
            ColSize={{ sm: 12, md: 12, lg: 12 }}
            rows={2}
            Label="Email Subject"
            icon="fas fa-envelope"
            name="EmailSubject"
            placeholder="Enter Email Subject"
            value={formData.EmailSubject}
            onChange={handleInputChange}
          />
        </Row>
        <Row>
          <CKEditorInput
            ColSize={{ sm: 12, md: 12, lg: 4 }}
            label="Email Body"
            data={formData.EmailBody}
            onChange={handleEditorChange}
          />
        </Row>
        <Row>
          <GlobalCheckbox
            label="Active"
            name="IsActive"
            checked={formData.IsActive}
            onChange={handleInputChange}
          />
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={insertEmailTemplate}
          HeadID={formData?.EmailTemplateID}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
