import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
    getAllData,
    getSingleData,
    deleteData,
    CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import SMSTemplateComponent from "./SMSTemplateComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function SMSTemplate() {
    const [SMSTemplateData, setSMSTemplateData] = useState([]);
    const [editSMSTemplateData, setEditSMSTemplateData] = useState(null);
    const { setIsLoading } = useLoadingContext();
    const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

    useEffect(() => {
        getSMSTemplateData();
        UserPermissions();
    }, []);

    const UserPermissions = async () => {
        await CheckRoleTemplatePermissions(
            "SMSTemplate",
            setRoleTemplatePermissions,
            setIsLoading
        );
    };

    // Fetch all document types
    const getSMSTemplateData = async () => {
        
        await getAllData(
            "SMSTemplate/AllSMSTemplate",
            setIsLoading,
            setSMSTemplateData
        );
    };

    const getSingleSMSTemplate = async (id) => {
        await getSingleData(
            `SMSTemplate/SingleSMSTemplate?SMSTemplateID=${id}`,
            setEditSMSTemplateData,
            setIsLoading
        );
    };

    // Delete document type
    const deleteSMSTemplate = async (id) => {
        // You'll need to replace "SMSTemplate/DeleteSMSTemplate" with the appropriate API endpoint
        await deleteData(
            `SMSTemplate/DeleteSMSTemplate?SMSTemplateID=${id}`,
            getSMSTemplateData,
            setIsLoading
        );
    };

    const columnDefs = [
        createColumnDef("SMS Template Name", "smsTemplateName", 150),
        createColumnDef("SMS Text", "smsText", 150),
        createColumnDef("CC Number", "ccNumber", 150),
        ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
            ? [
                {
                    headerName: "Edit",
                    width: 70,
                    field: "smsTemplateID",
                    cellStyle: { textAlign: "center" },
                    headerClass: "centered-header",
                    cellRenderer: ({ value }) => (
                        <ion-icon
                            name="create-outline"
                            class="iconsFonts editIcon"
                            onClick={() => getSingleSMSTemplate(value)}
                        ></ion-icon>
                    ),
                    pinned: window.innerWidth > 768 ? "right" : "",
                },
            ]
            : []),
        ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
            ? [
                {
                    headerName: "Delete",
                    width: 80,
                    field: "smsTemplateID",
                    cellStyle: { textAlign: "center" },
                    headerClass: "centered-header",
                    cellRenderer: ({ value }) => (
                        <ion-icon
                            name="trash-outline"
                            class="iconsFonts deleteIcon"
                            onClick={() => deleteSMSTemplate(value)}
                        ></ion-icon>
                    ),
                    pinned: window.innerWidth > 768 ? "right" : "",
                },
            ]
            : []),
    ];

    return (
        <>
            {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
                <Card>
                    <Card.Header>
                        <span>New SMS Template</span>
                    </Card.Header>
                    <Card.Body>
                        <SMSTemplateComponent
                            returnID={getSMSTemplateData}
                            editedData={editSMSTemplateData}
                        />
                    </Card.Body>
                </Card>
            )}
            <Card>
                <Card.Header>
                    <span>SMS Template List</span>
                </Card.Header>
                <Card.Body>
                    <AgGridComponent
                        rowData={SMSTemplateData}
                        columnDefs={columnDefs}
                        height={700} paginationPageSize={25}
                    />
                </Card.Body>
            </Card>
        </>
    );
}
