import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import AgGridComponent from "../../Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import { Form, Button, Col, Row, InputGroup, Modal } from "react-bootstrap";
import FormGroup from "./FormGroup";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import SubmitButtonOfForm from "../../Common/SubmitButtonOfForm";
import { showMessage } from "../../Utils/showMessageUtils";

export default function FormAdd() {
  const [validated, setValidated] = useState(false);
  const [insertFormData, setInsertFormData] = useState({
    form_id: 0,
    form_group_id: 0,
    form_name: "",
    controller_name: "",
    action_name: "",
  });
  const [options, setOptions] = useState([]);
  const [selectedFormGroup, setSelectedFormGroup] = useState(null);
  const [isFormGroupModalOpen, setIsFormGroupModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {
    getForm();
  }, []);

  const getForm = () => {
    api
      .get("Form/AllForm")
      .then((result) => {
        setFormData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const emptyForm = {
    form_id: 0,
    form_group_id: 0,
    form_name: "",
    controller_name: "",
    action_name: "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInsertFormData({ ...insertFormData, [name]: value });
  };

  // Insert & Update Data
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        formID: insertFormData?.form_id || 0,
        formName: insertFormData.form_name,
        formGroupID: insertFormData.form_group_id,
        controllerName: insertFormData.action_name,
        actionName: insertFormData.controller_name,
      };

      const endpoint =
        insertFormData.form_id === 0 ? "Form/InsertForm" : "Form/EditForm";

      api[insertFormData.form_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          getForm();
          setSelectedFormGroup(null);
            showMessage(result.data.statusCode,result.data.message)
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        });
      resetForm();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setInsertFormData(emptyForm);
  };

  // Get data of a single Form
  const getSingleForm = (id) => {
    api
      .get(`Form/SingleForm?FormID=${id}`)
      .then((result) => {
        const FormData = result.data.data;
        setInsertFormData({
          form_id: FormData.formID,
          form_name: FormData.formName,
          action_name: FormData.actionName,
          controller_name: FormData.controllerName,
          form_group_id: FormData.formGroupID,
        });
        setSelectedFormGroup(FormData.formGroupID);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Form
  const deleteForm = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Form/DeleteForm?FormID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getForm();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Group Name",
      field: "formGroupName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Form Name",
      field: "formName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Controller Name",
      field: "controllerName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Action Name",
      field: "actionName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Edit",
      width: 70,
      field: "formID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleForm(value)}
        ></ion-icon>
      ),
      pinned: window.innerWidth > 768 ? "right" : "",
    },

    {
      headerName: "Delete",
      width: 80,
      field: "formID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteForm(value)}
        ></ion-icon>
      ),
      pinned: window.innerWidth > 768 ? "right" : "",
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <span>New Form</span>
        </Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={(event) => handleSubmit(event, { resetForm })}
          >
            <Row>
              <GlobalDDComponent
               ColSize={{ sm: 12, md: 6, lg: 4 }}
                formLabel="Form Group Name"
                apiEndpoint="FormGroup/AllFormGroup"
                valueOfDD="formGroupID"
                labelOfDD="formGroupName"
                onSelectOption={(formGroupID) => {
                  setInsertFormData((prevData) => ({
                    ...prevData,
                    form_group_id: formGroupID,
                  }));
                  setDDValidationError(false);
                }}
                selectedOptionOfComponent={insertFormData.form_group_id}
                ValidationError={ddValidationError}
                offcanvasTitle="Add Form Group"
                offcanvasBodyComponent={<FormGroup />}
              />
              <Col md={6} sm={12} lg={4}>
                <Form.Group controlId="form_name">
                  <Form.Label>Form Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-sun"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="form_name"
                      placeholder="Enter Form Name"
                      value={insertFormData.form_name}
                      onChange={handleInputChange}
                      required
                    />
                    {validated && !insertFormData.client_name && (
                      <div className="invalid-feedback">
                        Please Enter Form Name.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} sm={12} lg={4}>
                <Form.Group controlId="controller_name">
                  <Form.Label>Controller Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-sun"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="controller_name"
                      placeholder="Enter Controller Name"
                      value={insertFormData.controller_name}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            
              <Col md={6} sm={12} lg={4}>
                <Form.Group controlId="action_name">
                  <Form.Label>Action Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-sun"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="action_name"
                      placeholder="Enter Action Name"
                      value={insertFormData.action_name}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            {/* <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {insertFormData?.form_id > 0 ? "Update" : "Save"}
              </Button>
             
            </div> */}
            <SubmitButtonOfForm HeadID={insertFormData?.form_id} />
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <span>Form List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={formData} columnDefs={columnDefs} height={500} paginationPageSize={25} />
        </Card.Body>
      </Card>
    </>
  );
}
