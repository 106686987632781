import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyIntimationStatus = {
  intimationStatusID: 0,
  intimationStatusName: "",
};

export default function IntimationStatusMasterComponent({ returnID, editedData, clearData, onClose }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyIntimationStatus);
  const intimationStatusFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        intimationStatusID: editedData.intimationStatusID,
        intimationStatusName: editedData.intimationStatusName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertIntimationStatus = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = intimationStatusFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        IntimationStatusID: formData?.intimationStatusID || 0,
        IntimationStatusName: formData.intimationStatusName,
      };

      const endpoint =
        formData.intimationStatusID === 0
          ? "IntimationStatusMaster/InsertIntimationStatusMaster"
          : "IntimationStatusMaster/EditIntimationStatusMaster";

      api[formData.intimationStatusID === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);            
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyIntimationStatus);
  };

  return (
    <>
      <GlobalFormTagWithSubmit validated={validated} refName={intimationStatusFormRef} formName="IntimationStatusForm">
        <Row>
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Intimation Status Name"
            icon="fas fa-info-circle"
            type="text"
            name="intimationStatusName"
            placeholder="Enter Intimation Status Name"
            value={formData.intimationStatusName}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm onClick={insertIntimationStatus} HeadID={formData?.intimationStatusID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
