import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import AilmentMasterComponent from "./AilmentMasterComponent"; 
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function AilmentMaster() {
  const [ailmentData, setAilmentData] = useState([]);
  const [editAilmentData, setEditAilmentData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getAilmentData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "AilmentMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all ailments
  const getAilmentData = async () => {
    await getAllData(
      "AilmentMaster/AllAilments",
      setIsLoading,
      setAilmentData
    );
  };

  const getSingleAilment = async (id) => {
    await getSingleData(
      `AilmentMaster/SingleAilment?ailmentMasterID=${id}`,
      setEditAilmentData,
      setIsLoading
    );
  };

  // Delete ailment
  const deleteAilment = async (id) => {
    await deleteData(
      `AilmentMaster/DeleteAilment?AilmentMasterID=${id}`,
      getAilmentData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("AilmentName", "ailmentName", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "ailmentMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
              name="create-outline"
              class="iconsFonts editIcon"
              onClick={() => getSingleAilment(value)}
            ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "ailmentMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                 name="trash-outline"
               class="iconsFonts deleteIcon"
                onClick={() => deleteAilment(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Ailment</span>
          </Card.Header>
          <Card.Body>
            <AilmentMasterComponent
              returnID={getAilmentData}
              editedData={editAilmentData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>Ailment List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={ailmentData}
            columnDefs={columnDefs}
            height={500} paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
