import React, { useEffect, useState } from "react";
import { getSingleDataReturn } from "../../Utils/CallApiOnlyReturn";
import Loader from "../../Common/Loader ";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import CustomCheckbox from "../../Common/CustomCheckbox";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import api from "../../Utils/api";
import { Button } from "react-bootstrap";
import { showMessage } from "../../Utils/showMessageUtils";

export default function SendSMSEndorsement({ EndorsementID, onCloseSendSMS }) {
  const emtSendDetails = {
    MobileNo: "",
    EmailId: "",
  };

  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [SendDetails, setSendDetails] = useState(emtSendDetails);

  useEffect(() => {
    if (EndorsementID) {
      getEndorsementData();
    }
  }, [EndorsementID]);

  const getEndorsementData = async () => {
    
    const result = await getSingleDataReturn(
      `Endorsement/SingleEndorsement?EndorsementID=${EndorsementID}`,
      setIsLoading
    );
    if (result) {
      setFormData({
        ...result,
        SendWhatsAppSMS: true,
        SendTextSMS: true,
        SendEmail: true,
      });
      const PolicyResult = await getSingleDataReturn(
        `Policy/SinglePolicy?PolicyID=${result.policyID}`,
        setIsLoading
      );
      if (PolicyResult) {
        setSendDetails({
          MobileNo: PolicyResult.mobile,
          EmailId: PolicyResult.emailIdTo,
        });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setSendDetails((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const handleSubmit = () => {
    if (SendDetails.MobileNo == "" || SendDetails.EmailId == "") {
      showMessage(400,"Enter MobileNo And EmailID");
    }

    setIsLoading(true);
    api
      .post(
        `Endorsement/SendSMSToAnyEndorsement?MobileNo=${SendDetails.MobileNo}&EmailID=${SendDetails.EmailId}`,
        formData
      )
      .then((result) => {
        showMessage(result.data.statusCode,result.data.message);
        onCloseSendSMS();
      })
      .catch((error) => {
        showMessage(400,"Failed to submit form data");
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading || formData === null) {
    return <Loader />;
  }
  return (
    <>
      <InputGroupWithIcon
        Label="Mobile Number"
        ColSize={{ sm: 12, md: 12, lg: 12 }}
        icon="fas fa-mobile-alt"
        type="text"
        name="MobileNo"
        placeholder="Enter Mobile Number"
        value={SendDetails.MobileNo ? SendDetails.MobileNo : ""}
        onChange={handleInputChange}
        required
      />
      <InputGroupWithIcon
        Label="Email To"
        ColSize={{ sm: 12, md: 12, lg: 12 }}
        icon="fas fa-envelope"
        type="email"
        name="EmailId"
        placeholder="Enter Email To"
        value={SendDetails.EmailId ? SendDetails.EmailId : ""}
        onChange={handleInputChange}
        required
      />
      <div className="d-flex">
        <CustomCheckbox
          icon="fab fa-whatsapp"
          iconColor="Green"
          name="SendWhatsAppSMS"
          checked={formData.SendWhatsAppSMS}
          onChange={handleInputChange}
        />
        <CustomCheckbox
          icon="fas fa-sms"
          iconColor="#4290f5"
          name="SendTextSMS"
          checked={formData.SendTextSMS}
          onChange={handleInputChange}
        />
        <CustomCheckbox
          icon="fas fa-envelope"
          iconColor="#ed4a3d"
          name="SendEmail"
          checked={formData.SendEmail}
          onChange={handleInputChange}
        />
      </div>
      {/* <OnClickSubmitButtonOfForm
      onClick={handleSubmit}
      HeadID={formData.PolicyID}
    /> */}
      <div className="margin_top10 items-end flex justify-end">
        <Button
          onClick={handleSubmit}
          variant="primary"
          className="mb-20px btn-tapti"
        >
          Send&nbsp;&nbsp;
          <ion-icon name="send-sharp" class="iconsFonts"></ion-icon>
        </Button>
      </div>
    </>
  );
}
