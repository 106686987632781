import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyPolicyType = {
  policy_type_id: 0,
  policy_type_name: "",
};

export default function PolicyTypeComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyPolicyType);
  const policyTypeFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        policy_type_id: editedData.policyTypeID,
        policy_type_name: editedData.policyTypeName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const insertPolicyType = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = policyTypeFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        PolicyTypeID: formData?.policy_type_id || 0,
        PolicyTypeName: formData.policy_type_name,
      };

      const endpoint =
        formData.policy_type_id === 0
          ? "PolicyType/InsertMasterMenu"
          : "PolicyType/EditMasterMenu";

      api[formData.policy_type_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyPolicyType);
  };
  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={policyTypeFormRef}
        formName="PolicyTypeForm"
      >
        <Row>
          <InputGroupWithIcon
             ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Policy Type Name"
            icon="fas fa-file-alt"
            type="text"
            name="policy_type_name"
            placeholder="Enter Policy Type Name"
            value={formData.policy_type_name}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={insertPolicyType}
          HeadID={formData?.policy_type_id}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
