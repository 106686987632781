import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Logo from "../assets/logo.png";

export default function LoaderWithLogo() {
  return (
    <>
      <div className="LoaderWithLogo">
        <img src={Logo} alt="" />
      </div>
    </>
  );
}
