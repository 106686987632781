// // CustomCheckbox.js
// import React from 'react';
// import './CustomCheckbox.css'; // Ensure to create and link the CSS file

// const CustomCheckbox = ({ label, name, checked, onChange }) => {
//   return (
//     <div className="checkbox-container">
//       <label className="checkbox-wrapper">
//         <input
//           type="checkbox"
//           className="checkbox-input"
//           name={name}
//           checked={checked}
//           onChange={onChange}
//         />
//         <span className="checkbox-tile">
//           <span className="checkbox-icon">
//           <i className="fab fa-whatsapp"></i>
//           </span>
//         </span>
//       </label>
//     </div>
//   );
// };

// export default CustomCheckbox;

// CustomCheckbox.js
import React from "react";
import "./CustomCheckbox.css"; // Ensure to create and link the CSS file

const CustomCheckbox = ({
  label,
  name,
  checked,
  onChange,
  icon,
  iconColor,
}) => {
  return (
    <div className="checkbox-container">
      <label className="checkbox-wrapper">
        <input
          type="checkbox"
          className="checkbox-input"
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <span
          className="checkbox-tile"
          style={{ borderColor: checked ? iconColor : "#b5bfd9" }}
          onMouseEnter={(event) => {
            // Apply hover effect
            if (!checked) {
              event.currentTarget.style.borderColor = iconColor;
            }
          }}
          onMouseLeave={(event) => {
            // Remove hover effect
            if (!checked) {
              event.currentTarget.style.borderColor = "#494949";
            }
          }}
        >
          <span
            className="checkbox-icon"
            style={{ backgroundColor: checked == true ? iconColor : "" ,color:checked == true ? "white" : "black" }}
            onMouseEnter={(event) => {
                // Apply hover effect
                if (!checked) {
                  event.currentTarget.style.color = iconColor;
                }
              }}
              onMouseLeave={(event) => {
                // Remove hover effect
                if (!checked) {
                  event.currentTarget.style.color = "#494949";
                }
              }}
          >
            <i className={icon}></i>
            {/* <i className="fab fa-whatsapp"></i> */}
          </span>
        </span>
      </label>
    </div>
  );
};

export default CustomCheckbox;
