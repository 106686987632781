import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import CourierStatusMasterComponent from "./CourierStatusMasterComponent";
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function CourierStatusMaster() {
  const [courierStatusData, setCourierStatusData] = useState([]);
  const [editcourierStatusData, setEditcourierStatusData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getcourierStatusData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "CourierStatusMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all document types
  const getcourierStatusData = async () => {
    await getAllData(
      "CourierStatusMaster/AllCouruerStatus",
      setIsLoading,
      setCourierStatusData
    );
  };

  const getSingleCourierStatus = async (id) => {
    await getSingleData(
      `CourierStatusMaster/SingleCourierStatus?courierStatusMasterID=${id}`,
      setEditcourierStatusData,
      setIsLoading
    );
  };

  // Delete document type
  const deleteCourierStatus = async (id) => {
    // You'll need to replace "DocumentType/deleteCourierStatus" with the appropriate API endpoint
    await deleteData(
      `CourierStatusMaster/DeleteCourierStatus?CourierStatusMasterID=${id}`,
      getcourierStatusData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("CourierStatus", "courierStatus", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "courierStatusMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value, data }) =>
              data.courierStatus !== "Dispatched" ? (
                <ion-icon
                  name="create-outline"
                  class="iconsFonts editIcon"
                  onClick={() => getSingleCourierStatus(value)}
                ></ion-icon>
              ) : null,
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "courierStatusMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value, data }) =>
              data.courierStatus !== "Dispatched" ? (
                <ion-icon
                  name="trash-outline"
                  class="iconsFonts deleteIcon"
                  onClick={() => deleteCourierStatus(value)}
                ></ion-icon>
              ) : null,
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New CourierStatus</span>
          </Card.Header>
          <Card.Body>
            <CourierStatusMasterComponent
              returnID={getcourierStatusData}
              editedData={editcourierStatusData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>CourierStatus List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={courierStatusData}
            columnDefs={columnDefs}
            height={500}
            paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
