import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import CKEditorInput from "../../Common/CKEditorInput";
import SubmitButtonOfForm from "../../Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyFormGroup = {
  FormGroupID: 0,
  FormGroupName: "",
  FaIcon: "",
};

export default function FormGroup({ returnID, clearData, onClose }) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const FormGroupFormRef = useRef(null);
  const [formData, setFormData] = useState(emptyFormGroup);
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (FormGroup) => {
    const { name, value, type, checked } = FormGroup.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertFormGroup = (FormGroup) => {
    setValidated(true);
    const form = FormGroupFormRef.current;
    if (!form.checkValidity()) {
      return;
    } else {
      const Data = {
        FormGroupID: formData?.FormGroupID || 0,
        FormGroupName: formData.FormGroupName,
        FaIcon: formData.FaIcon,
      };

      const endpoint =
        formData.FormGroupID === 0
          ? "FormGroup/InsertFormGroup"
          : "FormGroup/EditFormGroup";

      api[formData.FormGroupID === 0 ? "post" : "patch"](endpoint, Data)
        .then((result) => {
          showMessage(result.data.statusCode, result.data.message);
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
            setRefreshKey(refreshKey + 1);
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(400, "An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyFormGroup);
  };

  return (
    <GlobalFormTagWithSubmit
      validated={validated}
      // onSubmit={insertFormGroup}
      refName={FormGroupFormRef}
      formName="FormGroupForm"
    >
      <Row>
        <InputGroupWithIcon
          ColSize={{ sm: 12, md: 8, lg: 6 }}
          Label="FormGroup"
          icon="fas fa-building"
          type="text"
          name="FormGroupName"
          placeholder="Enter FormGroup Name"
          value={formData.FormGroupName}
          onChange={handleInputChange}
          required
        />
      </Row>

      <Row>
        <InputGroupWithIcon
          ColSize={{ sm: 12, md: 8, lg: 6 }}
          Label="FaIcon"
          icon="fas fa-building"
          type="text"
          name="FaIcon"
          placeholder="Enter FaIcon "
          value={formData.FaIcon}
          onChange={handleInputChange}
          required
        />
      </Row>
      <OnClickSubmitButtonOfForm
        onClick={insertFormGroup}
        HeadID={formData?.FormGroupID}
      />
    </GlobalFormTagWithSubmit>
  );
}
