import React, { useEffect, useState } from "react";
import { WhatsAppStatus } from "../../Utils/commonFunction";
import axios from "axios";
import { Button, Image, Card } from "react-bootstrap";
import api from "../../Utils/api";

export default function StatusOfWhatsApp() {
  const [Status, setStatus] = useState("");
  const [qrImage, setQrImage] = useState("");
  const [SMSLoginURL, setSMSLoginURL] = useState("");
  const [ButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    CheckStatus();
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await WhatsAppStatus();
      let url = response.data.smsLoginUrl;
      setSMSLoginURL(url);
    } catch (error) {
      console.error("Error fetching WhatsApp status:", error);
    }
  };

  const CheckStatus = () => {
    // axios
    //   .post(LoginURL)
    //   .then((response) => {
    //     const WhatsAppActive = response.data.data.status;
    //     setStatus(WhatsAppActive);
    //     setQrImage(
    //       response.data.data.qrimg.replace(
    //         "http://127.0.0.1:5000",
    //         "http://whatsapp.taptitechnology.com/nodeproject"
    //       )
    //     );
    //   })
    //   .catch((error) => {
    //     console.error("Error making POST request:", error);
    //   });

    api
      .get(`SMSApi/WhatsAppStatus`)
      .then((WhatsAppStatus1) => {
        if (WhatsAppStatus1.data.message === "false") {
          return null;
        } else {
          debugger
          const { qrimg, status } = WhatsAppStatus1.data.data;
          setStatus(status);
          setQrImage(
            qrimg.replace(
              "http://127.0.0.1:5000",
              "https://whatsapp.taptitechnology.com/nodeproject"
            )
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching WhatsApp status:", error);
      });
  };

  useEffect(() => {
    debugger
    if (Status !== "READY") {
      const intervalId = setInterval(() => {
        CheckStatus();
      }, 4000);

      return () => clearInterval(intervalId); 
    }
  }, [Status]);

  const connectWhatsapp = () => {
    setButtonDisabled(true);
    debugger
  //  const Url = SMSLoginURL.replace("get_connection_status", "connect_now")
    api
      .get(`SMSApi/ConnectWhatsapp`)
      .then((response) => {
        CheckStatus();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  return (
    <>
      <Card className="text-center p-5">
        <Image
          src={qrImage}
          className="img img-responsive"
          style={{ margin: "0 auto" }}
        />
        <div className="mt-5">
          <strong>{Status}</strong>
        </div>
        {Status !== "READY" && Status !== "QR GENERATED" && (
          <div>
            <Button
              variant="primary"
              size="lg"
              onClick={connectWhatsapp}
              id="btnWhatsapp"
              disabled={ButtonDisabled}
            >
              Click To Connect Now
            </Button>
          </div>
        )}
      </Card>
    </>
  );
}
