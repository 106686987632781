import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import PolicyTypeComponent from "./PolicyTypeComponent"; // You'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function PolicyType() {
    const [policyTypeData, setPolicyTypeData] = useState([]);
  const [editPolicyTypeData, setEditPolicyTypeData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getPolicyTypeData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "PolicyType",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all policy types
  const getPolicyTypeData = async () => {
    await getAllData("PolicyType/AllPolicyType", setIsLoading, setPolicyTypeData);
  };

  const getSinglePolicyType = async (id) => {
    await getSingleData(
      `PolicyType/SingleMasterMenu?PolicyTypeID=${id}`,
      setEditPolicyTypeData,
      setIsLoading
    );
  };

  // Delete policy type
  const deletePolicyType = async (id) => {
    await deleteData(
      `PolicyType/DeleteMasterMenu?PolicyTypeID=${id}`,
      setIsLoading,
      getPolicyTypeData
    );
  };

  const columnDefs = [
    createColumnDef("Policy Type Name", "policyTypeName", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "policyTypeID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSinglePolicyType(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "policyTypeID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deletePolicyType(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];
  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Policy Type</span>
          </Card.Header>
          <Card.Body>
            <PolicyTypeComponent
              returnID={getPolicyTypeData}
              editedData={editPolicyTypeData}
            />
          </Card.Body>
        </Card>
      )}

      <Card>
        <Card.Header>
          <span>Policy Type List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={policyTypeData} columnDefs={columnDefs} height={500} paginationPageSize={25}/>
        </Card.Body>
      </Card>
    </>
  )
}
