import React, { useEffect, useState, useRef } from "react";
import { Form, Col, Row } from "react-bootstrap";
import pdfLogo from "../assets/icons8-pdf-60.png";

export default function ImageAddAndPreview({
  imagePreview,
  ColSize = { sm: 4, md: 4, lg: 4 },
  Label,
  multiple,
  onFileUpload,
  ClearImages,
  DisplayImagePreview,
  required
}) {
  const [imageView, setImageView] = useState([]);

  const imageInputRef = useRef(null);

  useEffect(() => {
    ;
    if (ClearImages) {
      clearImages();
    }
  }, [ClearImages]);

  const clearImages = () => {
    setImageView([]);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
  };

  const handleInputChange = (event) => {
    const { files } = event.target;

    const newImages = Array.from(files);
    const updatedViews = Array.isArray(imageView) ? [...imageView] : [];

    newImages.forEach((newImage) => {
      const reader = new FileReader();
      const fileType = newImage.type;

      reader.onloadend = () => {
        updatedViews.push({ url: reader.result, type: fileType });
        setImageView(updatedViews);
        onFileUpload(newImage);
      };

      reader.readAsDataURL(newImage);
    });
  };

  return (
    <>
      <Col
       md={ColSize.md} sm={ColSize.sm} lg={ColSize.lg}
      >
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{Label}:</Form.Label>
          <Form.Control
            type="file"
            onChange={handleInputChange}
            ref={imageInputRef}
            multiple={multiple}
            required={required}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Select ${Label}.`}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      {DisplayImagePreview && (
        <Row>
          {imageView.map((image, index) => (
            <Col key={index} xs={3}>
              {image.type && image.type.toLowerCase().includes("pdf") ? (
                <a href={image.url} target="_blank" rel="noopener noreferrer">
                  <img src={pdfLogo} alt="PDF" width="100%" height="200px" />
                </a>
              ) : (
                <img
                  src={image.url}
                  alt={`Image ${index}`}
                  width="100%"
                  height="200px"
                />
              )}
            </Col>
          ))}
          {imagePreview &&
            imagePreview.map((imageView, index) => (
              <Col key={index} xs={3} md={3}>
                {imageView && imageView.toLowerCase().includes("pdf") ? (
                  <a href={imageView} target="_blank" rel="noopener noreferrer">
                    <img src={pdfLogo} alt="PDF" width="100%" height="200px" />
                  </a>
                ) : (
                  <img
                    src={imageView}
                    alt={`Image ${index}`}
                    width="100%"
                    height="200px"
                  />
                )}
              </Col>
            ))}
        </Row>
      )}
    </>
  );
}

// How to use
// const MyComponent = () => {
// const imageInputRef = useRef(null);
// const [selectedImage, setSelectedImage] = useState(null);
// const [imagePreview, setImagePreview] = useState(null);

// const handleImageChange = (event) => {
//   setSelectedImage(event.target.files[0]);
//   const reader = new FileReader();
//   reader.onloadend = () => {
//     setImagePreview(reader.result);
//   };
//   reader.readAsDataURL(event.target.files[0]);
// };

// const reset = () => {
//   setSelectedImage(null);
//   setImagePreview(null);
//   if (imageInputRef.current) {
//     imageInputRef.current.value = null;
//   }
//   setFormData(emptyGallery);
// };

//   return (
//   <ImageAddAndPreview
//   imagePreview={imagePreview}
//   onChange={handleImageChange}
//   imageInputRef={imageInputRef}
//   Label = "Image"
// />
//   );
// };

// export default MyComponent;
