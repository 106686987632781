// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
// import { AgGridReact } from "ag-grid-react";

// const AgGridComponent = ({
//   grid,
//   rowData,
//   columnDefs,
//   onSelectionChanged,
//   autoGroupColumnDef,
//   onGridReady,
//   paginationPageSize,
// }) => {
//   return (
//     <div className="ag-theme-alpine ag-responsive-grid" >
//       <AgGridReact
//         ref={grid}
//         rowData={rowData}
//         columnDefs={columnDefs}
//         pagination={true}
//         paginationPageSize={paginationPageSize}
//         domLayout="autoHeight"
//         rowSelection="multiple"
//         animateRows={true}
//         onGridReady={onGridReady}
//       ></AgGridReact>
//     </div>
//   );
// };

// export default AgGridComponent;
import React, { useRef ,useMemo} from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import BeatLoader from "react-spinners/BeatLoader";

// import CustomLoadingOverlay from './customLoadingOverlay';
// import { ModuleRegistry } from "@ag-grid-community/core";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

// ModuleRegistry.registerModules([ClientSideRowModelModule]);
const CustomLoadingOverlay = () => {
  return (
    // <div role="presentation">
    //   <div
    //     role="presentation"
    //     style={{
    //       height: 100,
    //       margin: '0 auto',
    //     }}
    //     className="custom-loading-overlay"
    //   >
    //     <span className="loader"></span>
    //   </div>
    // </div>
    <BeatLoader color="#3cb5ff"  />
  );
};

const AgGridComponent = ({
  grid,
  rowData,
  columnDefs,
  onGridReady,
  paginationPageSize,
  height,
  loading 
}) => {

  const loadingOverlayComponentParams = useMemo(() => {
    return { loadingMessage: 'One moment please...' };
}, []);
  
  return (
    <div className="ag-theme-quartz my-grid-margin" style={{ height: height }}>
      <AgGridReact
        rowBuffer={25}
        ref={grid}
        loading={loading}
        rowData={rowData}
        columnDefs={columnDefs}
        animateRows={true}
        onGridReady={onGridReady}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        pagination={true}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={[paginationPageSize,10, 25, 50]}
        loadingOverlayComponent={CustomLoadingOverlay}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
      ></AgGridReact>
    </div>
  );
};

export default AgGridComponent;


// import React, { useState, useRef, useEffect } from "react";
// import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-quartz.css";

// const AgGridComponent = ({
//   rowData,
//   columnDefs,
//   onGridReady,
//   paginationPageSize = 10,
//   height,
//   isLoading
// }) => {
//   const gridRef = useRef();

//   useEffect(() => {
//     if (gridRef.current && gridRef.current.api) {
//       if (isLoading) {
//         gridRef.current.api.showLoadingOverlay();
//       } else {
//         gridRef.current.api.hideOverlay();
//       }
//     }
//   }, [isLoading]);

//   return (
//     <div className="ag-theme-quartz" style={{ height: height }}>
//       <AgGridReact
//         ref={gridRef}
//         rowData={rowData}
//         columnDefs={columnDefs}
//         animateRows={true}
//         onGridReady={onGridReady}
//         rowSelection="multiple"
//         suppressRowClickSelection={true}
//         pagination={true}
//         paginationPageSize={paginationPageSize}
//       ></AgGridReact>
//     </div>
//   );
// };

// export default AgGridComponent;
