// PolicyDetailContext.js
import React, { createContext, useContext, useState } from "react";

const PolicyDetailContext = createContext();

export const usePolicyDetailContext = () => useContext(PolicyDetailContext);

export const PolicyDetailProvider = ({ children }) => {
  const [activeKey, setActiveKey] = useState("policy");
  const [PolicyIDForDetails, setPolicyIDForDetails] = useState(null);

  return (
    <PolicyDetailContext.Provider value={{ activeKey, setActiveKey ,PolicyIDForDetails ,setPolicyIDForDetails}}>
      {children}
    </PolicyDetailContext.Provider>
  );
};