import React, { useEffect, useInsertionEffect, useState, useRef } from "react";
import { getSingleData, deleteData } from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import AgGridComponent from "../../Common/AgGridComponent";
import { Card, Row } from "react-bootstrap";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import DocumentTypeComponent from "../DocumentTypes/DocumentTypesComponent";
import Loader from "../../Common/Loader ";
import ImageAddAndPreview from "../../Common/ImageAddAndPreview";
import api from "../../Utils/api";
import UserSetting from "../../UserSettings.json";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import TextArea from "../../Common/TextArea";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyKYCOfPolicy = {
  policyID: 0,
  DocumentTypeID: 0,
  Remarks: "",
};

export default function KYCComponent({ policyID }) {
  const [isLoading, setIsLoading] = useState(false);
  const [KYCDataList, setKYCDataList] = useState([]);
  const [formData, setFormData] = useState(emptyKYCOfPolicy);
  const [ddValidationError, setDDValidationError] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedImage, setSelectedImage] = useState([]);
  const KYCdocumentTypeFormRef = useRef(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (policyID) {
      getKYCDataOfPolicy();
    }
  }, [policyID]);

  const getKYCDataOfPolicy = async () => {
    await getSingleData(
      `KYC/PolicyAllKYCDocument?policyID=${policyID}`,
      setKYCDataList,
      setIsLoading
    );
  };

  const insertKYCDocument = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = KYCdocumentTypeFormRef.current;
    if (!form.checkValidity()) {
      setDDValidationError(formData.DocumentTypeID === 0);
      setIsLoading(false);
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("PolicyID", policyID);
      form_Data.append("DocumentTypeID", formData.DocumentTypeID);
      form_Data.append("Remarks", formData.Remarks);
      form_Data.append("image", selectedImage);
      // for (let i = 0; i < selectedImage.length; i++) {
      //   form_Data.append(`image[${i}]`, selectedImage[i]);
      // }

      api
        .post("KYC/InsertPolicyKYCDocument", form_Data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            getKYCDataOfPolicy();
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyKYCOfPolicy);
    setRefreshKey(refreshKey + 1);
    setSelectedImage([]);
  };

  const deleteKYCDocument = async (id) => {
    await deleteData(
      `KYC/DeletePolicyKYCDocument?ImageForAllID=${id}`,
      getKYCDataOfPolicy,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("Document Type Name", "documentType", 150),
    createColumnDef("Remarks", "remarks", 300),
    {
      headerName: "View",
      width: 80,
      field: "imagePath",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <a href={UserSetting.imgURL + value} target="_blank">
          <ion-icon name="eye-outline" class="iconsFonts"></ion-icon>
        </a>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 80,
      field: "imageForAllID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteKYCDocument(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ];

  const handleImageUpload = (imageFile) => {
    setSelectedImage( imageFile);
  };

  return (
    <>
      {isLoading && <Loader />}

      <Card.Body>
        <GlobalFormTagWithSubmit
          validated={validated}
          refName={KYCdocumentTypeFormRef}
          formName="KYCdocumentType"
        >
          <Row>
            <GlobalDDComponent
              ColSize={8}
              formLabel="Document Type"
              apiEndpoint="DocumentType/AllDocumentTypes"
              valueOfDD="documentTypeID"
              labelOfDD="documentTypeName"
              onSelectOption={(DocumentTypeID) => {
                setFormData((prevData) => ({
                  ...prevData,
                  DocumentTypeID: DocumentTypeID,
                }));
                setDDValidationError(false);
              }}
              selectedOptionOfComponent={formData.DocumentTypeID}
              ValidationError={ddValidationError}
              offcanvasTitle="Add Document Type"
              offcanvasBodyComponent={<DocumentTypeComponent />}
            />
          </Row>
          <Row>
            <ImageAddAndPreview
              ClearImages={refreshKey}
              Label="Document"
              onFileUpload={handleImageUpload}
              ColSize={8}
              required
            />
          </Row>
          <Row>
            <TextArea
              ColSize={8}
              rows={3}
              Label=" Remarks"
              icon="fas fa-pencil-alt"
              name="Remarks"
              placeholder=" Remarks"
              value={formData.Remarks}
              onChange={(data) => {
                setFormData((prevData) => ({
                 ...prevData,
                  Remarks: data.target.value,
                }));  
              }}
            />
          </Row>
          <OnClickSubmitButtonOfForm onClick={insertKYCDocument} HeadID={0} />
        </GlobalFormTagWithSubmit>
      </Card.Body>
      <Card>
        <AgGridComponent rowData={KYCDataList} columnDefs={columnDefs}  height={500} paginationPageSize={25}/>
      </Card>

      {/* <Card>
        <Card.Header> KYC</Card.Header>

        <Card.Body>
          
        </Card.Body>
      </Card> */}
    </>
  );
}
