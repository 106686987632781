import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import TextArea from "../../Common/TextArea";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyHospital = {
  hospital_master_id: 0,
  hospital_name: "",
  hospital_address: "",
};

export default function HospitalMasterComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyHospital);
  const hospitalFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        hospital_master_id: editedData.hospitalMasterID,
        hospital_name: editedData.hospitalName,
        hospital_address: editedData.hospitalAddress,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const insertHospitalMaster = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = hospitalFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        HospitalMasterID: formData?.hospital_master_id || 0,
        HospitalName: formData.hospital_name,
        HospitalAddress: formData.hospital_address,
      };

      const endpoint =
        formData.hospital_master_id === 0
          ? "HospitalMaster/InsertHospital"
          : "HospitalMaster/EditHospital";

      api[formData.hospital_master_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyHospital);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={hospitalFormRef}
        formName="HospitalForm"
      >
        <Row>
          <InputGroupWithIcon
             ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Hospital Name"
            icon="fas fa-hospital"
            type="text"
            name="hospital_name"
            placeholder="Enter Hospital Name"
            value={formData.hospital_name}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <TextArea
             ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Address"
            icon="fas fa-hospital"
            name="hospital_address"
            placeholder="Enter Address"
            value={formData.hospital_address}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={insertHospitalMaster}
          HeadID={formData?.hospital_master_id}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
