import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, InputGroup, Form } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import EndorsementComponent from "./EndorsementComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";
import UserSetting from "../../UserSettings.json";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { convertDateFormat } from "../../Utils/commonFunction";
import {
  GetPre7DayDate,
  getCurrentDate,
  GetPre30DayDate,
  filterParams
} from "../../Utils/commonFunction";
import api from "../../Utils/api";
import { InputGroupWithIconAndInLineLabel } from "../../Common/MyComponent";
import GlobalModal from "../../Common/GlobalModal";
import SendSMSEndorsement from "./SendSMSEndorsement";

const emtSearchFormData = {
  start_date: GetPre30DayDate(),
  end_date: getCurrentDate(),
  policyNo: "",
};
export default function Endorsement() {
  const [endorsementData, setEndorsementData] = useState([]);
  const [editEndorsementData, setEditEndorsementData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const [formData, setFormData] = useState(emtSearchFormData);
  const [showSendSMSModel, setShowSendSMSModel] = useState(false);
  const [EndorsementIDForSendSMS, setEndorsementIDForSendSMS] = useState(null);

  useEffect(() => {
    getEndorsements();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "Endorsement",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  const getEndorsements = async () => {
    const { start_date, end_date, policyNo } = formData;
    if (new Date(start_date) > new Date(end_date)) {
      alert("Start date cannot be after the end date.");
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `Endorsement/AllEndorsementsDateWise?START_DATE=${start_date}&END_DATE=${end_date}&PolicyNo=${policyNo}`
      );
      setEndorsementData(Array.isArray(data.data) ? data.data : []);
    } catch (error) {
      console.error("Not Found");
      setEndorsementData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getSingleEndorsement = async (id) => {
    await getSingleData(
      `Endorsement/SingleEndorsement?EndorsementID=${id}`,
      setEditEndorsementData,
      setIsLoading
    );
  };

  // Delete endorsement
  const deleteEndorsement = async (id) => {
    await deleteData(
      `Endorsement/DeleteEndorsement?EndorsementID=${id}`,
      getEndorsements,
      setIsLoading
    );
  };

  const handleReset = () => {
    setFormData(emtSearchFormData);
  };

  useEffect(() => {
    if (formData === emtSearchFormData) {
      getEndorsements();
    }
  }, [formData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const HandleSendSMSModel = () => {
    setShowSendSMSModel(false);
  };

  const exportToExcel = () => {
    setIsLoading(true);
    setTimeout(() => {
      
      const fileName = "Endorsementdata_data.xlsx";
      
      const formattedData = endorsementData.map((item) => ({
      EndorsementName: item.endorsementName,
      EndorsementDate:item.endorsementDate && convertDateFormatWithOutTime(item.endorsementDate),
      PolicyNo: item.policyNo,
      ClientName: item.clientName,
      PolicyHolderName: item.policyHolderName,
      NetAmount: item.netAmount,
      ServiceTax: item.serviceTax,
      ServiceTaxAmount: item.serviceTaxAmount,
      PremiumAmt: item.premiumAmt,
      Remarks :item.remarks,
      ImageURL: `View Image`, // Placeholder text for clickable link
      ImagePath: UserSetting.imgURL + item.imagePath, // Store the actual URL separately
    }));
    
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Endorsement Data");
    
    worksheet.columns = [
      { header: "EndorsementDate", key: "EndorsementDate", width: 20 },
      { header: "PolicyNo", key: "PolicyNo", width: 20 },
      { header: "ClientName", key: "ClientName", width: 20 },
      { header: "Policy Holder", key: "PolicyHolderName", width: 20 },
      { header: "Endorsement Name", key: "EndorsementName", width: 20 },
      { header: "Premium Amt", key: "PremiumAmt", width: 20 },
      { header: "Service Tax", key: "ServiceTax", width: 20 },
      { header: "Service TaxAmount", key: "ServiceTaxAmount", width: 20 },
      { header: "Net Amount", key: "NetAmount", width: 20 },
      { header: "Remarks", key: "Remarks", width: 20 },
    
      { header: "Image URL", key: "ImageURL", width: 40 }, // Column for image URL
    ];
    
    worksheet.addRows(formattedData);
    
    // Style the header row
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF4F81BD" },
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });
    
    // Add hyperlink formula to each cell in Image URL column
    worksheet.getColumn("ImageURL").eachCell((cell, rowNumber) => {
      if (rowNumber > 1) {
        // Skip header row
        const imagePath = formattedData[rowNumber - 2].ImagePath;
        cell.value = { formula: `HYPERLINK("${imagePath}","View Image")` };
      }
    });
    
    // Adjust column widths based on content length
    worksheet.columns.forEach((column) => {
      let maxLength = column.header.length;
      formattedData.forEach((row) => {
        const cellValue = row[column.key];
        if (cellValue) {
          maxLength = Math.max(maxLength, cellValue.toString().length);
        }
      });
      column.width = maxLength + 2; // Add padding
    });
    
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
    });
    setIsLoading(false);
  }, 1000);
  };
  
  const columnDefs = [
    {
      headerName: "Endorsement Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 200,
      headerClass: "widthTableColum",
      field: "endorsementDate",
      headerClass: "centered-header",
      cellRenderer: ({ value }) => convertDateFormatWithOutTime(value),
    },
    createColumnDef("PolicyNo", "policyNo", 300),
    createColumnDef("ClientName", "clientName", 300),
    createColumnDef("Policy Holder", "policyHolderName", 300),
    createColumnDef("Endorsement Name", "endorsementName", 250),
    createColumnDef("Premium Amt", "premiumAmt", 150),
    createColumnDef("Service Tax", "serviceTax", 150),
    createColumnDef("ServiceTaxAmount", "serviceTaxAmount", 250),
    createColumnDef("Net Amount", "netAmount", 150),
    createColumnDef("Remarks", "remarks", 150),
    {
      headerName: "View",
      width: 80,
      field: "imagePath",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <a href={UserSetting.imgURL + value} target="_blank">
          <ion-icon name="eye-outline" class="iconsFonts"></ion-icon>
        </a>
      ),
      pinned: window.innerWidth > 768 ? "right" : "",
    },
    {
      headerName: "Send",
      width: 80,
      field: "endorsementID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <button
          onClick={() => {
            setShowSendSMSModel(true);
            setEndorsementIDForSendSMS(value);
          }}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              e.preventDefault();
              setShowSendSMSModel(true);
              setEndorsementIDForSendSMS(value);
            }
          }}
        >
          <ion-icon name="send-sharp" class="iconsFonts"></ion-icon>
        </button>
      ),
      pinned: window.innerWidth > 768 ? "right" : "",
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "endorsementID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleEndorsement(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "endorsementID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteEndorsement(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];
  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Endorsement</span>
          </Card.Header>
          <Card.Body>
            <EndorsementComponent
              returnID={getEndorsements}
              editedData={editEndorsementData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Endorsement List</span>
          <Button
            type="button"
            variant="success"
            className="mb-20px"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </Card.Header>
        <Card.Body>
          <Row className="align-items-center mb-2">
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={4} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">Policy No</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="policyNo"
                  placeholder="Enter Policy No"
                  value={formData.policyNo}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={2}
              className="d-flex justify-between items-center"
            >
              <Button variant="success" onClick={getEndorsements}>
                <i class="fa fa-search"></i> Search
              </Button>
              <Button variant="danger" onClick={handleReset}>
                <i class="	fa fa-refresh"></i> Reset
              </Button>
            </Col>
          </Row>
          <AgGridComponent
            rowData={endorsementData}
            columnDefs={columnDefs}
            height={500}
            paginationPageSize={25}
          />
        </Card.Body>
        <GlobalModal
          title="Send SMS"
          bodyComponent={
            <SendSMSEndorsement
              // ={EndorsementIDForSendSMS}
              EndorsementID={EndorsementIDForSendSMS}
              onCloseSendSMS={() => {
                setShowSendSMSModel(false);
              }}
            />
          }
          isOpen={showSendSMSModel}
          onClose={HandleSendSMSModel}
        />
      </Card>
    </>
  );
}
