import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import api from "../Utils/api";

export default function GlobalFormSelectComponent({
  apiEndpoint,
  formLabel,
  valueOfDD,
  ColSize = { sm: 12, md: 12, lg: 12 },
  labelOfDD,
  onSelectOption,
  selectedOptionOfComponent,
  reset,
  ValidationError,
  handleCloseModal,
  required,
  disabled
}) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (ValidationError && selectedOptionOfComponent === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedOptionOfComponent]);

  useEffect(() => {
    if (handleCloseModal) {
      handleCloseOffcanvas();
    }
  }, [handleCloseModal]);

  useEffect(() => {
    if (reset) {
      setSelectedOption("");
    }
  }, [reset]);

  const fetchData = () => {
    api
      .get(apiEndpoint)
      .then((result) => {
        const list = result.data.data;
        setOptions(list);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    
    if (selectedOptionOfComponent) {
      const selected = options.find(
        (option) => option[valueOfDD] === parseInt(selectedOptionOfComponent, 10)
      );
      setSelectedOption(selected ? selected[valueOfDD] : "");
    }
    if (selectedOptionOfComponent === 0) {
      setSelectedOption("");
    }
  }, [selectedOptionOfComponent, options, valueOfDD, labelOfDD]);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onSelectOption(selectedValue ? selectedValue : null);
  };

  const handleCloseOffcanvas = () => {
    // Logic for handling the close of the offcanvas, if needed
  };

  const selectDDOption = (ID) => {
    handleOptionChange({ target: { value: ID } });
    fetchData();
    handleCloseOffcanvas();
  };

  return (
    <>
      <Col md={ColSize.md} sm={ColSize.sm} lg={ColSize.lg}>
        <Form.Group controlId="MasterMenu_id">
          {formLabel && <Form.Label>{formLabel}</Form.Label>}
          <Form.Select
            className={`w-100 ${validation ? "borderRed" : ""}`}
            value={selectedOption}
            onChange={handleOptionChange}
            required ={required}
            disabled = {disabled}
          >
            <option value="" disabled>
              Select
            </option>
            {options.map((option) => (
              <option key={option[valueOfDD]} value={option[valueOfDD]}>
                {option[labelOfDD]}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
          Please select Option
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </>
  );
}
