import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import api from "../../Utils/api";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import UserMasterComponent from "./UserMasterComponent";
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function UserMaster() {
  const [UserMasterData, setUserMasterData] = useState([]);
  const [editUserMasterData, setEditUserMasterData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getUserMaster();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "UserMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all Areas
  const getUserMaster = async () => {
    await getAllData("UserMaster/AllUser", setIsLoading, setUserMasterData);
  };

  const getSingleUserMaster = async (id) => {
    await getSingleData(
      `UserMaster/SingleUser?UserID=${id}`,
      setEditUserMasterData,
      setIsLoading
    );
  };

  // Delete Area
  const DeleteUserMaster = async (id) => {
    await deleteData(
      `UserMaster/DeleteUser?UserID=${id}`,
      setIsLoading,
      getUserMaster
    );
  };

  const UpdateActiveAndDeActive = (u_ID, active_type) => {
    const Active = !Boolean(active_type);
    api
      .patch(
        `UserMaster/UpdateUserActivateOrDeActivate?UserID=${u_ID}&IsActive=${Active}`
      )
      .then((result) => {
        getUserMaster();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columnDefs = [
    createColumnDef("User Name", "userName", 150),
    createColumnDef("Mobile Number", "mobileNumber", 150),
    createColumnDef("Email ID", "emailID", 150),
    {
      headerName: "Active",
      field: "isActive",
      headerClass: "center-text widthTableColum",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: ({ value, data }) => {
        if (data.isActive) {
          return (
            <button
              className="btn btn-outline-success mb-2"
              onClick={() =>
                UpdateActiveAndDeActive(data.userID, data.isActive)
              }
            >
              <strong>Active</strong>
            </button>
          );
        } else {
          return (
            <button
              className="btn btn-danger mb-2"
              onClick={() =>
                UpdateActiveAndDeActive(data.userID, data.isActive)
              }
            >
              <strong>De-Active</strong>
            </button>
          );
        }
      },
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "userID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleUserMaster(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "userID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => DeleteUserMaster(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New User</span>
          </Card.Header>
          <Card.Body>
            <UserMasterComponent
              returnID={getUserMaster}
              editedData={editUserMasterData}
              // getUserMasterData={getSingleUserMaster}
            />
          </Card.Body>
        </Card>
      )}

      <Card>
        <Card.Header>
          <span>User List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={UserMasterData} columnDefs={columnDefs} height={500} paginationPageSize={25}/>
        </Card.Body>
      </Card>
    </>
  );
}
