import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyDocumentType = {
  courierNameMasterID: 0,
  courierName: "",
};

export default function CourierNameMasterComponent({ returnID, editedData, clearData, onClose }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyDocumentType);
  const courierNameFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        courierNameMasterID: editedData.courierNameMasterID,
        courierName: editedData.courierName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertCourierStatus = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = courierNameFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        CourierNameMasterID: formData?.courierNameMasterID || 0,
        CourierName: formData.courierName,
      };

      const endpoint =
        formData.courierNameMasterID === 0
          ? "CourierNameMaster/InsertCourierName"
          : "CourierNameMaster/EditCourierName";

      api[formData.courierNameMasterID === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyDocumentType);
  };

  return (
    <>
      <GlobalFormTagWithSubmit validated={validated} refName={courierNameFormRef} formName="CourierNameForm">
        <Row>
          <InputGroupWithIcon
             ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="CourierName"
            icon="fas fa-file-alt"
            type="text"
            name="courierName"
            placeholder="Enter CourierName"
            value={formData.courierName}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm onClick={insertCourierStatus} HeadID={formData?.courierNameMasterID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
