import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import GlobalCheckbox from "../../Common/GlobalCheckbox";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyUser = {
  user_id: 0,
  user_name: "",
  password: "",
  mobile_Number: "",
  email_id: "",
  user_type: "",
  IsActive: true,
};

export default function UserMasterComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyUser);
  const UserFormRef = useRef(null);
  const [userOption, setUserOption] = useState([]);
  const { setIsLoading } = useLoadingContext();


  useEffect(() => {
    if (editedData) {
      ;
      setFormData({
        user_id: editedData.userID,
        user_name: editedData.userName,
        password: editedData.password,
        mobile_Number: editedData.mobileNumber,
        email_id: editedData.emailID,
        IsActive: editedData.IsActive,
        user_type: editedData.userType,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  useEffect(() => {
    getRoleTemplateName();
  }, []);

  const getRoleTemplateName = () => {
    return new Promise((resolve, reject) => {
      api
        .get("RoleTemplateDetail/AllRoleTemplate")
        .then((result) => {
          setUserOption(result.data.data);
          resolve(result.data.data); // Resolve with the data
        })
        .catch((error) => {
          console.log(error);
          reject(error); // Reject with the error
        });
    });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertUserMaster = (event) => {
    setValidated(true);
    setIsLoading(true)
    const form = UserFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false)
      return;
    } else {
      const Data = {
        UserID: formData?.user_id || 0,
        UserName: formData.user_name,
        Password: formData.password,
        MobileNumber: formData.mobile_Number,
        UserType: formData.user_type,
        EmailID: formData.email_id,
        IsActive: formData.IsActive,
      };

      const endpoint =
        formData.user_id === 0
          ? "UserMaster/InsertUser"
          : "UserMaster/EditUser";

      api[formData.user_id === 0 ? "post" : "patch"](endpoint, Data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false)
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyUser);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={UserFormRef}
        formName="UserForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 4, lg: 4 }}
            Label="User Name"
            icon="fas fa-user"
            type="text"
            name="user_name"
            placeholder="Enter User Name"
            value={formData.user_name}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
           ColSize={{ sm: 12, md: 4, lg: 4 }}
            Label="Password"
            icon="fas fa-lock"
            type="password"
            name="password"
            placeholder="Enter Password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
             ColSize={{ sm: 12, md: 4, lg: 4 }}
            Label="Mobile No"
            icon="fas fa-mobile"
            type="text"
            name="mobile_Number"
            placeholder="Enter Mobile No"
            value={formData.mobile_Number}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <InputGroupWithIcon
            ColSize={{ sm: 12, md: 4, lg: 4 }}
            Label="Email ID"
            icon="fas fa-envelope"
            type="text"
            name="email_id"
            placeholder="Enter Email"
            value={formData.email_id}
            onChange={handleInputChange}
            required
          />
          <Col md={4} sm={12} lg={4}>
            <Form.Group controlId="user_type">
              <Form.Label>User Role:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-user"></i>
                </InputGroup.Text>
                <Form.Select
                  aria-label="Select User Role"
                  value={formData.user_type}
                  name="user_type"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">-- Select User Role --</option>
                  {userOption.map((option) => (
                    <option key={option.roleName} value={option.roleName}>
                      {option.roleName}
                    </option>
                  ))}
                </Form.Select>
                {validated && !formData.user_type && (
                  <div className="invalid-feedback">
                    Please Select User Role.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <GlobalCheckbox
            label="Is Active"
            name="IsActive"
            checked={formData.IsActive}
            onChange={handleInputChange}
          />
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={insertUserMaster}
          HeadID={formData?.user_id}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
