import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";

const InputGroupWithIcon = ({
  icon,
  type,
  Label,
  name,
  placeholder,
  value,
  onChange,
  required,
  onKeyPress,
  disabled,
  ColSize = { sm: 4, md: 4, lg: 4 },
}) => {
  return (
    <Col md={ColSize.md} sm={ColSize.sm} lg={ColSize.lg}>
      <Form.Group controlId={name}>
        {Label && <Form.Label>{Label}:</Form.Label>}
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <i className={icon}></i>
          </InputGroup.Text>
          <Form.Control
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            onKeyPress={onKeyPress}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Enter ${Label}.`}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
  );
};

export default InputGroupWithIcon;

// How to use
// const emptyData= {
//   Field....
// }
// const MyComponent = () => {
// const [formData, setFormData] = useState(emptyData);

// const handleInputChange = (UserMaster) => {
//   const { name, value, type, checked } = UserMaster.target;
//   const inputValue = type === "checkbox" ? checked : value;
//   setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
// };

//   return (
//   <Row>
//   <InputGroupWithIcon
//   ColSize={8}
//     icon="fas fa-building"
//     type="text"
//     name="Name"
//     placeholder="Enter  Name"
//     value={formData.Name}
//     onChange={handleInputChange}
//     required
//   />
// </Row>
//   );
// };

// export default MyComponent;

export const InputGroupWithOutIconAndUpperLabel = ({
  icon,
  type,
  name,
  placeholder,
  value,
  onChange,
  required,
  ColSize,
}) => {
  return (
    <Col
      md={ColSize ? ColSize : 4}
      sm={ColSize ? ColSize : 4}
      lg={ColSize ? ColSize : 4}
    >
      <Form.Group controlId={name}>
        <Form.Label>{name}:</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <i className={icon}></i>
          </InputGroup.Text>
          <Form.Control
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
          />
          <Form.Control.Feedback type="invalid">
            {`Please Enter ${name}.`}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
  );
};
