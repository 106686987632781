import React, { useState, useEffect, useRef } from "react";
import { Popover, Form, Button, Card, Col, InputGroup } from "react-bootstrap";
import api from "../Utils/api";
import GlobalFormTagWithSubmit from "../Common/GlobalFormTagWithSubmit";
import InputGroupWithIcon from "../Common/InputGroupWithIcon ";
import OnClickSubmitButtonOfForm from "../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../Loading/LoadingContext";
import { decryptAndGetItemCookiesStorage } from "../Utils/CookiesStorage";
import { showMessage } from "../Utils/showMessageUtils";

const UserData = {
  user_id: 0,
  user_name: "",
  password: "",
};

const UserProfilePopover = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(UserData);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [validated, setValidated] = useState(false);
  const UserProfileFormRef = useRef(null);
  const UserID = decryptAndGetItemCookiesStorage("user_ID");

  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    
    if (UserID) {
      getSingleUser();
    }
  }, [UserID]);

  const getSingleUser = () => {
    setIsLoading(true);
    api
      .get(`UserMaster/SingleUser?UserID=${UserID}`)
      .then((result) => {
        const UserData = result.data.data;
        setFormData({
          user_id: UserData.userID,
          user_name: UserData.userName,
          password: UserData.password,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = UserProfileFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        userID: formData.user_id,
        userName: formData.user_name,
        password: formData.password,
      };
      api
        .patch("UserMaster/UpdateUserProfile", data)
        .then((result) => {
          setValidated(false); // Reset validation state
          showMessage(result.data.statusCode,result.data.message)
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        }).finally(()=>{
            setIsLoading(false);
        })
    }
  };

  return (
    <Card>
      <Card.Body>
        <GlobalFormTagWithSubmit
          validated={validated}
          refName={UserProfileFormRef}
          formName="UserProfileFormForm"
        >
          <InputGroupWithIcon
            ColSize={4}
            Label="User Name"
            icon="fas fa-user"
            type="text"
            name="user_name"
            placeholder="Enter User Name"
            value={formData.user_name}
            onChange={handleInputChange}
            required
          />
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="MasterMenu_id">
              <Form.Label>Password:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </InputGroup.Text>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Password"
                  style={{
                    border: passwordValidation ? "2px solid #dc3545" : "",
                  }}
                  required
                />
                <InputGroup.Text>
                  <span
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`fa ${
                        showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                    ></i>
                  </span>
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <OnClickSubmitButtonOfForm onClick={handleSubmit} HeadID={10} />
        </GlobalFormTagWithSubmit>
      </Card.Body>
      {/* <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="formUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            name="user_name"
            value={formData.user_name}
            onChange={handleInputChange}
            placeholder="Enter username"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid username.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formPassword" className="inputBox">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Password"
            style={{ border: passwordValidation ? "2px solid #dc3545" : "" }}
            required
          />
          <i className="fa fa-lock"></i>
          <span
            onClick={togglePasswordVisibility}
            style={{ cursor: "pointer" }}
          >
            <i className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
          </span>
          <Form.Control.Feedback type="invalid">
            Please provide a valid password.
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit">
          Update
        </Button>
      </Form> */}
    </Card>
  );
};

export default UserProfilePopover;
