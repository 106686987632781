import React from "react";
import { Button } from "react-bootstrap";

export default function SubmitButtonOfForm({ HeadID }) {
  return (
    <div className="margin_top10 items-end flex justify-end">
      <Button type="submit" variant="primary" className="mb-20px btn-tapti">
        {HeadID > 0 ? "Update" : "Save"}
      </Button>
    </div>
  );
}

// How to use
// const MyComponent = () => {

//   return (
//   <GlobalFormTagWithSubmit

// >
// <SubmitButtonOfForm HeadID={formData?.ID} />
// </GlobalFormTagWithSubmit>
//   );
// };

// export default MyComponent;
