import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
    getAllData,
    getSingleData,
    deleteData,
    CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import EmailTemplateComponent from "./EmailTemplateComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function EmailTemplate() {
    const [EmailTemplateData, setEmailTemplateData] = useState([]);
    const [editEmailTemplateData, setEditEmailTemplateData] = useState(null);
    const { setIsLoading } = useLoadingContext();
    const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

    useEffect(() => {
        getEmailTemplateData();
        UserPermissions();
    }, []);

    const UserPermissions = async () => {
        await CheckRoleTemplatePermissions(
            "EmailTemplate",
            setRoleTemplatePermissions,
            setIsLoading
        );
    };

    // Fetch all document types
    const getEmailTemplateData = async () => {
        
        await getAllData(
            "EmailTemplate/AllEmailTemplate",
            setIsLoading,
            setEmailTemplateData
        );
    };

    const getSingleEmailTemplate = async (id) => {
        await getSingleData(
            `EmailTemplate/SingleEmailTemplate?EmailTemplateID=${id}`,
            setEditEmailTemplateData,
            setIsLoading
        );
    };

    // Delete document type
    const deleteEmailTemplate = async (id) => {
        // You'll need to replace "EmailTemplate/DeleteEmailTemplate" with the appropriate API endpoint
        await deleteData(
            `EmailTemplate/DeleteEmailTemplate?EmailTemplateID=${id}`,
            getEmailTemplateData,
            setIsLoading
        );
    };

    const columnDefs = [
        createColumnDef("Template Name", "emailTemplateName", 200),
        createColumnDef("Subject Name", "emailSubject", 500),
       
        {
            headerName: "EmailBody",
            field: "emailBody",
            wrapText: true,
            sortable: true,
            filter: true,
            headerClass: "widthTableColum",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            autoHeight: true,
            cellStyle: { textAlign: "left" },
            minWidth: 800,
            flex: 1,
            cellRenderer: (params) => {
              return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
            },
          },
          createColumnDef("CC Email", "ccEmail", 150),
        ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
            ? [
                {
                    headerName: "Edit",
                    width: 70,
                    field: "emailTemplateID",
                    cellStyle: { textAlign: "center" },
                    headerClass: "centered-header",
                    cellRenderer: ({ value }) => (
                        <ion-icon
                            name="create-outline"
                            class="iconsFonts editIcon"
                            onClick={() => getSingleEmailTemplate(value)}
                        ></ion-icon>
                    ),
                    pinned: window.innerWidth > 768 ? "right" : "",
                },
            ]
            : []),
        ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
            ? [
                {
                    headerName: "Delete",
                    width: 80,
                    field: "emailTemplateID",
                    cellStyle: { textAlign: "center" },
                    headerClass: "centered-header",
                    cellRenderer: ({ value }) => (
                        <ion-icon
                            name="trash-outline"
                            class="iconsFonts deleteIcon"
                            onClick={() => deleteEmailTemplate(value)}
                        ></ion-icon>
                    ),
                    pinned: window.innerWidth > 768 ? "right" : "",
                },
            ]
            : []),
    ];

    return (
        <>
            {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
                <Card>
                    <Card.Header>
                        <span>New SMS Template</span>
                    </Card.Header>
                    <Card.Body>
                        <EmailTemplateComponent
                            returnID={getEmailTemplateData}
                            editedData={editEmailTemplateData}
                        />
                    </Card.Body>
                </Card>
            )}
            <Card>
                <Card.Header>
                    <span>SMS Template List</span>
                </Card.Header>
                <Card.Body>
                    <AgGridComponent
                        rowData={EmailTemplateData}
                        columnDefs={columnDefs}
                        height={700} paginationPageSize={25}
                    />
                </Card.Body>
            </Card>
        </>
    );
}
