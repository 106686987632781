import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import CourierNameMasterComponent from "./CourierNameMasterComponent"; 
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function CourierNameMaster() {
  const [courierNameData, setcourierNameData] = useState([]);
  const [editcourierNameData, setEditcourierNameData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getcourierNameData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "CourierNameMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all document types
  const getcourierNameData = async () => {
    await getAllData(
      "CourierNameMaster/AllCourierName",
      setIsLoading,
      setcourierNameData
    );
  };

  const getSinglecourierName = async (id) => {
    await getSingleData(
      `CourierNameMaster/SingleCourierName?CourierNameMasterID=${id}`,
      setEditcourierNameData,
      setIsLoading
    );
  };

  // Delete document type
  const deletecourierName = async (id) => {
    // You'll need to replace "DocumentType/deletecourierName" with the appropriate API endpoint
    await deleteData(
      `CourierNameMaster/DeleteCourierName?CourierNameMasterID=${id}`,
      getcourierNameData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("CourierName", "courierName", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "courierNameMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSinglecourierName(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "courierNameMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deletecourierName(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New courierName</span>
          </Card.Header>
          <Card.Body>
            <CourierNameMasterComponent
              returnID={getcourierNameData}
              editedData={editcourierNameData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>CourierName List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={courierNameData}
            columnDefs={columnDefs}
            height={500} paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
