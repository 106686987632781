import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Col,
  Row,
  InputGroup,
} from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import TextArea from "../../Common/TextArea";
import { showMessage } from "../../Utils/showMessageUtils";

const emptySMSApi = {
  SMSApiID: 0,
  SMSCompanyName: "",
  SMSUrl: "",
  SMSBalanceUrl: "",
  RequestType: "",
  HeaderField: "",
  BodyText: "",
  SMSLoginUrl: "",
};

export default function SMSApiComponent({ returnID, editedData, clearData, onClose }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptySMSApi);
  const SMSApiFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    
    if (editedData) {
      
      setFormData({
        SMSApiID: editedData.smsApiID,
        SMSCompanyName: editedData.smsCompanyName,
        SMSUrl: editedData.smsUrl,
        SMSBalanceUrl: editedData.smsBalanceUrl,
        RequestType: editedData.requestType,
        HeaderField: editedData.headerField,
        BodyText: editedData.bodyText,
        SMSLoginUrl: editedData.smsLoginUrl,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertSMSApi = (event) => {
    
    setValidated(true);
    setIsLoading(true);
    const form = SMSApiFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        SMSApiID: formData?.SMSApiID || 0,
        SMSCompanyName: formData.SMSCompanyName,
        SMSUrl: formData.SMSUrl,
        SMSBalanceUrl: formData.SMSBalanceUrl,
        RequestType: formData.RequestType,
        HeaderField: formData.HeaderField,
        BodyText: formData.BodyText,
        SMSLoginUrl: formData.SMSLoginUrl,
      };

      const endpoint =
        formData.SMSApiID === 0
          ? "SMSApi/InsertSMSApi"
          : "SMSApi/EditSMSApi";

      api[formData.SMSApiID === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);           
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptySMSApi);
  };

  return (
    <>
      <GlobalFormTagWithSubmit validated={validated} refName={SMSApiFormRef} formName="SMSApiForm">
        <Row>
          <InputGroupWithIcon
            ColSize={4}
            Label="SMS API Name"
            icon="fas fa-file-alt"
            type="text"
            name="SMSCompanyName"
            placeholder="Enter SMS API Name"
            value={formData.SMSCompanyName}
            onChange={handleInputChange}
            required
          />
          <Col md={4} sm={4} lg={4}>
            <Form.Label>Request Type:</Form.Label>
            <Form.Group controlId="RequestType">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-send"></i>
                </InputGroup.Text>
                <Form.Select
                  name="RequestType"
                  value={formData.RequestType}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select Request Type
                  </option>
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <TextArea
            ColSize={6}
            rows={3}
            Label="Header Field"
            icon="fas fa-file-alt"
            name="HeaderField"
            placeholder="Enter Header Field"
            value={formData.HeaderField}
            onChange={handleInputChange}
          />
          <TextArea
            ColSize={6}
            rows={3}
            Label="Body Text"
            icon="fas fa-file-alt"
            name="BodyText"
            placeholder="Enter Body Text"
            value={formData.BodyText}
            onChange={handleInputChange}
          />
        </Row>
        <Row>
          <InputGroupWithIcon
            ColSize={4}
            Label="SMS Url"
            icon="fas fa-at"
            type="text"
            name="SMSUrl"
            placeholder="Enter SMS Url"
            value={formData.SMSUrl}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
            ColSize={4}
            Label="SMS BalanceUrl"
            icon="fas fa-at"
            type="text"
            name="SMSBalanceUrl"
            placeholder="Enter SMS Balance Url"
            value={formData.SMSBalanceUrl}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
            ColSize={4}
            Label="SMS Login Url"
            icon="fas fa-at"
            type="text"
            name="SMSLoginUrl"
            placeholder="Enter SMS Login Url"
            value={formData.SMSLoginUrl}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm onClick={insertSMSApi} HeadID={formData?.SMSApiID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
