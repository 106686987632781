import React from 'react';
import { Modal } from 'react-bootstrap';

export default function GlobalModal({ title, bodyComponent, isOpen, onClose }) {
  return (
    <Modal show={isOpen} onHide={onClose} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton closeVariant={"white"}>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bodyComponent}
      </Modal.Body>
    </Modal>
  );
}


// How to use
// const MyComponent = () => {
  // const [showEventModal, setShowEventModal] = useState(false);

// const HandlModelClose = () => {
//   setShowEventModal(false);
// };

//   return (
//   <GlobalModal
//   title="New Event"
//   bodyComponent={
//     <EventComponent
//       onClose={HandlModelClose}
//       returnID={getEvent}
//       editedEventData={editEventData}
//       clearData={showEventModal}
//     />
//   }
//   isOpen={showEventModal}
//   onClose={HandlModelClose}
// />
//   );
// };

// export default MyComponent;