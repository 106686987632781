import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import api from "../Utils/api";

export default function GlobalDDComponentWithOutPlus({
    apiEndpoint,
    formLabel ,
    valueOfDD,
    ColSize = { sm: 4, md: 4, lg: 4 },
    labelOfDD,
    onSelectOption,
    selectedOptionOfComponent,
    reset,
    ValidationError,
    placeholder
  }) {

    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [validation, setValidation] = useState(false);
  
    useEffect(() => {
      fetchData();
    }, []);
  
    useEffect(() => {
      if (ValidationError && selectedOptionOfComponent === 0) {
        setValidation(true);
      } else {
        setValidation(false);
      }
    }, [ValidationError, selectedOptionOfComponent]);
  
  
    useEffect(() => {
      if (reset) {
        setSelectedOption(null);
      }
    }, [reset]);
  
    const fetchData = () => {
      api
        .get(apiEndpoint)
        .then((result) => {
          const list = result.data.data;
          setOptions(list);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
  
    useEffect(() => {
      if (selectedOptionOfComponent) {
        const selected = options.find(
          (option) => option[valueOfDD] === selectedOptionOfComponent
        );
        setSelectedOption({
          value: selected?.[valueOfDD],
          label: selected?.[labelOfDD],
        });
      }
      if(selectedOptionOfComponent == 0){
        setSelectedOption(null);
      }
    }, [selectedOptionOfComponent, options, valueOfDD, labelOfDD]);
  
    const handleOptionChange = (selectedOption) => {
      
      setSelectedOption(selectedOption);
      onSelectOption(selectedOption ? selectedOption.value : null);
    };
  
  
    const selectDDOption = (ID) => {
      handleOptionChange({ value: ID });
      fetchData();
    };
  
  return (
    <Col
    md={ColSize.md} sm={ColSize.sm} lg={ColSize.lg}
  >
    <Form.Group controlId="MasterMenu_id">
     {formLabel && <Form.Label>{formLabel}:</Form.Label>}
      <div className ={`${ValidationError ? "mb-3" : "" }  d-flex justify-content-between`}>
        <div className="selectStartIcon input-group-text" id="basic-addon1">
        <i class="fa fa-bars" aria-hidden="true"></i>
        </div>
        <Select
          className={`w-100  ${validation ? "borderRed" : ""}`}
          options={options.map((option) => ({
            value: option[valueOfDD],
            label: option[labelOfDD],
          }))}
          value={selectedOption}
          onChange={handleOptionChange}
          placeholder={` ${placeholder ? placeholder : "Select Option"}`}
        />
      </div>
      {validation && (
        <div className="ValidationErrorMsg">Please select a {formLabel}.</div>
      )}
    </Form.Group>
  </Col>

  )
}
