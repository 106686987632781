import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Button,
  Row,
  Form,
  Col,
  Modal,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import PolicyComponent from "./PolicyComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";
import {
  convertDateFormat,
  convertDateFormatWithOutTime,
  filterParams,
} from "../../Utils/commonFunction";
import GlobalModal from "../../Common/GlobalModal";
import KYCComponent from "./KYCComponent";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import { PolicyDetailProvider } from "../AllPolicyDetailModel/PolicyDetailContext";
import AllPolicyDetailModel from "../AllPolicyDetailModel/AllPolicyDetailModel";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Common/Loader ";
import SendSMSComponent from "./SendSMSComponent";
import { InputGroupWithIconAndInLineLabel } from "../../Common/MyComponent";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { showMessage } from "../../Utils/showMessageUtils";

const emtGetPolicy = {
  filterType: "activeAndExpired",
  DataOfPolicy: "Active",
  startDate: "",
  endDate: "",
  dateFilter: "",
  dayString: "",
  MobileNo: "",
  PolicyOrMnO : "",
};

const emtFormDataForDateFilter = {
  start_date: new Date().toISOString().substr(0, 10),
  end_date: new Date().toISOString().substr(0, 10),
  selectedDateFilter: "Select Filter",
  mobileNo: "",
  PolicyOrMnO :"",
};

// const filterParams = {
//   comparator: function(filterLocalDateAtMidnight, cellValue) {
//     if (!cellValue) return -1;
//     const cellDate = new Date(cellValue);
//     if (isNaN(cellDate)) return -1;
//     const cellDateMidnight = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
//     const filterDate = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());
//     if (filterDate.getTime() === cellDateMidnight.getTime()) {
//       return 0;
//     }
//     if (cellDateMidnight < filterDate) {
//       return -1;
//     }
//     if (cellDateMidnight > filterDate) {
//       return 1;
//     }
//     return 0;
//   },
//   minValidYear: new Date().getFullYear() - 100, // Current year minus 100
//   maxValidYear: new Date().getFullYear() + 100,
//   inRangeFloatingFilterDateFormat: "Do MMM YYYY",
// };

export default function Policy() {
  const [policyData, setPolicyData] = useState([]);
  const [editPolicyData, setEditPolicyData] = useState(null);
  const { setIsLoading, isLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  const [showPolicyForm, setShowPolicyForm] = useState(false);
  const [showKYCModel, setShowKYCModel] = useState(false);
  const [showSendSMSModel, setShowSendSMSModel] = useState(false);
  const [policyIDForKYC, setPolicyIDForKYC] = useState(null);

  const [selectedValue, setSelectedValue] = useState("All");
  const [showFullSizePopup, setShowFullSizePopup] = useState(false);

  const [popupData, setPopupData] = useState(null);
  const [policyID, setPolicyID] = useState(null);

  const [PolicyIDForSendSMS, setPolicyIDForSendSMS] = useState(null);
  const [formData, setFormData] = useState(emtFormDataForDateFilter);

  const [GetPolicyDetails, setGetPolicyDetails] = useState(emtGetPolicy);

  const [sendSMSToExpiredPolicy, setSendSMSExpiredPolicy] = useState(false);
  const [scheduleDaySMS, setScheduleDaySMS] = useState("");

  const [agGridLoading, setAgGridLoading] = useState(false);

  const UserPermissions = useCallback(async () => {
    await CheckRoleTemplatePermissions(
      "Policy",
      setRoleTemplatePermissions,
      setIsLoading
    );
  }, []);

  // Fetch all policies
  const getPolicyData = useCallback(async () => {
    setAgGridLoading(true);
    if (
      emtGetPolicy.filterType === "activeAndExpired" &&
      emtGetPolicy.DataOfPolicy === "Active"
    ) {
      setSendSMSExpiredPolicy(false);
      setScheduleDaySMS("");
    }
    api
      .get(
        `Policy/GetPolicies?filterType=${emtGetPolicy.filterType}&DataOfPolicy=${emtGetPolicy.DataOfPolicy}&startDate=${emtGetPolicy.startDate}&endDate=${emtGetPolicy.endDate}&dateFilter=${emtGetPolicy.dateFilter}&dayString=${emtGetPolicy.dayString}&MobileNo=${emtGetPolicy.MobileNo}&PolicyOrMNo=${emtGetPolicy.PolicyOrMnO}`
      )
      .then((result) => {
        setPolicyData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setAgGridLoading(false);
      });
  }, []);

  useEffect(() => {
    UserPermissions();
    resetPolicyFilter();
    getPolicyData();
  }, [getPolicyData, UserPermissions]);

  const getSinglePolicy = async (id) => {
    const Result = await getSingleData(
      `Policy/SinglePolicy?PolicyID=${id}`,
      setEditPolicyData,
      setIsLoading
    );
    if (Result) {
      setShowPolicyForm(true);
    }
  };

  // Function to open the full-size popup
  const openFullSizePopup = (data) => {
    setPopupData(data);
    setPolicyID(data.policyID);
    setShowFullSizePopup(true);
  };

  // Function to close the full-size popup
  const closeFullSizePopup = () => {
    setPopupData(null);
    setPolicyID(null);
    setShowFullSizePopup(false);
  };

  // Delete policy
  const deletePolicy = async (id) => {
    const passwordResult = await Swal.fire({
      title: "Enter Password",
      input: "password",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        if (!password) {
          Swal.showValidationMessage(`Password is required`);
        }
        return password;
      },
    });

    if (passwordResult.isConfirmed) {
      setIsLoading(true);
      try {
        const response = await api.patch(
          `Policy/DeletePolicy?PolicyID=${id}&password=${passwordResult.value}`
        );
        if (response.status === 200) {
          if (response.data.statusCode === 200) {
            Swal.fire({
              icon: "success",
              title: "Your record has been deleted.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (response.data.statusCode === 400) {
            Swal.fire({
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
          getPolicyData();
        }
      } catch (error) {
        console.error(error);
        Swal.fire("Error", "An error occurred.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const exportToExcel = () => {
    setIsLoading(true);

    setTimeout(() => {
      const fileName = "Policy_data.xlsx";

      const formattedData = policyData.map((item) => {
        let policyExpiredText = "";

        if (
          item.daysUntilExpiration >= -30 &&
          item.daysUntilExpiration <= 0 &&
          !item.renewalFlag
        ) {
          policyExpiredText = `Policy Expire ${item.daysUntilExpiration} day`;
        } else if (item.renewalFlag) {
          policyExpiredText = "Policy renew";
        } else if (
          item.daysUntilExpiration > 0 &&
          item.daysUntilExpiration <= 30
        ) {
          policyExpiredText = `Expire in ${item.daysUntilExpiration} days`;
        }

        return {
          PolicyExpired: policyExpiredText,
          ClientMasterName: item.clientMasterName,
          PolicyNo: item.policyNo,
          InsuredName: item.policyHolderName,
          PolicyTypeName: item.policyTypeName,
          PolicyStartDate:
            item.policyStartDate &&
            convertDateFormatWithOutTime(item.policyStartDate),
          PolicyEndDate:
            item.policyEndDate &&
            convertDateFormatWithOutTime(item.policyEndDate),
          NetAmount: item.netAmount,
          PolicyClaimCount: item.countOfInsuranceMainCLaim,
          PreviousPolicyNo: item.previousPolicyNo,
          InsuranceCompanyName: item.insuranceCompanyName,
          TPA: item.tpaMasterName,
          MobileNo: item.mobile,
          EmailIdTo: item.emailIdTo,
          InternalRemarks: item.internalRemarks,
          ExternalRemarks: item.externalRemarks,
          RenewalFlag: item.renewalFlag,
          Courier: item.isCourier,
          CourierCount: item.courierCount,
          DownloadPolicyCount: item.countOfViewPolicy,
          CreatedDate: convertDateFormat(item.createdDate),
          UpdatedDate: convertDateFormat(item.updatedDate),
          PolicyImageURL: `View Image`, // Placeholder text for clickable link
          ImagePath: UserSetting.imgURL + item.imagePath, // Store the actual URL separately
          EndorsementCount: item.endorsementCount,
          AgentName: item.agentMasterName,
          DOB:
            item.dateOfBirth && convertDateFormatWithOutTime(item.dateOfBirth),
          PremiumAmt: item.premiumAmt,
          AMTServiceTax: item.serviceTaxAmount,
          ServiceTax: item.serviceTax,
        };
      });

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Policy Data");

      worksheet.columns = [
        { header: "ClientName", key: "ClientMasterName", width: 20 },
        { header: "PolicyNo", key: "PolicyNo", width: 20 },
        { header: "InsuredName", key: "InsuredName", width: 20 },
        { header: "PolicyType", key: "PolicyTypeName", width: 20 },
        { header: "PolicyStartDate", key: "PolicyStartDate", width: 20 },
        { header: "PolicyEndDate", key: "PolicyEndDate", width: 20 },
        { header: "PolicyExpired", key: "PolicyExpired", width: 20 },
        { header: "NetAmount", key: "NetAmount", width: 20 },
        { header: "ExternalRemarks", key: "ExternalRemarks", width: 20 },
        { header: "EndorsementCount", key: "EndorsementCount", width: 20 },
        { header: "PolicyClaimCount", key: "PolicyClaimCount", width: 20 },
        { header: "RenewalFlag", key: "RenewalFlag", width: 20 },
        { header: "Courier", key: "Courier", width: 20 },
        { header: "PreviousPolicyNo", key: "PreviousPolicyNo", width: 20 },
        { header: "Insurance Company", key: "InsuranceCompanyName", width: 20 },
        { header: "AgentName", key: "AgentName", width: 20 },
        { header: "TPA", key: "TPA", width: 20 },
        { header: "MobileNo", key: "MobileNo", width: 20 },
        { header: "EmailIdTo", key: "EmailIdTo", width: 20 },
        { header: "CourierCount", key: "CourierCount", width: 20 },
        {
          header: "DownloadPolicyCount",
          key: "DownloadPolicyCount",
          width: 20,
        },
        { header: "DOB", key: "DOB", width: 20 },
        { header: "PremiumAmt", key: "PremiumAmt", width: 20 },
        { header: "ServiceTaxAmount", key: "AMTServiceTax", width: 20 },
        { header: "ServiceTax", key: "ServiceTax", width: 20 },
        { header: "InternalRemarks", key: "InternalRemarks", width: 20 },
        { header: "CreatedDate", key: "CreatedDate", width: 20 },
        { header: "UpdatedDate", key: "UpdatedDate", width: 20 },
        { header: "PolicyImageURL", key: "PolicyImageURL", width: 40 },
      ];

      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Add hyperlink formula to each cell in PolicyImageURL column
      worksheet.getColumn("PolicyImageURL").eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          // Skip header row
          const imagePath = formattedData[rowNumber - 2].ImagePath;
          cell.value = { formula: `HYPERLINK("${imagePath}","View Image")` };
        }
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Adjust width slightly to accommodate content
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });

      setIsLoading(false);
    }, 1000);
  };

  const removeExpiredPolicyFromList = (id) => {
    const FinalList = policyData.filter((item) => item.policyID !== id);
    setPolicyData(FinalList);
  };

  const columnDefs = [
    createColumnDef("Client", "clientMasterName", 200),
    {
      headerName: "Policy Number",
      field: "policyNo",
      sortable: true,
      filter: true,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 300,
      headerClass: "centered-header widthTableColum",
      cellRenderer: (params) => {
        return (
          <div
            style={{
              cursor: "pointer",
              height: "100%",
              whiteSpace: "normal",
            }}
          >
            <a
              href={UserSetting.imgURL + params.data.imagePath}
              target="_blank"
              rel="noreferrer"
              style={{ display: "block", height: "100%" }}
            >
              <span
                style={{
                  display: "inline-block",
                  padding: "8px",
                  color: params.data.isPolicyExpired ? "red" : "green",
                  fontWeight: "800",
                }}
              >
                {params.data.policyNo}
              </span>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Insured Name",
      field: "policyHolderName",
      filter: true,
      minWidth: 300,
      cellRenderer: (params) => {
        const handleKeyDown = (e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            handleClick();
          }
        };

        const handleClick = () => {
          openFullSizePopup(params.data);
        };

        return (
          <div
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              whiteSpace: "normal",
            }}
          >
            <span style={{ padding: "8px" }}>{params.value}</span>
          </div>
        );
      },
    },

    createColumnDef("Policy Type", "policyTypeName", 250),

    {
      headerName: "Start Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 150,
      headerClass: "centered-header widthTableColum",
      field: "policyStartDate",
      cellRenderer: ({ value }) => convertDateFormatWithOutTime(value),
    },
    {
      headerName: "End Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 150,
      headerClass: "centered-header widthTableColum",
      field: "policyEndDate",
      cellRenderer: ({ value }) => convertDateFormatWithOutTime(value),
    },
    {
      headerName: "Policy Expired",
      sortable: true,
      filter: true,
      autoHeight: true,
      flex: 1,
      minWidth: 190,
      headerClass: "centered-header widthTableColumn",
      field: "daysUntilExpiration",
      cellRenderer: (params) => {
        const { value, data } = params;
        const { renewalFlag } = data; // Assuming renewalFlag is a boolean field in your data
        let cellValue = "";

        // Determine cell value based on conditions
        if (value >= -30 && value <= 0 && !renewalFlag) {
          cellValue = `Policy Expire ${value} day`;
        } else if (renewalFlag) {
          cellValue = "Policy renew";
        } else if (value > 0 && value <= 30) {
          cellValue = `Expire in ${value} days`;
        }

        // Define cell style
        const cellStyle = {
          color: renewalFlag == true ? "Green" : "red", // Set text color to red
          fontWeight: "bold", // Set font weight to bold
        };

        // Return cell value with defined style
        return <span style={cellStyle}>{cellValue}</span>;
      },
    },

    createColumnDef("NetAmount", "netAmount", 150),

    createColumnDef("External Remarks", "externalRemarks", 150),
    createColumnDef("Endorsement Count", "endorsementCount", 170),
    createColumnDef("PolicyClaim Count", "countOfInsuranceMainCLaim", 150),
    {
      headerName: "Renewal Flag",
      sortable: true,
      filter: true,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 150,
      headerClass: "centered-header widthTableColum",
      field: "renewalFlag",
      cellRenderer: ({ value }) => {
        return value ? <p>Yes</p> : <p>No</p>;
      },
    },

    {
      headerName: "Courier",
      sortable: true,
      filter: true,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 150,
      headerClass: "centered-header widthTableColum",
      field: "isCourier",
      cellRenderer: ({ value }) => {
        return value ? <p>Yes</p> : <p>No</p>;
      },
    },
    createColumnDef("Previous PolicyNo", "previousPolicyNo", 300),
    createColumnDef("Insurance Company", "insuranceCompanyName", 400),
    createColumnDef("Agent", "agentMasterName", 200),
    createColumnDef("TPA", "tpaMasterName", 350),
    createColumnDef("Mobile No", "mobile", 150),
    createColumnDef("EmailID", "emailIdTo", 250),

    createColumnDef("Courier Count", "courierCount", 170),

    createColumnDef("Download Policy Count", "countOfViewPolicy", 170),
    {
      headerName: "DOB",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 200,
      headerClass: "centered-header widthTableColum",
      field: "dateOfBirth",
      cellRenderer: ({ value }) => value && convertDateFormatWithOutTime(value),
    },
    createColumnDef("PremiumAmt", "premiumAmt", 150),
    createColumnDef("ServiceTax Amt", "serviceTaxAmount", 150),
    createColumnDef("ServiceTax", "serviceTax", 150),
    createColumnDef("Internal Remarks", "internalRemarks", 150),
    {
      headerName: "Created Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 200,
      headerClass: "centered-header widthTableColum",
      field: "createdDate",
      cellRenderer: ({ value }) => convertDateFormat(value),
    },
    {
      headerName: "Updated Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 200,
      headerClass: "centered-header widthTableColum",
      field: "updatedDate",
      cellRenderer: ({ value }) => (value ? convertDateFormat(value) : ""),
    },
    !sendSMSToExpiredPolicy
      ? {
          headerName: "Send",
          width: 80,
          field: "policyID",
          cellStyle: { textAlign: "center" },
          headerClass: "centered-header",
          cellRenderer: ({ value }) => (
            <button
              onClick={() => {
                setShowSendSMSModel(true);
                setPolicyIDForSendSMS(value);
              }}
              onKeyDown={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  e.preventDefault();
                  setShowSendSMSModel(true);
                  setPolicyIDForSendSMS(value);
                }
              }}
            >
              <ion-icon name="send-sharp" class="iconsFonts"></ion-icon>
            </button>
          ),
          pinned: window.innerWidth > 768 ? "right" : "",
        }
      : {
          headerName: "Remove",
          width: 90,
          field: "policyID",
          cellStyle: { textAlign: "center" },
          headerClass: "centered-header",
          cellRenderer: ({ value }) => (
            <ion-icon
              name="remove-circle-outline"
              class="iconsFonts"
              onClick={() => removeExpiredPolicyFromList(value)}
            ></ion-icon>
          ),
        },
    {
      headerName: "KYC",
      width: 80,
      field: "policyID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <button
          onClick={() => {
            setShowKYCModel(true);
            setPolicyIDForKYC(value);
          }}
        >
          <ion-icon name="add-circle-outline" class="iconsFonts"></ion-icon>
        </button>
      ),
      pinned: window.innerWidth > 768 ? "right" : "",
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "policyID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSinglePolicy(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "policyID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deletePolicy(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  const HandleModelClose = () => {
    // setShowPolicyForm(false);
    setEditPolicyData(null);
    setSelectedValue("All");
  };

  const HandleKYCModel = () => {
    setShowKYCModel(false);
  };

  const HandleSendSMSModel = () => {
    setShowSendSMSModel(false);
  };

  const handleChange = async (eventKey) => {
    const value = eventKey;
    emtGetPolicy.filterType = "activeAndExpired";
    emtGetPolicy.DataOfPolicy = value;
    emtGetPolicy.dayString = "";
    emtGetPolicy.MobileNo = "";
    emtGetPolicy.PolicyOrMnO = "";
    setScheduleDaySMS("");
    setSendSMSExpiredPolicy(false);
    getPolicyData();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const getPolicyDatewise = async () => {
    if (formData.mobileNo == "") {
      const { start_date, end_date, selectedDateFilter } = formData;
      // Validation for date range
      if (new Date(start_date) > new Date(end_date)) {
        alert("Start date cannot be after the end date.");
        return;
      }
      emtGetPolicy.DataOfPolicy = "All";
      emtGetPolicy.filterType = "datewise";
      emtGetPolicy.startDate = start_date;
      emtGetPolicy.endDate = end_date;
      emtGetPolicy.dateFilter = selectedDateFilter;
      emtGetPolicy.MobileNo = "";
      emtGetPolicy.PolicyOrMnO = "";
      setScheduleDaySMS("");
      setSendSMSExpiredPolicy(false);
      getPolicyData();
    } else {
      if(formData.PolicyOrMnO == ""){
        showMessage(400, "Select Filter MobileNo or Policy or External Remarks!");
        return;
      }

      if (formData.PolicyOrMnO == "MobileNo" && formData.mobileNo.length != 10) {
        showMessage(400, "MobileNo Not valid!");
        return;
      }
      emtGetPolicy.DataOfPolicy = "All";
      emtGetPolicy.filterType = "MobileNoFilter";
      emtGetPolicy.startDate = "";
      emtGetPolicy.endDate = "";
      emtGetPolicy.dateFilter = "";
      emtGetPolicy.MobileNo = formData.mobileNo;
      emtGetPolicy.PolicyOrMnO = formData.PolicyOrMnO;
      setScheduleDaySMS("");
      setSendSMSExpiredPolicy(false);
      getPolicyData();
    }
  };

  const GetScheduleExpiredPolicy = async () => {
    if (scheduleDaySMS != "") {
      emtGetPolicy.filterType = "scheduledexpired";
      emtGetPolicy.DataOfPolicy = "";
      emtGetPolicy.startDate = "";
      emtGetPolicy.endDate = "";
      emtGetPolicy.dateFilter = "";
      emtGetPolicy.MobileNo = "";
      emtGetPolicy.PolicyOrMnO = "";
      emtGetPolicy.dayString = scheduleDaySMS;
      setFormData(emtFormDataForDateFilter);
      setSendSMSExpiredPolicy(true);
      getPolicyData();
    } else {
      showMessage(400, "Enter A days!");
    }
  };

  const SendSMSToExpiredPolicyAsDay = async () => {
    const PasswordResult = await Swal.fire({
      title: `SMS , WhatsApp , Email to ${policyData.length} Policy?`,
      text: `You will send SMS, WhatsApp, and Email to ${policyData.length} Policy.`,
      icon: "warning",
      input: "password",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
      preConfirm: (password) => {
        if (!password) {
          Swal.showValidationMessage(`Password is required`);
        }
        return password;
      },
    });

    if (PasswordResult.isConfirmed) {
      if (scheduleDaySMS !== "" && scheduleDaySMS !== null) {
        setIsLoading(true);
        api
          .post(
            `Policy/SendSMSToExpiredPolicyAsPerDay?Password=${PasswordResult.value}`,
            policyData
          )
          .then((result) => {
            showMessage(result.data.statusCode, result.data.message);
          })
          .catch((error) => {
            showMessage(400, "An error occurred");
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        showMessage(400, "Enter Day In textBox");
      }
    }
  };

  const resetPolicyFilter = () => {
    emtGetPolicy.filterType = "activeandexpired";
    emtGetPolicy.DataOfPolicy = "Active";
    emtGetPolicy.startDate = "";
    emtGetPolicy.endDate = "";
    emtGetPolicy.dateFilter = "";
    emtGetPolicy.dayString = "";
    emtGetPolicy.MobileNo = "";
    emtGetPolicy.PolicyOrMnO = "";
    setFormData(emtFormDataForDateFilter);
    setScheduleDaySMS("");
    setSendSMSExpiredPolicy(false);
    getPolicyData();
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="m-0 p-0 PolicyFilterRow">
            <Col sm={12} md={2} lg={3} className="sm:justify-center">
              <span>
                {showPolicyForm
                  ? editPolicyData
                    ? "Edit Policy"
                    : "Add Policy"
                  : "Policy List"}
              </span>
            </Col>
            <Col sm={12} md={10} lg={5} className="sm:justify-center">
              {!showPolicyForm && (
                <div>
                  <InputGroup>
                    <InputGroup.Text>Enter Day</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="scheduleDaySMS"
                      placeholder="Enter Days"
                      className="RemoveBorderRadiusTextBox"
                      value={scheduleDaySMS}
                      onChange={(e) => setScheduleDaySMS(e.target.value)}
                    />
                    <InputGroup.Text style={{ padding: "0px" }}>
                      <Button
                        onClick={GetScheduleExpiredPolicy}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#20336b",
                          color: "white",
                          borderRadius: "0px",
                        }}
                        variant="primary"
                      >
                        Get
                      </Button>
                    </InputGroup.Text>
                    {sendSMSToExpiredPolicy &&
                      scheduleDaySMS !== "" &&
                      scheduleDaySMS !== null && (
                        <InputGroup.Text
                          onClick={SendSMSToExpiredPolicyAsDay}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#20336b",
                            color: "white",
                          }}
                        >
                          Send SMS
                        </InputGroup.Text>
                      )}
                  </InputGroup>
                </div>
              )}
            </Col>
            <Col
              sm={12}
              md={12}
              lg={4}
              className="flex lg:justify-end justify-between"
            >
              {!showPolicyForm && (
                <Button
                  type="button"
                  variant="success"
                  className="mb-20px mr-4 policyTopButton"
                  onClick={exportToExcel}
                >
                  <i className="fa fa-file-excel"></i> Export to Excel
                </Button>
              )}
              <Button
                className="btn-tapti policyTopButton"
                onClick={() => {
                  if (showPolicyForm) {
                    setShowPolicyForm(false);
                    HandleModelClose();
                  } else {
                    setShowPolicyForm(true);
                  }
                }}
              >
                {showPolicyForm ? "show Data" : "Add Policy"}
              </Button>
            </Col>
          </Row>
        </Card.Header>
        {RoleTemplatePermissions &&
          RoleTemplatePermissions.addPermission &&
          showPolicyForm && (
            <Card.Body>
              <PolicyComponent
                onClose={HandleModelClose}
                returnID={() => {
                  getPolicyData();
                }}
                editedData={editPolicyData}
                clearData={showPolicyForm}
              />
            </Card.Body>
          )}

        {!showPolicyForm && (
          <Card.Body>
            <Row className="align-items-center mb-3 PolicyFilterRow">
              <Col md={6} lg={2} sm={12} className="my-0.5">
                <div className="w-full SendSMSButton">
                  <select
                    id="policySelect"
                    value={emtGetPolicy.DataOfPolicy}
                    onChange={(e) => handleChange(e.target.value)}
                    className="form-select"
                  >
                    <option value="All">All Policy</option>
                    <option value="Active">Active Policy</option>
                    <option value="Expired">Expired Policy</option>
                    <option value="30DayExpired">30Day Expired</option>
                  </select>
                </div>
              </Col>
              <Col md={6} lg={2} sm={12} className="d-flex justify-end my-0.5">
                {/* <DropdownButton
                  id="dropdown-variants-Warning"
                  title={
                    formData.selectedDateFilter                    
                  }
                  variant="info"
                  className="w-full SendSMSButton"
                  onSelect={(eventKey) => {
                    setFormData({
                      ...formData,
                      selectedDateFilter: eventKey,
                    });
                  }}
                >
                  <Dropdown.Item eventKey="SelectFilter">
                    Select Filter
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="CreatedDate">
                    Created Policy
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="ExpiredDate">
                    Expired Policy
                  </Dropdown.Item>

                </DropdownButton> */}
                <div className="w-full SendSMSButton">
                  <select
                    id="dateFilterSelect"
                    value={formData.selectedDateFilter}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        selectedDateFilter: e.target.value,
                        mobileNo :"",
                        PolicyOrMnO :"",
                        
                      });
                    }}
                    className="form-select"
                  >
                    <option value="SelectFilter">Select Filter</option>
                    <option value="CreatedDate">Created Policy</option>
                    <option value="ExpiredDate">Expired Policy</option>
                  </select>
                </div>
              </Col>
              <Col md={6} lg={4} sm={12} className="my-0.5">
                <InputGroup className="PolicySelectDateInputGroup">
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    className="RemoveBorderRadiusTextBox"
                    required
                  />
                  {/* </InputGroup>
              {/* </Col>
              <Col md={6} lg={2} sm={12} className="my-0.5"> */}
                  {/* <InputGroup className="PolicySelectDateInputGroup"> */}
                  <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={6} lg={3} sm={12} className="my-0.5">
                <InputGroup className="PolicySelectDateInputGroup">
                  <InputGroup.Text id="basic-addon1" className="p-0">
                    <select
                      id="dateFilterSelect"
                      value={formData.PolicyOrMnO}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          PolicyOrMnO: e.target.value,
                        });
                      }}
                      className="form-select border-0"
                    >
                       <option value="SelectFilter">Select</option>
                      <option value="MobileNo">M No.</option>
                      <option value="Policy">Policy</option>
                      <option value="ExternalRemarks">External</option>
                    </select>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="mobileNo"
                    value={formData.mobileNo}
                    placeholder="Enter value"
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </Col>
              <Col
                md={12}
                lg={1}
                sm={12}
                className="my-0.5 d-flex justify-content-between"
              >
                <ButtonGroup>
                  <Button variant="outline-success" onClick={getPolicyDatewise}>
                    <i className="fa fa-search"></i>
                  </Button>
                  <Button variant="outline-danger" onClick={resetPolicyFilter}>
                    <i className="fa fa-refresh"></i>
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <AgGridComponent
              rowData={policyData}
              columnDefs={columnDefs}
              height={"75vh"}
              paginationPageSize={100}
              loading={agGridLoading}
            />
          </Card.Body>
        )}
      </Card>

      <GlobalModal
        title="Add KYC"
        bodyComponent={<KYCComponent policyID={policyIDForKYC} />}
        isOpen={showKYCModel}
        onClose={HandleKYCModel}
      />

      <GlobalModal
        title="Send SMS"
        bodyComponent={
          <SendSMSComponent
            policyID={PolicyIDForSendSMS}
            onCloseSendSMS={() => {
              setShowSendSMSModel(false);
            }}
          />
        }
        isOpen={showSendSMSModel}
        onClose={HandleSendSMSModel}
      />

      <Modal
        show={showFullSizePopup}
        onHide={closeFullSizePopup}
        dialogClassName="modal-dialog-scrollable"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>AllDetail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader />}
          <PolicyDetailProvider>
            <AllPolicyDetailModel selectedPolicyID={policyID} />
          </PolicyDetailProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
