import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import CourierDetailModel from "../AllPolicyDetailModel/CourierDetailModel";
import EndorsementDetailModel from "../AllPolicyDetailModel/EndorsementDetailModel";
import PolicyDetailModel from "../AllPolicyDetailModel/PolicyDetailModel";
import ClaimDetailModel from "../AllPolicyDetailModel/ClaimDetailModel";
import { PolicyDetailProvider, usePolicyDetailContext } from "./PolicyDetailContext";

export default function AllPolicyDetailModel({ selectedPolicyID }) {
  const { activeKey, setActiveKey ,PolicyIDForDetails } = usePolicyDetailContext();

  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
        id="uncontrolled-tab-example"
        variant="underline"
      >
        
        <Tab eventKey="policy" title="Policy">
          <PolicyDetailModel selectedPolicyID={selectedPolicyID} />
        </Tab>
        <Tab eventKey="claim" title="Claim">
          <ClaimDetailModel selectedPolicyID={selectedPolicyID} PolicyID={PolicyIDForDetails}/>
        </Tab>
        <Tab eventKey="endorsement" title="Endorsement">
          <EndorsementDetailModel selectedPolicyID={selectedPolicyID}  PolicyID={PolicyIDForDetails}/>
        </Tab>
        <Tab eventKey="courier" title="Courier">
          <CourierDetailModel selectedPolicyID={selectedPolicyID} PolicyID={PolicyIDForDetails}/>
        </Tab>
       
      </Tabs>
    </div>
  );
}