import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import CourierNameMasterComponent from "../CourierNameMaster/CourierNameMasterComponent";
import CourierStatusMasterComponent from "../CourierStatusMaster/CourierStatusMasterComponent";
import TextArea from "../../Common/TextArea";
import CustomCheckbox from "../../Common/CustomCheckbox";
import { showMessage } from "../../Utils/showMessageUtils";
// import {
//   getCurrentDateTime
// } from "../../Utils/commonFunction";

// Define a constant for an empty document structure
const emptyDocumentType = {
  courierID: 0,
  policyID: 0,
  policyNo: "",
  clientName: "",
  holderName: "",
  courierNameMasterID: 0,
  trackingNumber: "",
  courierStatusMasterID: 0,
  remarks: "",
  SendWhatsAppSMS: true,
  SendTextSMS: true,
  SendEmail: true,
};

// Define the CourierComponent function
export default function CourierComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  // State variables
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyDocumentType);
  const courierNameFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();
  const [courierNameDDValidationError, setCourierNameDDValidationError] =
    useState(false);

  // Effect hook to handle edited data
  useEffect(() => {
    if (editedData) {
      setFormData((prevData) => ({
        ...prevData,
        policyID: editedData.policyID,
        courierID: editedData.courierID,
        policyNo: editedData.policyNo,
        clientName: editedData.clientName,
        holderName: editedData.policyHolderName,
        courierNameMasterID: editedData.courierNameMasterID,
        trackingNumber: editedData.trackingNumber || "",
        courierStatusMasterID: editedData.courierStatusMasterID,
        remarks: editedData.remarks,
      }));

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  // Effect hook to handle form reset
  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert or update courier

  const InsertCourier = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = courierNameFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        CourierID: formData?.courierID || 0,
        PolicyID: formData.policyID,
        CourierNameMasterID: formData.courierNameMasterID,
        TrackingNumber: formData.trackingNumber,
        CourierStatusMasterID: formData.courierStatusMasterID,
        Remarks: formData.remarks,
        SendWhatsAppSMS: formData.SendWhatsAppSMS,
        SendTextSMS: formData.SendTextSMS,
        SendEmail: formData.SendEmail,
      };

      const endpoint =
        formData.courierID === 0
          ? "Courier/InsertCourier"
          : "Courier/EditCourier";

      api[formData.courierID === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred");
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  // Reset form data
  const resetForm = () => {
    setFormData(emptyDocumentType);
  };

  // Handle key press events
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchCourierData(event.target.value);
    }
  };

  // Fetch courier data based on policy number
  const fetchCourierData = () => {
    const policyNo = formData.policyNo;
    if (!policyNo) {
     showMessage(400,"Please enter a valid policy number.");
      return;
    }

    setIsLoading(true);

    api
      .get(`Courier/FindCourierDataByPolicyNo?PolicyNo=${policyNo}`)
      .then((result) => {
        const data = result.data;
        if (!data.flag) {
          showMessage(400,data.message);
          setIsLoading(false);
          return;
        }

        const courierDetails = data.data;
        setFormData((prevData) => ({
          ...prevData,
          policyID: courierDetails.policyID,
          courierID: 0,
          policyNo: data.data.policyNo,
          clientName: courierDetails.clientName,
          holderName: courierDetails.policyHolderName,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        showMessage(400,"An error occurred while fetching courier details.");
        setIsLoading(false);
      });
  };

  // Render the component
  return (
    <GlobalFormTagWithSubmit
      validated={validated}
      refName={courierNameFormRef}
      formName="CourierNameForm"
    >
      <Row>
        {/* <InputGroupWithIcon
          ColSize={{ sm: 12, md: 6, lg: 4 }}
          Label="Policy No"
          icon="fas fa-file-alt"
          type="text"
          name="policyNo"
          placeholder="Enter Policy No"
          value={formData.policyNo}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          required
        /> */}
        <Col md={6} sm={12} lg={4}>
          <Form.Group controlId="PolicyNo">
            <Form.Label>PolicyNo:</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fas fa-file-alt"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="policyNo"
                placeholder="Enter Policy No"
                value={formData.policyNo}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className="RemoveBorderRadiusTextBox"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter policyNo
              </Form.Control.Feedback>
              <InputGroup.Text id="basic-addon1" style={{ padding: "1px" }}>
                <Button
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#20336b",
                    color: "white",
                    borderRadius: "0px",
                  }}
                  variant="primary"
                  onClick={fetchCourierData}
                >
                  <i className="fas fa-search"></i>
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <InputGroupWithIcon
          ColSize={{ sm: 12, md: 6, lg: 4 }}
          Label="Client Name"
          icon="fas fa-user"
          type="text"
          name="clientName"
          placeholder="Enter Client Name"
          value={formData.clientName}
          onChange={handleInputChange}
          disabled
        />
        <InputGroupWithIcon
          ColSize={{ sm: 12, md: 6, lg: 4 }}
          Label="Holder Name"
          icon="fas fa-user"
          type="text"
          name="holderName"
          placeholder="Enter Holder Name"
          value={formData.holderName}
          onChange={handleInputChange}
          disabled
        />
        <GlobalDDComponent
          ColSize={{ sm: 12, md: 6, lg: 4 }}
          formLabel="Courier Name"
          apiEndpoint="CourierNameMaster/AllCourierName"
          valueOfDD="courierNameMasterID"
          labelOfDD="courierName"
          onSelectOption={(courierNameMasterID) => {
            setFormData((prevData) => ({ ...prevData, courierNameMasterID }));
            setCourierNameDDValidationError(false);
          }}
          selectedOptionOfComponent={formData.courierNameMasterID}
          ValidationError={courierNameDDValidationError}
          offcanvasTitle="Add Courier Name"
          offcanvasBodyComponent={<CourierNameMasterComponent />}
        />
        <InputGroupWithIcon
          ColSize={{ sm: 12, md: 6, lg: 4 }}
          Label="Tracking Number"
          icon="fas fa-truck"
          type="text"
          name="trackingNumber"
          placeholder="Enter Tracking Number"
          value={formData.trackingNumber}
          onChange={handleInputChange}
          required
        />
        {editedData && (
          <GlobalDDComponent
            ColSize={{ sm: 12, md: 6, lg: 4 }}
            formLabel="Courier Status"
            apiEndpoint="CourierStatusMaster/AllCouruerStatus"
            valueOfDD="courierStatusMasterID"
            labelOfDD="courierStatus"
            onSelectOption={(courierStatusMasterID) => {
              setFormData((prevData) => ({
                ...prevData,
                courierStatusMasterID,
              }));
              setCourierNameDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.courierStatusMasterID}
            ValidationError={courierNameDDValidationError}
            offcanvasTitle="Add Courier Status"
            offcanvasBodyComponent={<CourierStatusMasterComponent />}
          />
        )}

        <TextArea
          ColSize={{ sm: 12, md: 6, lg: 4 }}
          rows={3}
          Label="Remarks"
          icon="fas fa-comment"
          name="remarks"
          placeholder="Enter Remarks"
          value={formData.remarks}
          onChange={handleInputChange}
        />
        <Col md={3} sm={3} lg={3} className="flex items-center">
          {!editedData && (
            <div className="d-flex">
              <CustomCheckbox
                icon="fab fa-whatsapp"
                iconColor="Green"
                name="SendWhatsAppSMS"
                checked={formData.SendWhatsAppSMS}
                onChange={handleInputChange}
              />
              <CustomCheckbox
                icon="fas fa-sms"
                iconColor="#4290f5"
                name="SendTextSMS"
                checked={formData.SendTextSMS}
                onChange={handleInputChange}
              />
              <CustomCheckbox
                icon="fas fa-envelope"
                iconColor="#ed4a3d"
                name="SendEmail"
                checked={formData.SendEmail}
                onChange={handleInputChange}
              />
            </div>
          )}
        </Col>
      </Row>
      <OnClickSubmitButtonOfForm
        onClick={InsertCourier}
        HeadID={formData?.courierID}
      />
    </GlobalFormTagWithSubmit>
  );
}
