import React, { useState, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Table,
  Modal,
} from "react-bootstrap";
import api, { handleApiError } from "../../Utils/api";
import TypeaheadComponent from "../../Common/TypeaheadComponent";
import SMSApiComponent from "../SMSApi/SMSApiComponent";
import SMSTemplateComponent from "../SMSTemplate/SMSTemplateComponent";
import { getCurrentDate } from "../../Utils/commonFunction";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import TextArea from "../../Common/TextArea";
import { Typeahead } from "react-bootstrap-typeahead";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { getAllDataReturn, getSingleDataReturn } from "../../Utils/CallApiOnlyReturn";
import { useLoadingContext } from "../../Loading/LoadingContext";
import GlobalModal from "../../Common/GlobalModal";
import { InputGroupWithIconAndInLineLabel } from "../../Common/MyComponent";
import { showMessage } from "../../Utils/showMessageUtils";


const emtSelectFormData ={
  start_date: new Date().toISOString().substr(0, 10),
  end_date: new Date().toISOString().substr(0, 10),
  selectedDateFilter: "",
}

const DateTimeFilterData = {
  start_date: getCurrentDate(),
  end_date: getCurrentDate(),
  ClientID: 0,
};

export default function SMSSend() {
  const [tableData, setTableData] = useState([]);
  const [selectedSMSApiID, setSelectedSMSApiID] = useState(0);
  const [selectedSMSTemplateID, setSelectedSMSTemplateID] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [refreshKeyOfSMSAPi, setRefreshKeyOfSMSAPi] = useState(0);
  const [refreshKeyOfSMSTemplate, setRefreshKeyOfSMSTemplate] = useState(0);
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [msgOfSMS, setMsgOfSMS] = useState("");
  const [isSelectClientModalOpen, setIsSelectClientModalOpen] = useState(false);
  const [formData, setFormData] = useState(DateTimeFilterData);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageForDisplay, setSelectedImageForDisplay] = useState("");
  const [smsApiDDValidationError, setSMSApiDDValidationError] = useState(false);
  const [smsTemplateIDDDValidationError, setSMSTemplateIDDDValidationError] =
    useState(false);
  const imageInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [options, setOptions] = useState([]);
  const policyTypeHadeRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState("All");
  const { setIsLoading, isLoading } = useLoadingContext();
  const [formDataOfSelectPolicy, setFormDataSelectPolicy] = useState(emtSelectFormData);
  const [showSelectPolicyModel,setShowSelectPolicyModel]= useState(false);
 const [scheduleDaySMS,setScheduleDaySMS] = useState("");



  const handleInputChangeSelectPolicy = (event) => {
    const { name, value } = event.target;
    setFormDataSelectPolicy({ ...formDataOfSelectPolicy, [name]: value });
  };


  const handleClientSelection = (selectedClient) => {
    api
      .get(`ClientMaster/SingleClient?ClientMasterID=${selectedClient}`)
      .then(({ data }) => {
        setTableData((prevData) => [...prevData, data.data]);
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);
  };

  const handleSelectOption = (smsApiID) => {
    setSelectedSMSApiID(smsApiID);
    setSMSApiDDValidationError(false);
  };

  const handleSMSTemplateSelectOption = (smsTemplateID) => {
    setSelectedSMSTemplateID(smsTemplateID);
    getSMSTemplate(smsTemplateID);
    setSMSTemplateIDDDValidationError(false);
  };

  const getSMSTemplate = (id) => {
    api
      .get(`SMSTemplate/SingleSMSTemplate?SMSTemplateID=${id}`)
      .then(({ data }) => setMsgOfSMS(data.data.smsText))
      .catch(handleApiError);
  };

  const ClientOrLeadData = (type) => {
    api
      .get(`ClientMaster/AllClients`)
      .then(({ data }) =>
        setTableData((prevData) => [...prevData, ...data.data])
      )
      .catch(handleApiError);
  };

  const removeRow = (rowNumber) => {
    const updatedList = tableData.filter((_, index) => index !== rowNumber);
    setTableData(updatedList);
  };

  const handleInputChange = (event) => {
    setErrorMessage("");
    const { name, value } = event.target;
    setNewMobileNumber(value);
  };

  const handleInputChangeModel = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const addMobileNumber = () => {
    if (!newMobileNumber.trim()) {
      setErrorMessage("Type Mobile No");
      return;
    }
    setTableData((prevData) => [
      ...prevData,
      { clientMobileNo: newMobileNumber },
    ]);
    setNewMobileNumber("");
  };

  const getClient = () => {
    api
      .get(
        `ClientMaster/AllClientDateWise?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&ClientMasterID=${formData.ClientID}`
      )
      .then(({ data }) => {
        setTableData((prevData) => [...prevData, ...data.data]);
        setIsSelectClientModalOpen(false);
      })
      .catch(handleApiError);
  };

  const SendSMS = () => {
    const mobileNumbersArray = tableData.map((item) => item.clientMobileNo);
    
    const formattedNumbersString = mobileNumbersArray.join(",");

    if (formattedNumbersString === null || formattedNumbersString === "") {
      showMessage(400,"select MobileNumber");
      return;
    }
    if (selectedSMSApiID === 0) {
      showMessage(400,"select SMSApi");
      return;
    }
    if (msgOfSMS === null || msgOfSMS === "") {
      showMessage(400,"Type Msg");
      return;
    }

    const formData = new FormData();
    formData.append("MobileNoList", formattedNumbersString);
    formData.append("Msg", msgOfSMS);
    formData.append("SMSApiID", selectedSMSApiID);

    // Check if an image file is selected
    if (selectedImage) {
      formData.append("Files", selectedImage);
    }
    api
      .post("SMSSend/InsertSendSMS", formData)
      .then(({ data }) => {
        showMessage(200,data.message);
        setSelectedSMSApiID(0);
        setSelectedSMSTemplateID(0);
        setTableData([]);
        setRefreshKey((prevKey) => prevKey + 1);
        setRefreshKeyOfSMSAPi((prevKey) => prevKey + 1);
        setRefreshKeyOfSMSTemplate((prevKey) => prevKey + 1);
        setNewMobileNumber("");
        setMsgOfSMS("");
        setSelectedImage("");
        setSelectedImageForDisplay("");
        if (imageInputRef.current) {
          imageInputRef.current.value = null;
        }
      })
      .catch(handleApiError);
  };

  const handleImageChange = (event) => {
    const { name, files } = event.target;
    const selectedFile = files[0];
    setSelectedImage(selectedFile);

    const fileImage = event.target.files[0];

    if (fileImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImageForDisplay(reader.result);
      };
      reader.readAsDataURL(fileImage);
    }
  };

  const handleEditorChange = (data) => {
    setMsgOfSMS(data);
  };

  const handleSelection = (selected) => {
    const data = {
      policyNo: selected[0].policyNo,
      clientMobileNo: selected[0].mobile,
      clientName: selected[0].clientMasterName,
    };
    setTableData((prevData) => [...prevData, data]);
    policyTypeHadeRef.current.clear();
    setOptions([]);
  };

  const fetchPolicies = async (query) => {
    try {
      const response = await api.get(
        `Policy/SearchPolicyByPolicyNoOrPolicyHolderName?search=${query}`
      );
      setOptions(response.data.data);
    } catch (error) {
      console.error("Error fetching policies:", error);
    }
  };

  const handleSearch = (query) => {
    if (query.length > 2) {
      fetchPolicies(query);
    }
  };

  const handleChange = async (eventKey) => {
    setSelectedValue(eventKey);
    let result;
    if (eventKey === "All") {
      result = await getAllDataReturn("Policy/AllPolicy", setIsLoading);
    } else if (eventKey === "Active") {
      result = await getAllDataReturn("Policy/ActivePolicy", setIsLoading);
    } else if (eventKey === "Expired") {
      result = await getAllDataReturn("Policy/ExpiredPolicy", setIsLoading);
    }

    if(result != null){
      const List = result.map(item =>({
        policyNo: item.policyNo,
        clientMobileNo: item.mobile,
        clientName: item.clientMasterName,
      }))
      setTableData((prevData) => [...prevData, ...List]);
    }
  };

  const getPolicyDatewise = async () => {
    
    const { start_date, end_date, selectedDateFilter } = formDataOfSelectPolicy;

    if (new Date(start_date) > new Date(end_date)) {
      alert("Start date cannot be after the end date.");
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `Policy/AllPolicyDateWise?START_DATE=${start_date}&END_DATE=${end_date}&FILTER=${selectedDateFilter}`
      );

      if(data != null){
        const List = data.data.map(item =>({
          policyNo: item.policyNo,
          clientMobileNo: item.mobile,
          clientName: item.clientMasterName,
        }))
        setTableData((prevData) => [...prevData, ...List]);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const HandleSelectPolicyModel =()=>{
    setShowSelectPolicyModel(false);
    setFormDataSelectPolicy(emtSelectFormData);
  }

 const GetScheduleExpiredPolicy =async ()=>{
   const result = await getSingleDataReturn(`Policy/GetScheduleExpiredPolicy?DayString=${scheduleDaySMS}`,setIsLoading);
   if(result != null){
    const List = result.map(item =>({
      policyNo: item.policyNo,
      clientMobileNo: item.mobile,
      clientName: item.clientMasterName,
    }))
    setTableData((prevData) => [...prevData, ...List]);
   }
 }
  return (
    <>
      <Card>
        <Card.Header>
          <span>Send SMS</span>
        </Card.Header>
        <Card.Body>
          <Row className="p-0 m-0">
            <Col md={12} lg={7} sm={12} className="px-3">
              <Row className="my-2 borderOFSendSMS d-flex">
                <Col sm={6} lg={3} md={4} className=" text-center my-0.5 ">
                  <Button
                    className="w-full SendSMSButton"
                    variant="warning"
                    onClick={() => ClientOrLeadData("Client")}
                  >
                    All Client
                  </Button>
                </Col>
                <Col sm={6} lg={3} md={4} className="text-center my-0.5">
                  <Button
                    className="w-full SendSMSButton"
                    variant="warning"
                    onClick={() => setIsSelectClientModalOpen(true)}
                  >
                    Select Client
                  </Button>
                </Col>
                <Col sm={6} lg={3} md={4} className=" text-center my-0.5">
                  <DropdownButton
                    id="dropdown-variants-Warning"
                    title={`Get Policy`}
                    variant="warning"
                    className="w-full SendSMSButton"
                    onSelect={handleChange}
                  >
                    <Dropdown.Item eventKey="All">All</Dropdown.Item>
                    <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                    <Dropdown.Item eventKey="Expired">Expired</Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col sm={6} lg={3} md={4} className=" text-center my-0.5">
                  <Button
                    className="w-full SendSMSButton"
                    variant="warning"
                    onClick={() => setShowSelectPolicyModel(true)}
                  >
                    Select Policy
                  </Button>
                </Col>
              </Row>
              <Row className="my-2 borderOFSendSMS d-flex">
                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label
                    column
                    sm={3}
                    lg={2}
                    md={3}
                    className="lg:text-end"
                  >
                    Search Client
                  </Form.Label>
                  <Col sm={9} lg={9} md={9}>
                    <TypeaheadComponent
                      onClientSelect={handleClientSelection}
                      onClear={refreshKey}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Form.Group>
              </Row>
              <Row className="my-2 borderOFSendSMS d-flex">
                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label
                    column
                    sm={3}
                    lg={2}
                    md={3}
                    className="lg:text-end"
                  >
                    Search Policy
                  </Form.Label>
                  <Col sm={9} lg={9} md={9}>
                    <Typeahead
                      id="policy-search"
                      ref={policyTypeHadeRef}
                      onInputChange={handleSearch}
                      onChange={handleSelection}
                      options={options}
                      labelKey={(option) =>
                        `${option.policyNo} - ${option.policyHolderName}`
                      }
                      placeholder="Search Policy"
                      className="typeHade"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Form.Group>
              </Row>

              <Row className="my-2 borderOFSendSMS d-flex">
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="newMobileNumber"
                    placeholder=" MobileNumber"
                    value={newMobileNumber}
                    onChange={handleInputChange}
                    required
                    style={{
                      borderTopRightRadius: "0px!important",
                      borderBottomRightRadius: "0px!important",
                    }}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    onClick={addMobileNumber}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#20336b",
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    Add
                  </InputGroup.Text>
                </InputGroup>
                {errorMessage && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errorMessage}
                  </p>
                )}
              </Row>
              {/* <Row className="my-2 borderOFSendSMS d-flex">
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="scheduleDaySMS"
                    placeholder="Enter Days"
                    value={scheduleDaySMS}
                    onChange={(e)=>setScheduleDaySMS(e.target.value)}
                    required
                    style={{
                      borderTopRightRadius: "0px!important",
                      borderBottomRightRadius: "0px!important",
                    }}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    onClick={GetScheduleExpiredPolicy}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#20336b",
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    Get 
                  </InputGroup.Text>
                </InputGroup>
                {errorMessage && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errorMessage}
                  </p>
                )}
              </Row> */}
              <Row className="my-2 borderOFSendSMS d-flex">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" onClick={addMobileNumber}>
                    Total Count
                  </InputGroup.Text>
                  <Form.Control value={tableData.length} className="w-full" />
                  <InputGroup.Text
                    id="basic-addon1"
                    onClick={() => setTableData([])}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#20336b",
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    <i className="fa fa-refresh"></i>
                  </InputGroup.Text>
                </InputGroup>
              </Row>
            </Col>
            <Col md={12} lg={5} sm={12} className="borderOFSendSMS">
              <GlobalDDComponent
                ColSize={12}
                formLabel="SMS API Name"
                apiEndpoint="SMSApi/AllSMSApi"
                valueOfDD="smsApiID"
                labelOfDD="smsCompanyName"
                onSelectOption={handleSelectOption}
                selectedOptionOfComponent={selectedSMSApiID}
                ValidationError={smsApiDDValidationError}
                offcanvasTitle="Add Sms API Name"
                offcanvasBodyComponent={<SMSApiComponent />}
              />
              {/* <GlobalDDComponent
                ColSize={12}
                formLabel="SMS Template Name"
                apiEndpoint="SMSTemplate/AllSMSTemplate"
                valueOfDD="smsTemplateID"
                labelOfDD="smsTemplateName"
                onSelectOption={handleSMSTemplateSelectOption}
                selectedOptionOfComponent={selectedSMSTemplateID}
                ValidationError={smsTemplateIDDDValidationError}
                offcanvasTitle="Add SMS Template"
                offcanvasBodyComponent={<SMSTemplateComponent />}
              /> */}
              <Col md={12}>
                <TextArea
                  ColSize={{ sm: 12, md: 12, lg: 12 }}
                  rows={4}
                  Label="SMS Text"
                  icon="fas fa-pencil-alt"
                  name="SMSText"
                  placeholder="SMS Text"
                  value={msgOfSMS}
                  onChange={(e) => setMsgOfSMS(e.target.value)}
                />
              </Col>
              <Col md={12}>
                <Form.Group controlId="HeaderField">
                  <Form.Label> Send Image</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="file"
                      name="Image"
                      ref={imageInputRef} // Create a ref for the file input
                      onChange={handleImageChange} // Call the onChange function when the file input changes
                    />
                  </InputGroup>
                </Form.Group>
                {/* You can display the selected image if needed */}
                {selectedImageForDisplay && (
                  <img
                    alt="Selected"
                    src={selectedImageForDisplay}
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
              </Col>
            </Col>
          </Row>

          <div className="text-center p-2">
            <Button onClick={SendSMS} className="btn-tapti">
              <i className="fa fa-send"></i> Send SMS
            </Button>
          </div>
          <hr />
          <Table
            bordered
            className="mt-4"
            style={{ border: "#20336b" }}
            responsive
          >
            <thead>
              <tr>
                <th>PolicyNo</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, rowNumber) => (
                  <tr>
                    <td>{item.policyNo}</td>
                    <td>{item.clientName}</td>
                    <td>{item.clientMobileNo}</td>
                    <td className="text-center">
                      <Button
                        variant="outline-danger "
                        size="sm"
                        onClick={() => removeRow(rowNumber)}
                      >
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal
        show={isSelectClientModalOpen}
        onHide={() => setIsSelectClientModalOpen(false)}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            Select
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Row className="justify-content-center">
              <Col md={3} sm={3} lg={3}>
                <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChangeModel}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected start_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} sm={3} lg={3}>
                <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChangeModel}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected end_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={1} sm={1} lg={1}>
                <Button variant="outline-success" onClick={getClient}>
                  Search
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
      </Modal>



      <GlobalModal
        title="Select Policy"
        bodyComponent={<Row className="align-items-center">
          <Col md={3} lg={3} sm={12} className="mb-8">
            <Form.Select
              aria-label="Select Date Filter"
              value={formData.selectedDateFilter}
              onChange={(e) =>
                setFormDataSelectPolicy({
                  ...formDataOfSelectPolicy,
                  selectedDateFilter: e.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Date Filter
              </option>
              <option value="CreatedDate">Created Policy</option>
              <option value="ExpiredDate">Expired Policy</option>
            </Form.Select>
          </Col>
          <Col md={3} lg={4} sm={12}>
            <InputGroupWithIconAndInLineLabel
              TextBoxCol={15}
              text="From"
              type="date"
              name="start_date"
              value={formDataOfSelectPolicy.start_date}
              onChange={handleInputChangeSelectPolicy}
              required
            />
          </Col>
          <Col md={3} lg={4} sm={12}>
            <InputGroupWithIconAndInLineLabel
              text="To"
              TextBoxCol={15}
              type="date"
              name="end_date"
              value={formDataOfSelectPolicy.end_date}
              onChange={handleInputChangeSelectPolicy}
              required
            />
          </Col>
          <Col className="mb-4">
            <Button className="btn-tapti" onClick={()=>{getPolicyDatewise() ; HandleSelectPolicyModel()}}>
              Search
            </Button>
          </Col>
        </Row>}
        isOpen={showSelectPolicyModel}
        onClose={HandleSelectPolicyModel}
      />
    </>
  );
}
