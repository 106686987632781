import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import SMSApiComponent from "./SMSApiComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function SMSApi() {
  const [SMSApiData, setSMSApiData] = useState([]);
  const [editSMSApiData, setEditSMSApiData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getSMSApiData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "SMSApi",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all document types
  const getSMSApiData = async () => {
    
    await getAllData(
      "SMSApi/AllSMSApi",
      setIsLoading,
      setSMSApiData
    );
  };

  const getSingleSMSApi = async (id) => {
    await getSingleData(
      `SMSApi/SingleSMSApi?SMSApiID=${id}`,
      setEditSMSApiData,
      setIsLoading
    );
  };

  // Delete document type
  const deleteSMSApi = async (id) => {
    // You'll need to replace "SMSApi/DeleteSMSApi" with the appropriate API endpoint
    await deleteData(
      `SMSApi/DeleteSMSApi?SMSApiID=${id}`,
      getSMSApiData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("Company Name", "smsCompanyName", 150),
    createColumnDef("SMS URL Detail", "smsUrl", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "smsApiID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleSMSApi(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "smsApiID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteSMSApi(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New SMS API</span>
          </Card.Header>
          <Card.Body>
            <SMSApiComponent
              returnID={getSMSApiData}
              editedData={editSMSApiData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>SMS API List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={SMSApiData}
            columnDefs={columnDefs}
            height={500} paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
