import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Button, Table, Form,InputGroup } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import TextArea from "../../Common/TextArea";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { getCurrentDate } from "../../Utils/commonFunction";
import { getSingleDataReturn } from "../../Utils/CallApiOnlyReturn";
import HospitalMasterComponent from "../HospitalMaster/HospitalMasterComponent";
import IntimationStatusMasterComponent from "../IntimationStatusMaster/IntimationStatusMasterComponent";
import { format } from "date-fns";
import CustomCheckbox from "../../Common/CustomCheckbox";
import UserSetting from "../../UserSettings.json";
import ImageAddAndPreview from "../../Common/ImageAddAndPreview";
import GlobalFormSelectComponent from "../../Common/GlobalFormSelectComponent";
import AilmentMasterComponent from "../AilmentMaster/AilmentMasterComponent";
import Swal from "sweetalert2";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyClaim = {
  InsuranceClaimID: 0,
  CompanyClaimNumber: "",
  ClientName: "",
  PolicyHolderName: "",
  PolicyNumber: "",
  HospitalAddress: "",
  PolicyID: 0,
  TPAMasterID: 0,
  HospitalMasterID: 0,
  IntimationStatusID: 0,
  PatientName: "",
  AilmentMasterID: 0,
  DateOfAdmission: getCurrentDate(),
  IntimationAmount: "",
  MobileNo: "",
  EmailTo: "",
  EmailIdCC: "",
  Remarks: "",
  SendWhatsAppSMS: true,
  SendTextSMS: true,
  SendEmail: true,
  TPAFileDate: "",
  ClaimAmount: "",
  DecutAmount: "",
  PayableAmount: "",
  UTR: "",
  Date: "",
  SubRemark: "",
  ClientEmailID: "",
  finalClaimStatus: 0,
  EmailIDFieldForTPA: "",
  MobileNoFieldForTPA: "",
};

const emptySubClaim = {
  SubInsuranceClaimID: 0,
  TPAFileDate: "",
  ClaimAmount: "",
  DecutAmount: "",
  PayableAmount: "",
  UTR: "",
  Date: "",
  Remark: "",
  fileSub: null,
  IndexOfImage: "",
  IntimationStatusID: 0,
  ImagePath: null,
};
export default function InsuranceClaimComponent({
  editedData,
  returnID,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const claimFormRef = useRef(null);
  const [formData, setFormData] = useState(emptyClaim);
  const { setIsLoading } = useLoadingContext();
  const [tpaMasterDDValidationError, setTpaMasterDDValidationError] =
    useState(false);
  const [HospitalMasterDDValidationError, setHospitalMasterDDValidationError] =
    useState(false);
  const [
    IntimationStatusDDValidationError,
    setIntimationStatusDDValidationError,
  ] = useState(false);
  const [
    finalClaimStatusDDValidationError,
    setFinalClaimStatusDDValidationError,
  ] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedImage, setSelectedImage] = useState([]);
  const [subClaimForm, setSubClaimForm] = useState([]);
  const fileInputRef = useRef(null);
  const [AilmentMasterDDValidationError, setAilmentMasterDDValidationError] =
    useState(false);

  useEffect(() => {
    if (editedData) {
      setFormData((prevData) => ({
        ...prevData,
        InsuranceClaimID: editedData.insuranceClaim.insuranceClaimID || 0,
        PolicyID: editedData.insuranceClaim.policyID || 0,
        PolicyNumber: editedData.insuranceClaim.policyNo,
        ClientName: editedData.insuranceClaim.clientName || "",
        PolicyHolderName: editedData.insuranceClaim.policyHolderName || "",
        TPAMasterID: editedData.insuranceClaim.tpaMasterID || 0,
        HospitalMasterID: editedData.insuranceClaim.hospitalMasterID || 0,
        IntimationStatusID: editedData.insuranceClaim.intimationStatusID || 0,
        PatientName: editedData.insuranceClaim.patientName || "",
        AilmentMasterID: editedData.insuranceClaim.ailmentMasterID || "",
        DateOfAdmission: format(
          editedData.insuranceClaim.dateOfAdmission,
          "yyyy-MM-dd"
        ),
        ClaimAmount: editedData.insuranceClaim.claimAmount || "",
        MobileNo: editedData.insuranceClaim.mobileNo || "",
        EmailTo: editedData.insuranceClaim.emailTo || "",
        EmailIdCC: editedData.insuranceClaim.emailIdCC || "",
        Remarks: editedData.insuranceClaim.remarks || "",
        HospitalAddress: editedData.insuranceClaim.hospitalAddress || "",
        CompanyClaimNumber: editedData.insuranceClaim.companyClaimNumber || "",
        TPAFileDate:
          (editedData.insuranceClaim.tpaFileDate &&
            format(editedData.insuranceClaim.tpaFileDate, "yyyy-MM-dd")) ||
          "",
        DecutAmount: editedData.insuranceClaim.decutAmount || "",
        PayableAmount: editedData.insuranceClaim.payableAmount || "",
        UTR: editedData.insuranceClaim.utr || "",
        Date:
          (editedData.insuranceClaim.date &&
            format(editedData.insuranceClaim.date || "", "yyyy-MM-dd")) ||
          "",
        SubRemark: editedData.insuranceClaim.subRemark || "",
        IntimationAmount: editedData.insuranceClaim.intimationAmount,
        ClientEmailID: editedData.insuranceClaim.clientEmailID,
        finalClaimStatus: editedData.insuranceClaim.finalClaimStatus,
        EmailIDFieldForTPA: editedData.insuranceClaim.EmailIDFieldForTPA,
        MobileNoFieldForTPA: editedData.insuranceClaim.MobileNoFieldForTPA,
      }));
      if (editedData.insuranceClaim.imagePath) {
        setImagePreview([
          UserSetting.imgURL + editedData.insuranceClaim.imagePath,
        ]);
      }

      if (editedData.subClaims) {
        const updatedSubClaimForms = editedData.subClaims.map(
          (subClaim, index) => {
            return {
              SubInsuranceClaimID: subClaim.subInsuranceClaimID || 0,
              TPAFileDate: subClaim.tpaFileDate
                ? format(subClaim.tpaFileDate, "yyyy-MM-dd")
                : "",
              ClaimAmount: subClaim.claimAmount || "",
              DecutAmount: subClaim.decutAmount || "",
              PayableAmount: subClaim.payableAmount || "",
              UTR: subClaim.utr || "",
              Date: subClaim.date ? format(subClaim.date, "yyyy-MM-dd") : "",
              Remark: subClaim.remark || "",
              fileSub: subClaim.fileSub || null,
              IndexOfImage: subClaim.indexOfImage || "",
              IntimationStatusID: subClaim.intimationStatusID || 0,
              ImagePath: UserSetting.imgURL + subClaim.imagePath || "",
            };
          }
        );
        setSubClaimForm(updatedSubClaimForms);
      }
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  useEffect(() => {
    const claimAmount = parseFloat(formData.ClaimAmount) || 0;
    const decutAmount = parseFloat(formData.DecutAmount) || 0;
    const payableAmount = claimAmount - decutAmount;

    setFormData((prevData) => ({
      ...prevData,
      PayableAmount: payableAmount.toString(),
    }));
  }, [formData.ClaimAmount, formData.DecutAmount]);

  const insertClaim = (event) => {
    ;
    setValidated(true);
    setIsLoading(true);
    event.preventDefault();
    const form = claimFormRef.current;

    if (
      !form.checkValidity() ||
      formData.TPAMasterID === 0 ||
      formData.HospitalMasterID === 0 ||
      formData.AilmentMasterID === 0 ||
      formData.finalClaimStatus === 0
    ) {
      setIsLoading(false);
      setTpaMasterDDValidationError(formData.TPAMasterID === 0);
      setHospitalMasterDDValidationError(formData.HospitalMasterID === 0);
      setIntimationStatusDDValidationError(formData.IntimationStatusID === 0);
      setAilmentMasterDDValidationError(formData.AilmentMasterID === 0);
      setFinalClaimStatusDDValidationError(formData.finalClaimStatus === 0);
      return;
    } else {
      const form_Data = new FormData();
      const data = {
        InsuranceClaimID: formData?.InsuranceClaimID || 0,
        PolicyID: formData.PolicyID,
        TPAMasterID: formData.TPAMasterID,
        HospitalMasterID: formData.HospitalMasterID,
        IntimationStatusID: formData.IntimationStatusID,
        PatientName: formData.PatientName,
        AilmentMasterID: formData.AilmentMasterID,
        DateOfAdmission: formData.DateOfAdmission,
        IntimationAmount: formData.IntimationAmount,
        MobileNo: formData.MobileNo,
        EmailTo: formData.EmailTo,
        EmailIdCC: formData.EmailIdCC,
        Remarks: formData.Remarks,
        CompanyClaimNumber: formData?.CompanyClaimNumber || "",
        SendWhatsAppSMS: formData.SendWhatsAppSMS,
        SendTextSMS: formData.SendTextSMS,
        SendEmail: formData.SendEmail,
        TPAFileDate: formData.TPAFileDate,
        ClaimAmount: formData.ClaimAmount,
        DecutAmount: formData.DecutAmount,
        PayableAmount: formData.PayableAmount,
        UTR: formData.UTR,
        Date: formData.Date,
        SubRemark: formData.SubRemark,
        ClientEmailID: formData.ClientEmailID,
        FinalClaimStatus: formData.finalClaimStatus,
        EmailIDFieldForTPA: formData.EmailIDFieldForTPA,
        MobileNoFieldForTPA: formData.MobileNoFieldForTPA,
        // File:selectedImage[0],
        // SubInsuranceClaimDataModel : subClaimForm
      }; // Copy formData to avoid mutating it

      // for (const key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     if (key === "SubInsuranceClaimDataModel") {
      //       form_Data.append(key, JSON.stringify(data[key])); // Stringify the sub-claims array
      //     } else {
      //       form_Data.append(key, data[key]);
      //     }
      //   }
      // }

      // for (const key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     form_Data.append(key, data[key]);
      //   }
      // }
      form_Data.append("Claim", JSON.stringify(data));
      form_Data.append("SubClaim", JSON.stringify(subClaimForm));
      form_Data.append("ClaimFile", selectedImage);
      // for (let i = 0; i < selectedImage.length; i++) {
      //   form_Data.append("ClaimFile", selectedImage[i]);
      // }
      for (let i = 0; i < subClaimForm.length; i++) {
        if (subClaimForm[i].fileSub) {
          form_Data.append(
            `${subClaimForm[i].IndexOfImage}`,
            subClaimForm[i].fileSub
          );
        }
      }

      const endpoint =
        formData.InsuranceClaimID === 0
          ? "InsuranceClaim/InsertInsuranceClaim"
          : "InsuranceClaim/EditInsuranceClaim";

      api[formData.InsuranceClaimID === 0 ? "post" : "patch"](
        endpoint,
        form_Data
      )
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            if (returnID) {
              returnID(result.data.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          reset();
          onClose();
          setValidated(false);
          setIsLoading(false);
        });
    }
  };

  const reset = () => {
    setFormData(emptyClaim);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchCourierData(event.target.value);
    }
  };

  const fetchCourierData = () => {
    const policyNo = formData.PolicyNumber;
    if (!policyNo) {
     showMessage(400,"Please enter a valid policy number.");
      return;
    }

    setIsLoading(true);

    api
      .get(`Courier/FindCourierDataByPolicyNo?PolicyNo=${policyNo}`)
      .then((result) => {
        const data = result.data;
        if (!data.flag) {
         showMessage(400,data.message);
          setIsLoading(false);
          return;
        }

        const courierDetails = data.data;
        setFormData((prevData) => ({
          ...prevData,
          PolicyID: courierDetails.policyID,
          PolicyNumber: data.data.policyNumber,
          ClientName: courierDetails.clientName,
          PolicyHolderName: courierDetails.policyHolderName,
          TPAMasterID: courierDetails.tpaMasterID,
          EmailTo: courierDetails.tpaMasterEmail,
          MobileNo: courierDetails.mobile,
          ClientEmailID: courierDetails.emailIdTo,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        showMessage(400,"An error occurred while fetching courier details.");
        setIsLoading(false);
      });
  };

  const HandleTPAselectionChange = async (id) => {
    const data = await getSingleDataReturn(
      `TPAMaster/SingleTPAMaster?TPAMasterID=${id}`,
      setIsLoading
    );
    {
      data &&
        setFormData((prevData) => ({
          ...prevData,
          EmailTo: data.tpaMasterEmail,
        }));
    }
  };

  const HandleHospitalMasterSelectionChange = async (id) => {
    const data = await getSingleDataReturn(
      `HospitalMaster/SingleHospital?HospitalMasterID=${id}`,
      setIsLoading
    );
    {
      data &&
        setFormData((prevData) => ({
          ...prevData,
          HospitalAddress: data.hospitalAddress,
        }));
    }
  };
  const handleImageUpload = async (imageFile) => {
    setSelectedImage(imageFile);
  };

  const AddSubClaim = () => {
    setSubClaimForm([...subClaimForm, emptySubClaim]);
  };

  const RemoveSubClaim = (index) => {
    const updatedForms = subClaimForm.filter((_, i) => i !== index);
    setSubClaimForm(updatedForms);
  };

  const handleInputChangeOfSubClaim = (index, event) => {
    const { name, value } = event.target;

    // Update the specific form field (ClaimAmount or DecutAmount) in subClaimForm
    const updatedForms = subClaimForm.map((form, i) => {
      if (i === index) {
        return {
          ...form,
          [name]: value,
        };
      }
      return form;
    });

    // Calculate PayableAmount based on updated ClaimAmount and DecutAmount
    const updatedForm = updatedForms[index];
    const claimAmount = parseFloat(updatedForm.ClaimAmount || 0);
    const decutAmount = parseFloat(updatedForm.DecutAmount || 0);
    const payableAmount = claimAmount - decutAmount;

    // Update PayableAmount in the specific form
    updatedForms[index].PayableAmount = isNaN(payableAmount)
      ? 0
      : payableAmount.toFixed(2);

    // Update state with the modified forms array
    setSubClaimForm(updatedForms);
  };

  const handleImageUploadOfSubClaim = (index, imageFile) => {
    const updatedForms = subClaimForm.map((form, i) =>
      i === index ? { ...form, fileSub: imageFile, IndexOfImage: index } : form
    );
    setSubClaimForm(updatedForms);
  };

  const HandelDeleteSubClaim = async (id, index) => {
    ;
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      setIsLoading(true);
      try {
        const response = await api.patch(
          `InsuranceClaim/DeleteSubInsuranceClaim?SubInsuranceClaimID=${id}`
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Your record has been deleted.",
            showConfirmButton: false,
            timer: 1500,
          });
          RemoveSubClaim(index);
        }
      } catch (error) {
        console.error(error);
        Swal.fire("Error", "An error occurred.", "error");
      } finally {
        setIsLoading(false);
      }
    } else {
      setSubClaimForm(subClaimForm);
    }
    if (subClaimForm.length === 0) {
      setSubClaimForm([emptySubClaim]);
    }
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={claimFormRef}
        formName="ClaimForm"
      >
        <Row>
          {/* <InputGroupWithIcon
            ColSize={{ sm: 6, md: 6, lg: 3 }}
            Label="Policy No"
            icon="fas fa-file-alt"
            type="text"
            name="PolicyNumber"
            placeholder="Enter Policy No"
            value={formData.PolicyNumber}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            required
          /> */}
           <Col md={6} sm={12} lg={3}>
            <Form.Group controlId="PolicyNo">
              <Form.Label>PolicyNo:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-file-alt"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="PolicyNumber"
                  placeholder="Enter Policy No"
                  value={formData.PolicyNumber}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  className="RemoveBorderRadiusTextBox"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter policyNo
                </Form.Control.Feedback>
                <InputGroup.Text id="basic-addon1" style={{ padding: "1px" }}>
                  <Button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#20336b",
                      color: "white",
                      borderRadius: "0px",
                    }}
                    variant="primary"
                    onClick={fetchCourierData}
                  >
                    <i className="fas fa-search"></i>
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
          <InputGroupWithIcon
            ColSize={{ sm: 6, md: 6, lg: 3 }}
            Label="Client Name"
            icon="fas fa-user"
            type="text"
            name="ClientName"
            placeholder="Enter Client Name"
            value={formData.ClientName}
            onChange={handleInputChange}
            disabled
          />
          <InputGroupWithIcon
            ColSize={{ sm: 6, md: 6, lg: 3 }}
            Label="Holder Name"
            icon="fas fa-user"
            type="text"
            name="PolicyHolderName"
            placeholder="Enter Holder Name"
            value={formData.PolicyHolderName}
            onChange={handleInputChange}
            disabled
          />
          <GlobalFormSelectComponent
            ColSize={{ sm: 6, md: 6, lg: 3 }}
            formLabel="TPA Master"
            apiEndpoint="TPAMaster/AllTPAMasters"
            valueOfDD="tpaMasterID"
            labelOfDD="tpaMasterName"
            onSelectOption={(tpaMasterID) => {
              setFormData((prevData) => ({
                ...prevData,
                TPAMasterID: tpaMasterID,
              }));
              setTpaMasterDDValidationError(false);
              HandleTPAselectionChange(tpaMasterID);
            }}
            selectedOptionOfComponent={formData.TPAMasterID}
            ValidationError={false}
            required={false}
            disabled={true}
          />
        </Row>
        <Row className="mt-4 ">
          <Col lg={6} md={12} sm={12} className="p-0">
            <Card className="p-3">
              <Row>
                <InputGroupWithIcon
                  Label="Date Of Admission"
                  ColSize={{ sm: 12, md: 4, lg: 4 }}
                  icon="far fa-calendar-alt"
                  type="date"
                  name="DateOfAdmission"
                  value={formData.DateOfAdmission}
                  onChange={handleInputChange}
                  required
                />
                <InputGroupWithIcon
                  Label="Patient Name"
                  ColSize={{ sm: 12, md: 8, lg: 8 }}
                  icon="fas fa-user"
                  type="text"
                  name="PatientName"
                  placeholder="Enter Patient Name"
                  value={formData.PatientName}
                  onChange={handleInputChange}
                  required
                />
              </Row>
              <Row>
                <GlobalDDComponent
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  formLabel="Ailments"
                  apiEndpoint="AilmentMaster/AllAilments"
                  valueOfDD="ailmentMasterID"
                  labelOfDD="ailmentName"
                  onSelectOption={(ailmentMasterID) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      AilmentMasterID: ailmentMasterID,
                    }));
                    setAilmentMasterDDValidationError(false);
                  }}
                  selectedOptionOfComponent={formData.AilmentMasterID}
                  ValidationError={AilmentMasterDDValidationError}
                  offcanvasTitle="Add Ailment"
                  offcanvasBodyComponent={<AilmentMasterComponent />}
                />
                <InputGroupWithIcon
                  Label="Intimation Amount"
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="IntimationAmount"
                  placeholder="Enter Intimation Amount"
                  value={formData.IntimationAmount}
                  onChange={handleInputChange}
                  required
                />
              </Row>
              <Row>
                {/* <GlobalDDComponent
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  formLabel="Intimation Status "
                  apiEndpoint="IntimationStatusMaster/AllIntimationStatusMasters"
                  valueOfDD="intimationStatusID"
                  labelOfDD="intimationStatusName"
                  onSelectOption={(intimationStatusID) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      IntimationStatusID: intimationStatusID,
                    }));
                    setIntimationStatusDDValidationError(false);
                  }}
                  selectedOptionOfComponent={formData.IntimationStatusID}
                  ValidationError={IntimationStatusDDValidationError}
                  offcanvasTitle="Add TPA Master"
                  offcanvasBodyComponent={<IntimationStatusMasterComponent />}
                /> */}
                <GlobalDDComponent
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  formLabel="Hospital Master"
                  apiEndpoint="HospitalMaster/AllHospitals"
                  valueOfDD="hospitalMasterID"
                  labelOfDD="hospitalName"
                  onSelectOption={(hospitalMasterID) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      HospitalMasterID: hospitalMasterID,
                    }));
                    setHospitalMasterDDValidationError(false);
                    HandleHospitalMasterSelectionChange(hospitalMasterID);
                  }}
                  selectedOptionOfComponent={formData.HospitalMasterID}
                  ValidationError={HospitalMasterDDValidationError}
                  offcanvasTitle="Add TPA Master"
                  offcanvasBodyComponent={<HospitalMasterComponent />}
                />
                <Col sm={12} md={6} lg={6} className="flex items-center">
                  {formData.HospitalAddress && (
                    <div className="d-flex">
                      <strong>Hospital Address:&nbsp;&nbsp;</strong>{" "}
                      {formData.HospitalAddress}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <TextArea
                  ColSize={{ sm: 12, md: 12, lg: 12 }}
                  rows={3}
                  // Label=" Remarks"
                  icon="fas fa-pencil-alt"
                  name="Remarks"
                  placeholder="Remarks"
                  value={formData.Remarks}
                  onChange={handleInputChange}
                />
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={12} sm={12} className="p-0">
            <Card className="p-3">
              <InputGroupWithIcon
                Label="Client Mobile No"
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                icon="fas fa-mobile-alt"
                type="number"
                name="MobileNo"
                placeholder="Enter MobileNo"
                value={formData.MobileNo}
                onChange={handleInputChange}
                required
              />
              <InputGroupWithIcon
                Label="Client EmailID"
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                icon="fas fa-envelope"
                type="text"
                name="ClientEmailID"
                placeholder="Enter Client EmailID"
                value={formData.ClientEmailID}
                onChange={handleInputChange}
              />
              <InputGroupWithIcon
                Label="TPA Email To"
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                icon="fas fa-envelope"
                type="text"
                name="EmailTo"
                placeholder="Enter Email To"
                value={formData.EmailTo}
                onChange={handleInputChange}
                required
              />
              <InputGroupWithIcon
                Label="TPA EmailId CC"
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                icon="fas fa-envelope"
                type="text"
                name="EmailIdCC"
                placeholder="Enter EmailId CC"
                value={formData.EmailIdCC}
                onChange={handleInputChange}
              />
              {!editedData && <Row>
                <InputGroupWithIcon
                  ColSize={{ sm: 12, md: 12, lg: 6 }}
                  icon="fas fa-envelope"
                  type="text"
                  name="EmailIDFieldForTPA"
                  placeholder="EmailId Field For TPA"
                  value={formData.EmailIDFieldForTPA}
                  onChange={handleInputChange}
                />
                <InputGroupWithIcon
                  ColSize={{ sm: 12, md: 12, lg: 6 }}
                  icon="fas fa-mobile-alt"
                  type="text"
                  name="MobileNoFieldForTPA"
                  placeholder="MobileNo Field For TPA"
                  value={formData.MobileNoFieldForTPA}
                  onChange={handleInputChange}
                />
              </Row>}
            </Card>
          </Col>
        </Row>
        <Row>
          {editedData && (
            <InputGroupWithIcon
              Label="Company ClaimNumber"
              ColSize={{ sm: 12, md: 12, lg: 6 }}
              icon="fas fa-envelope"
              type="text"
              name="CompanyClaimNumber"
              placeholder="Enter CompanyClaimNumber"
              value={formData.CompanyClaimNumber}
              onChange={handleInputChange}
            />
          )}
          {!editedData && (
            <Col sm={12} md={12} lg={6} className="flex items-center">
              <div className="d-flex">
                <CustomCheckbox
                  icon="fab fa-whatsapp"
                  iconColor="Green"
                  name="SendWhatsAppSMS"
                  checked={formData.SendWhatsAppSMS}
                  onChange={handleInputChange}
                />
                <CustomCheckbox
                  icon="fas fa-sms"
                  iconColor="#4290f5"
                  name="SendTextSMS"
                  checked={formData.SendTextSMS}
                  onChange={handleInputChange}
                />
                <CustomCheckbox
                  icon="fas fa-envelope"
                  iconColor="#ed4a3d"
                  name="SendEmail"
                  checked={formData.SendEmail}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          )}
          <GlobalDDComponent
            ColSize={{ sm: 12, md: 6, lg: 6 }}
            formLabel="Final Claim Status"
            apiEndpoint="IntimationStatusMaster/AllIntimationStatusMasters"
            valueOfDD="intimationStatusID"
            labelOfDD="intimationStatusName"
            onSelectOption={(intimationStatusID) => {
              setFormData((prevData) => ({
                ...prevData,
                finalClaimStatus: intimationStatusID,
              }));
              setFinalClaimStatusDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.finalClaimStatus}
            ValidationError={finalClaimStatusDDValidationError}
            offcanvasTitle="Add TPA Master"
            offcanvasBodyComponent={<IntimationStatusMasterComponent />}
          />
        </Row>
        {editedData && (
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <span>Main Claim</span>
              <GlobalDDComponent
                ColSize={{ sm: 12, md: 6, lg: 4 }}
                apiEndpoint="IntimationStatusMaster/AllIntimationStatusMasters"
                valueOfDD="intimationStatusID"
                labelOfDD="intimationStatusName"
                onSelectOption={(intimationStatusID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    IntimationStatusID: intimationStatusID,
                  }));
                  setIntimationStatusDDValidationError(false);
                }}
                selectedOptionOfComponent={formData.IntimationStatusID}
                // ValidationError={IntimationStatusDDValidationError}
                offcanvasTitle="Add TPA Master"
                offcanvasBodyComponent={<IntimationStatusMasterComponent />}
              />
            </Card.Header>
            <Card.Body>
              <Row>
                <InputGroupWithIcon
                  ColSize={{ sm: 6, md: 6, lg: 3 }}
                  Label="TPAFileDate"
                  icon="fas fa-file-alt"
                  type="date"
                  name="TPAFileDate"
                  // placeholder="Enter Policy No"
                  value={formData.TPAFileDate}
                  onChange={handleInputChange}
                />
                <InputGroupWithIcon
                  Label="Claim Amount"
                  ColSize={{ sm: 12, md: 6, lg: 3 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="ClaimAmount"
                  placeholder="Enter Claim Amount"
                  value={formData.ClaimAmount}
                  onChange={handleInputChange}
                />
                <InputGroupWithIcon
                  Label="Decut Amount"
                  ColSize={{ sm: 12, md: 6, lg: 3 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="DecutAmount"
                  placeholder="Enter Decut Amount"
                  value={formData.DecutAmount}
                  onChange={handleInputChange}
                />
                <InputGroupWithIcon
                  Label="Payable Amount"
                  ColSize={{ sm: 12, md: 6, lg: 3 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="PayableAmount"
                  placeholder="Enter Payable Amount"
                  value={formData.PayableAmount}
                  onChange={handleInputChange}
                />
              </Row>
              <Row>
                <InputGroupWithIcon
                  Label="UTR Amount"
                  ColSize={{ sm: 12, md: 6, lg: 3 }}
                  icon="fas fa-rupee-sign"
                  type="text"
                  name="UTR"
                  placeholder="Enter UTR"
                  value={formData.UTR}
                  onChange={handleInputChange}
                />
                <InputGroupWithIcon
                  Label="Date"
                  ColSize={{ sm: 12, md: 6, lg: 3 }}
                  icon="fas fa-rupee-sign"
                  type="Date"
                  name="Date"
                  // placeholder="Enter Date"
                  value={formData.Date}
                  onChange={handleInputChange}
                />
                <Col sm={12} md={6} lg={3} className="d-flex items-center">
                  <ImageAddAndPreview
                    imagePreview={imagePreview}
                    ClearImages={refreshKey}
                    Label="Image"
                    onFileUpload={handleImageUpload}
                    ColSize={
                      editedData
                        ? { sm: 10, md: 10, lg: 10 }
                        : { sm: 12, md: 12, lg: 12 }
                    }
                  />
                  <Col sm={2} md={2} lg={2} className="text-center ">
                    {editedData && (
                      <a
                        href={imagePreview}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontSize: "30px",
                          cursor: "Pointer",
                          border: "1px solid #464457",
                          padding: "5px",
                        }}
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </a>
                    )}
                  </Col>
                </Col>
                <InputGroupWithIcon
                  Label="SubRemark"
                  ColSize={{ sm: 12, md: 6, lg: 3 }}
                  icon="fas fa-rupee-sign"
                  type="text"
                  name="SubRemark"
                  placeholder="Enter SubRemark"
                  value={formData.SubRemark}
                  onChange={handleInputChange}
                />
              </Row>
            </Card.Body>
          </Card>
        )}
        {subClaimForm.length > 0 && (
          <div>
            <Table striped bordered hover responsive className="SubClaimTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ minWidth: "150px" }}>Intimation Status</th>
                  <th style={{ minWidth: "50px" }}>TPA File Date</th>
                  <th style={{ minWidth: "130px" }}>Claim Amount</th>
                  <th style={{ minWidth: "130px" }}>Deduct Amount</th>
                  <th style={{ minWidth: "130px" }}>Payable Amount</th>
                  <th style={{ minWidth: "200px" }}>UTR</th>
                  <th style={{ minWidth: "50px" }}>Date</th>
                  <th style={{ minWidth: "110px" }}>Image</th>
                  {editedData && <th>View</th>}
                  <th style={{ minWidth: "200px" }}>Sub Remark</th>
                  <th>+/-</th>
                </tr>
              </thead>
              <tbody>
                {subClaimForm.map((formData, index) => (
                  <tr key={index}>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {index + 1}
                    </td>
                    <td>
                      <GlobalFormSelectComponent
                        ColSize={{ sm: 12, md: 12, lg: 12 }}
                        apiEndpoint="IntimationStatusMaster/AllIntimationStatusMasters"
                        valueOfDD="intimationStatusID"
                        labelOfDD="intimationStatusName"
                        onSelectOption={(intimationStatusID) => {
                          const updatedForms = subClaimForm.map((form, i) =>
                            i === index
                              ? {
                                  ...form,
                                  IntimationStatusID: intimationStatusID,
                                }
                              : form
                          );
                          setSubClaimForm(updatedForms);
                        }}
                        selectedOptionOfComponent={formData.IntimationStatusID}
                        ValidationError={false}
                        required={false}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        name="TPAFileDate"
                        value={formData.TPAFileDate}
                        onChange={(e) => handleInputChangeOfSubClaim(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="ClaimAmount"
                        placeholder="Claim Amt"
                        value={formData.ClaimAmount}
                        onChange={(e) => handleInputChangeOfSubClaim(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="DecutAmount"
                        placeholder="Deduct Amt"
                        value={formData.DecutAmount}
                        onChange={(e) => handleInputChangeOfSubClaim(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="PayableAmount"
                        placeholder="Payable Amt"
                        value={formData.PayableAmount}
                        onChange={(e) => handleInputChangeOfSubClaim(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="UTR"
                        placeholder="Enter UTR"
                        value={formData.UTR}
                        onChange={(e) => handleInputChangeOfSubClaim(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        name="Date"
                        value={formData.Date}
                        onChange={(e) => handleInputChangeOfSubClaim(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="file"
                        onChange={(e) =>
                          handleImageUploadOfSubClaim(index, e.target.files[0])
                        }
                        custom
                        style={{
                          border:
                            subClaimForm[index].fileSub != null
                              ? "2px dashed blue"
                              : "",
                        }}
                      />
                    </td>

                    {editedData && (
                      <td style={{ textAlign: "center" }}>
                        <a
                          href={formData.ImagePath}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontSize: "25px", cursor: "pointer" }}
                        >
                          {formData.ImagePath && (
                            <i className="far fa-eye" aria-hidden="true"></i>
                          )}
                        </a>
                      </td>
                    )}
                    <td>
                      <Form.Control
                        type="text"
                        name="Remark"
                        placeholder="Enter Remark"
                        value={formData.Remark}
                        onChange={(e) => handleInputChangeOfSubClaim(index, e)}
                      />
                    </td>
                    <td style={{ display: "flex" }}>
                      {index === subClaimForm.length - 1 && (
                        <Button onClick={AddSubClaim} className="mr-1">
                          +
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          if (formData.SubInsuranceClaimID > 0) {
                            HandelDeleteSubClaim(
                              formData.SubInsuranceClaimID,
                              index
                            );
                          } else {
                            RemoveSubClaim(index);
                          }
                        }}
                        className="btn-danger"
                      >
                        -
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        <div className=" flex justify-center">
          {editedData && subClaimForm.length === 0 && (
            <Button
              onClick={() => setSubClaimForm([emptySubClaim])}
              className="btn-tapti   justify-end"
            >
              Addition Claim
            </Button>
          )}
        </div>
        <OnClickSubmitButtonOfForm
          onClick={insertClaim}
          HeadID={formData?.InsuranceClaimID}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}

/* {subClaimForm &&
          subClaimForm.map((formData, index) => (
            <Card key={index}>
              <Card.Header>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    {" "}
                    <span>Additional Claim {index + 1}</span>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="d-flex">
                    <GlobalDDComponent
                      ColSize={{ sm: 10, md: 10, lg: 10 }}
                      apiEndpoint="IntimationStatusMaster/AllIntimationStatusMasters"
                      valueOfDD="intimationStatusID"
                      labelOfDD="intimationStatusName"
                      onSelectOption={(intimationStatusID) => {
                        const updatedForms = subClaimForm.map((form, i) =>
                          i === index
                            ? {
                                ...form,
                                IntimationStatusID: intimationStatusID,
                              }
                            : form
                        );
                        setSubClaimForm(updatedForms);
                      }}
                      selectedOptionOfComponent={formData.IntimationStatusID}
                      ValidationError={false} // Replace with your validation logic
                      offcanvasTitle="Add TPA Master"
                      offcanvasBodyComponent={
                        <IntimationStatusMasterComponent />
                      }
                    />
                    <Col sm={1} md={1} lg={1} className="text-center">
                    <Button onClick={() => RemoveSubClaim(index)} className="ms-2 btn-danger">-</Button>
                    </Col>
                    {index === subClaimForm.length - 1 && (
                      <Col sm={1} md={1} lg={1} className="text-center">
                        <Button onClick={AddSubClaim} className="text-end">
                          +
                        </Button>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row></Row>
                <Row>
                  <InputGroupWithIcon
                    ColSize={{ sm: 6, md: 6, lg: 3 }}
                    Label="TPAFileDate"
                    icon="fas fa-file-alt"
                    type="date"
                    name="TPAFileDate"
                    value={formData.TPAFileDate}
                    onChange={(e) => handleInputChange(index, e)}
                    required
                  />
                  <InputGroupWithIcon
                    Label="Claim Amount"
                    ColSize={{ sm: 12, md: 6, lg: 3 }}
                    icon="fas fa-rupee-sign"
                    type="number"
                    name="ClaimAmount"
                    placeholder="Enter Claim Amount"
                    value={formData.ClaimAmount}
                    onChange={(e) => handleInputChange(index, e)}
                    required
                  />
                  <InputGroupWithIcon
                    Label="Decut Amount"
                    ColSize={{ sm: 12, md: 6, lg: 3 }}
                    icon="fas fa-rupee-sign"
                    type="number"
                    name="DecutAmount"
                    placeholder="Enter Decut Amount"
                    value={formData.DecutAmount}
                    onChange={(e) => handleInputChange(index, e)}
                    required
                  />
                  <InputGroupWithIcon
                    Label="Payable Amount"
                    ColSize={{ sm: 12, md: 6, lg: 3 }}
                    icon="fas fa-rupee-sign"
                    type="number"
                    name="PayableAmount"
                    placeholder="Enter Payable Amount"
                    value={formData.PayableAmount}
                    onChange={(e) => handleInputChange(index, e)}
                    required
                  />
                </Row>
                <Row>
                  <InputGroupWithIcon
                    Label="UTR"
                    ColSize={{ sm: 12, md: 6, lg: 3 }}
                    icon="fas fa-rupee-sign"
                    type="text"
                    name="UTR"
                    placeholder="Enter UTR"
                    value={formData.UTR}
                    onChange={(e) => handleInputChange(index, e)}
                    required
                  />
                  <InputGroupWithIcon
                    Label="Date"
                    ColSize={{ sm: 12, md: 6, lg: 3 }}
                    icon="fas fa-rupee-sign"
                    type="date"
                    name="Date"
                    value={formData.Date}
                    onChange={(e) => handleInputChange(index, e)}
                    required
                  />
                  <Col sm={12} md={6} lg={3} className="d-flex items-center">
                    <ImageAddAndPreview
                      imagePreview={formData.imagePreview}
                      ClearImages={() =>
                        setRefreshKey((prevKey) => prevKey + 1)
                      }
                      Label="Image"
                      onFileUpload={(e) =>
                        handleImageUpload(index, e.target.files[0])
                      }
                      ColSize={
                        subClaimForm.length > 1
                          ? { sm: 10, md: 10, lg: 10 }
                          : { sm: 12, md: 12, lg: 12 }
                      }
                      required={subClaimForm.length === 1}
                    />
                    <Col sm={2} md={2} lg={2} className="text-center">
                      {formData.imagePreview && (
                        <a
                          href={formData.imagePreview}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            fontSize: "30px",
                            cursor: "pointer",
                            border: "1px solid #464457",
                            padding: "5px",
                          }}
                        >
                          <i className="far fa-eye" aria-hidden="true"></i>
                        </a>
                      )}
                    </Col>
                  </Col>
                  <InputGroupWithIcon
                    Label="SubRemark"
                    ColSize={{ sm: 12, md: 6, lg: 3 }}
                    icon="fas fa-rupee-sign"
                    type="text"
                    name="SubRemark"
                    placeholder="Enter SubRemark"
                    value={formData.SubRemark}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Row>
              </Card.Body>
            </Card>
          ))} */
