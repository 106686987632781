import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import IntimationStatusMasterComponent from "./IntimationStatusMasterComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function IntimationStatusMaster() {
  const [intimationStatusData, setIntimationStatusData] = useState([]);
  const [editIntimationStatusData, setEditIntimationStatusData] =
    useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getIntimationStatusData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "IntimationStatusMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all intimation status masters
  const getIntimationStatusData = async () => {
    await getAllData(
      "IntimationStatusMaster/AllIntimationStatusMasters",
      setIsLoading,
      setIntimationStatusData
    );
  };

  // Fetch a single intimation status master by ID
  const getSingleIntimationStatus = async (id) => {
    await getSingleData(
      `IntimationStatusMaster/SingleIntimationStatusMaster?IntimationStatusID=${id}`,
      setEditIntimationStatusData,
      setIsLoading
    );
  };

  // Delete intimation status master by ID
  const deleteIntimationStatus = async (id) => {
    await deleteData(
      `IntimationStatusMaster/DeleteIntimationStatusMaster?IntimationStatusID=${id}`,
      getIntimationStatusData,
      setIsLoading
    );
  };

  // Define column definitions for the grid
  const columnDefs = [
    createColumnDef("Intimation Status Name", "intimationStatusName", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "intimationStatusID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value, data }) =>
              !["Paid", "Intimated"].includes(data.intimationStatusName) ? (
                <ion-icon
                  name="create-outline"
                  class="iconsFonts editIcon"
                  onClick={() => getSingleIntimationStatus(value)}
                ></ion-icon>
              ) : null,
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "intimationStatusID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value, data }) =>
              !["Paid", "Intimated"].includes(data.intimationStatusName) ? (
                <ion-icon
                  name="trash-outline"
                  class="iconsFonts deleteIcon"
                  onClick={() => deleteIntimationStatus(value)}
                ></ion-icon>
              ) : null,
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Intimation Status</span>
          </Card.Header>
          <Card.Body>
            <IntimationStatusMasterComponent
              returnID={getIntimationStatusData}
              editedData={editIntimationStatusData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>Intimation Status List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={intimationStatusData}
            columnDefs={columnDefs}
            height={500}
            paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
