import React from 'react';
import { Offcanvas } from 'react-bootstrap';

export default function GlobalOffcanvas({ isOpen, onClose, bodyComponent ,title}) {
  return (
    <Offcanvas  show={isOpen} onHide={onClose} >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='scrollable-content' style={{overflow:"auto"}}>
        {bodyComponent}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
