import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Row, Col, Form, InputGroup } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import api from "../../Utils/api";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import CourierComponent from "./CourierComponent";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { InputGroupWithIconAndInLineLabel } from "../../Common/MyComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import {
  GetPre7DayDate,
  getCurrentDate,
  GetPre30DayDate,
  filterParams
} from "../../Utils/commonFunction";

const emtSearchFormData = {
  start_date: GetPre30DayDate(),
  end_date: getCurrentDate(),
  policyNo: "",
};

export default function Courier() {
  const [courierData, setCourierData] = useState([]);
  const [editcourierData, setEditcourierData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const [formData, setFormData] = useState(emtSearchFormData);

  const UserPermissions = useCallback(async () => {
    await CheckRoleTemplatePermissions(
      "Courier",
      setRoleTemplatePermissions,
      setIsLoading
    );
  }, [setIsLoading]);

  const getCourier = async () => {
    const { start_date, end_date, policyNo } = formData;

    // Validation for date range
    if (new Date(start_date) > new Date(end_date)) {
      alert("Start date cannot be after the end date.");
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `Courier/AllCourierDateWise?START_DATE=${start_date}&END_DATE=${end_date}&PolicyNo=${policyNo}`
      );
      setCourierData(Array.isArray(data.data) ? data.data : []);
    } catch (error) {
      console.error("Not Found");
      setCourierData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFormData(emtSearchFormData);
  };

  useEffect(() => {
    if (formData === emtSearchFormData) {
      getCourier();
    }
  }, [formData]);

  // const getcourierData = useCallback(async () => {
  //   await getAllData("Courier/AllCourier", setIsLoading, setCourierData);
  // }, [setIsLoading]);

  useEffect(() => {
    UserPermissions();
    getCourier();
  }, []);

  const getSinglecourier = async (id) => {
    await getSingleData(
      `Courier/SingleCourier?CourierID=${id}`,
      setEditcourierData,
      setIsLoading
    );
  };

  // Delete courier data
  const deletecourier = async (id) => {
    await deleteData(
      `Courier/DeleteCourier?CourierID=${id}`,
      getCourier,
      setIsLoading
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const exportToExcel = () => {
    setIsLoading(true);
    setTimeout(() => {
      const fileName = "courier_data.xlsx";

      const formattedData = courierData.map((item) => ({
        PolicyNo: item.policyNo,
        ClientName: item.clientName,
        HolderName: item.policyHolderName,
        CourierName: item.courierName,
        TrackingNumber: item.trackingNumber,
        TrackShipmentLink: item.trackingNumber
          ? `http://www.anjanicourier.in/Doc_Track.aspx?No=${item.trackingNumber}`
          : null,
        CourierStatus: item.courierStatus,
        DispatchDate: convertDateFormat(item.dispatchedDateTime),
        Remarks: item.remarks,
      }));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Courier Data");

      worksheet.columns = [
        { header: "PolicyNo", key: "PolicyNo", width: 20 },
        { header: "ClientName", key: "ClientName", width: 20 },
        { header: "HolderName", key: "HolderName", width: 20 },
        { header: "DispatchDate", key: "DispatchDate", width: 20 },
        { header: "CourierName", key: "CourierName", width: 20 },
        { header: "TrackingNumber", key: "TrackingNumber", width: 20 },
        { header: "Track Shipments", key: "TrackShipmentLink", width: 30 },
        { header: "CourierStatus", key: "CourierStatus", width: 20 },
        { header: "Remarks", key: "Remarks", width: 20 },
      ];

      formattedData.forEach((row) => {
        const rowIndex = worksheet.addRow(row).number;

        // Add hyperlink to the "Track Shipments" column (Assuming it's the 6th column)
        if (row.TrackShipmentLink) {
          const cell = worksheet.getCell(`F${rowIndex}`);
          cell.value = {
            text: "Track your shipment",
            hyperlink: row.TrackShipmentLink,
          };
          cell.font = { color: { argb: "FF0000FF" }, underline: true };
        }
      });

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });
      setIsLoading(false);
    }, 1000);
  };

  const columnDefs = [
    createColumnDef("PolicyNo", "policyNo", 250),
    createColumnDef("ClientName", "clientName", 250),
    createColumnDef("HolderName", "policyHolderName", 250),
    {
      headerName: "Dispatch Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 200,
      headerClass: "centered-header widthTableColum",
      field: "dispatchedDateTime",
      cellRenderer: ({ value }) => convertDateFormat(value),
    },
    createColumnDef("CourierName", "courierName", 250),
    createColumnDef("TrackingNo.", "trackingNumber", 150),
    {
      headerName: "Track Shipments",
      width: 190,
      field: "trackingNumber", // This binds the tracking number to the column
      cellRenderer: ({ value }) => {
        if (value) {
          return (
            <a
              href={`http://www.anjanicourier.in/Doc_Track.aspx?No=${value}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 underline"
            >
              Track your shipment
            </a>
          );
        }
        return null; // If there's no tracking number, nothing is rendered
      },
    },
    createColumnDef("CourierStatus", "courierStatus", 150),

    createColumnDef("Remarks", "remarks", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "courierID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSinglecourier(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "courierID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deletecourier(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Courier</span>
          </Card.Header>
          <Card.Body>
            <CourierComponent
              returnID={getCourier}
              editedData={editcourierData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Courier List</span>
          <Button
            type="button"
            variant="success"
            className="mb-20px"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </Card.Header>
        <Card.Body>
          <Row className="align-items-center mb-2">
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={4} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">Policy No</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="policyNo"
                  placeholder="Enter Policy No"
                  value={formData.policyNo}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={2}
              className="d-flex justify-between items-center"
            >
              <Button variant="success" onClick={getCourier}>
                <i class="fa fa-search"></i> Search
              </Button>
              <Button variant="danger" onClick={handleReset}>
                <i class="	fa fa-refresh"></i> Reset
              </Button>
            </Col>
          </Row>

          <AgGridComponent
            rowData={courierData}
            columnDefs={columnDefs}
            height={500}
            paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
