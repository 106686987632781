import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/authActions";
import "./Login.css"

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [UserNameValidation, setUserNameValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);


  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.loading);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ username, password }));
  }

  const CheckLogin =  (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(login({ username, password }));
    }
  };


  const validate = () => {
    if (username.trim() === "" || password.trim() === "") {
      if (username.trim() === "") {
        setUserNameValidation(true);
      }
      if (password.trim() === "") {
        setPasswordValidation(true);
      }
      return false;
    }
    return true; // Validation passed
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
 <div className="LoginSection">
         <div className="animetion">
          {/* <img src={photo} alt="" /> */}
        </div> 
        <div className="login">
          <form>
            {/* <div className="logo">
             <img
                src={LOGO}
                height="60"
                alt="TAPTI TECHNOLOGY"
                loading="lazy"
              />  *
            </div> */}
            <h2 className="text-center"> 
              Hello
              <br />
              <span> Welcome To Maa Consultancy</span>
            </h2>
            <div>
              <div className="inputBox" >
                <input
                  type="text"
                  style={{ border: UserNameValidation ? "2px solid #dc3545" : "" }}
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setUserNameValidation(false);
                  }}
                  placeholder="User Name"
                />
                <i class="fa fa-user"></i>
              </div>
              {UserNameValidation && (
                <div className="ValidationErrorMsg mx-3">
                  Please select an UserName.
                </div>
              )}
            </div>
            <div>
              <div className="inputBox">
                <input
                  type={showPassword ? "text" : "password"}
                  style={{ border: passwordValidation ? "2px solid #dc3545" : "" }}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordValidation(false);
                  }}
                  placeholder="Password"
                />
                <i class="fa fa-lock"></i>
                <span
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  ></i>
                </span>
              </div>
              {passwordValidation && (
                <div className="ValidationErrorMsg mx-3 ">
                  Please select an Password.
                </div>
              )}
            </div>
            <div className="inputBox align-bottom">
              <input type="submit" value="Log In" onClick={CheckLogin} />
            </div>
          </form>
        </div>
      </div> 
    </>
  )
}
