import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Card, Button, InputGroup, Form } from "react-bootstrap";
import api from "../../Utils/api";
import UserSetting from "../../UserSettings.json";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import ImageAddAndPreview from "../../Common/ImageAddAndPreview";
import GlobalCheckbox from "../../Common/GlobalCheckbox";
import GlobalDDComponent from "../../Common/GlobalDDComponent";
import ClientMasterComponent from "../ClientMaster/ClientMasterComponent";
import PolicyTypeComponent from "../PolicyType/PolicyTypeComponent";
import InsuranceCompanyComponent from "../InsuranceCompany/InsuranceCompanyComponent";
import AgentMasterComponent from "../AgentMaster/AgentMasterComponent";
import TPAMasterComponent from "../TPAMaster/TPAMasterComponent";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { format } from "date-fns";
import TextArea from "../../Common/TextArea";
import { getCurrentDate, GetFutureDate ,appendIfNotNull} from "../../Utils/commonFunction";
import CustomCheckbox from "../../Common/CustomCheckbox";
import { getSingleDataReturn } from "../../Utils/CallApiOnlyReturn";
import Swal from "sweetalert2";
import { showMessage } from "../../Utils/showMessageUtils";


const emptyPolicy = {
  PolicyID: 0,
  ClientMasterID: 0,
  InsuranceCompanyID: 0,
  TPAMasterID: 0,
  PolicyNo: "",
  ImagePath: "",
  PolicyTypeID: 0,
  PreviousPolicyNo: "",
  PolicyStartDate: getCurrentDate(),
  PolicyEndDate: GetFutureDate(),
  PremiumAmt: "",
  ServiceTax: 18,
  RenewalFlag: false,
  Mobile: "",
  EmailIdTo: "",
  EmailIdCC: "",
  EmailIdBcc: "",
  InternalRemarks: "",
  ExternalRemarks: "",
  NetAmount: "",
  ServiceTaxAmount: "",
  PolicyHolderName: "",
  DateOfBirth: "",
  SendWhatsAppSMS: true,
  SendTextSMS: true,
  SendEmail: true,
  AgentMasterID: 0,
};





export default function PolicyComponent({
  editedData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const PolicyFormRef = useRef(null);
  const [formData, setFormData] = useState(emptyPolicy);
  const [selectedImage, setSelectedImage] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [clientDDValidationError, setClientDDValidationError] = useState(false);
  const [
    insuranceCompanyDDValidationError,
    setInsuranceCompanyDDValidationError,
  ] = useState(false);
  const [tpaMasterDDValidationError, setTpaMasterDDValidationError] =
    useState(false);
  const [policyTypeDDValidationError, setPolicyTypeDDValidationError] =
    useState(false);
  const { setIsLoading } = useLoadingContext();
  const [agentDDValidationError, setAgentDDValidationError] = useState(false);

  const formatDateOrEmpty = (date) => (date ? format(date, "yyyy-MM-dd") : "");

  useEffect(() => {
    if (editedData) {
      setFormData((prevData) => ({
        ...prevData,
        PolicyID: editedData.policyID,
        ClientMasterID: editedData.clientMasterID,
        InsuranceCompanyID: editedData.insuranceCompanyID,
        TPAMasterID: editedData.tpaMasterID,
        PolicyNo: editedData.policyNo,
        PolicyTypeID: editedData.policyTypeID,
        PreviousPolicyNo: editedData.previousPolicyNo,
        PolicyStartDate: formatDateOrEmpty(editedData.policyStartDate),
        PolicyEndDate: formatDateOrEmpty(editedData.policyEndDate),
        PremiumAmt: editedData.premiumAmt,
        ServiceTax: editedData.serviceTax,
        RenewalFlag: editedData.renewalFlag,
        Mobile: editedData.mobile,
        EmailIdTo: editedData.emailIdTo,
        EmailIdCC: editedData.emailIdCC,
        NetAmount: editedData.netAmount,
        ServiceTaxAmount: editedData.serviceTaxAmount,
        InternalRemarks: editedData.internalRemarks || "",
        ExternalRemarks: editedData.externalRemarks || "",
        PolicyHolderName: editedData.policyHolderName,
        DateOfBirth: formatDateOrEmpty(editedData.dateOfBirth),
        AgentMasterID: editedData.agentMasterID || 0,
      }));
  
      if (editedData.imagePath) {
        setImagePreview([UserSetting.imgURL + editedData.imagePath]);
      }
    }
  }, [editedData]);
  

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: inputValue };
      if (name === "PolicyStartDate") {
        const endDate = calculateEndDate(inputValue);
        updatedData.PolicyEndDate = endDate;
      }

      if (name === "NetAmount" || name === "ServiceTax") {
        return calculateTaxAndPremium(updatedData);
      }
      return updatedData;
    });
  };

  const calculateEndDate = (startDate) => {
    const date = new Date(startDate);
    if (!isNaN(date.getTime()) || date.getMonth() > 11 || date.getDate() > 31) {
      date.setFullYear(date.getFullYear() + 1);
      date.setDate(date.getDate() - 1);
      return date.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
    } else {
      return null;
    }
  };

  const calculateTaxAndPremium = (data) => {
    const totalPrice = parseFloat(data.NetAmount) || 0;
    const gstRate = parseFloat(data.ServiceTax) || 0;

    // Convert the GST rate to decimal
    const gstRateDecimal = gstRate / 100;

    // Calculate the Pre-GST Price
    const preGstPrice = totalPrice / (1 + gstRateDecimal);

    // Calculate the GST Amount
    const gstAmount = totalPrice - preGstPrice;

    return {
      ...data,
      ServiceTaxAmount: gstAmount.toFixed(2),
      PremiumAmt: preGstPrice.toFixed(2),
    };
  };

  const InsertAndCheckPolicyNo = (event) => {
    if (formData.PolicyNo === "") {
      showMessage(400, "Enter Policy No!");
      return;
    }
    api
      .get(`Policy/CheckPolicyNoIsHaveOrNot?PolicyNo=${formData.PolicyNo}`)
      .then((result) => {
        if (result.data.statusCode === 200) {
          debugger
          insertPolicy(event);
        }
        else{
          showMessage(result.data.statusCode, result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showMessage(400, "An error occurred");
      });
  };

  const insertPolicy = (event) => {
    debugger
    setValidated(true);
    setIsLoading(true);
    event.preventDefault();
    const form = PolicyFormRef.current;

    if (
      !form.checkValidity() ||
      formData.ClientMasterID === 0 ||
      formData.TPAMasterID === 0 ||
      formData.PolicyTypeID === 0 ||
      formData.InsuranceCompanyID === 0 ||
      formData.AgentMasterID === 0
    ) {
      setClientDDValidationError(formData.ClientMasterID === 0);
      setTpaMasterDDValidationError(formData.TPAMasterID === 0);
      setPolicyTypeDDValidationError(formData.PolicyTypeID === 0);
      setAgentDDValidationError(formData.AgentMasterID ===0);
      setInsuranceCompanyDDValidationError(formData.InsuranceCompanyID === 0);
      setIsLoading(false);
      return;
    } else {
      const form_Data = new FormData();

      appendIfNotNull(form_Data, "PolicyID", formData?.PolicyID || 0);
      appendIfNotNull(form_Data, "ClientMasterID", formData.ClientMasterID);
      appendIfNotNull(form_Data, "InsuranceCompanyID", formData.InsuranceCompanyID);
      appendIfNotNull(form_Data, "AgentMasterID", formData.AgentMasterID === 0 ? "" : formData.AgentMasterID);
      appendIfNotNull(form_Data, "TPAMasterID", formData.TPAMasterID);
      appendIfNotNull(form_Data, "PolicyNo", formData.PolicyNo);
      appendIfNotNull(form_Data, "ImagePath", formData.ImagePath);
      appendIfNotNull(form_Data, "PolicyTypeID", formData.PolicyTypeID);
      appendIfNotNull(form_Data, "PreviousPolicyNo", formData.PreviousPolicyNo);
      appendIfNotNull(form_Data, "PolicyStartDate", formData.PolicyStartDate);
      appendIfNotNull(form_Data, "PolicyEndDate", formData.PolicyEndDate);
      appendIfNotNull(form_Data, "PremiumAmt", formData.PremiumAmt);
      appendIfNotNull(form_Data, "ServiceTax", formData.ServiceTax);
      appendIfNotNull(form_Data, "RenewalFlag", formData.RenewalFlag);
      appendIfNotNull(form_Data, "Mobile", formData.Mobile);
      appendIfNotNull(form_Data, "EmailIdTo", formData.EmailIdTo);
      appendIfNotNull(form_Data, "EmailIdCC", formData.EmailIdCC);
      appendIfNotNull(form_Data, "EmailIdBcc", formData.EmailIdBcc);
      appendIfNotNull(form_Data, "InternalRemarks", formData.InternalRemarks);
      appendIfNotNull(form_Data, "ExternalRemarks", formData.ExternalRemarks);
      appendIfNotNull(form_Data, "NetAmount", formData.NetAmount);
      appendIfNotNull(form_Data, "ServiceTaxAmount", formData.ServiceTaxAmount);
      appendIfNotNull(form_Data, "PolicyHolderName", formData.PolicyHolderName);
      appendIfNotNull(form_Data, "DateOfBirth", formData.DateOfBirth);
      appendIfNotNull(form_Data, "SendWhatsAppSMS", formData.SendWhatsAppSMS);
      appendIfNotNull(form_Data, "SendTextSMS", formData.SendTextSMS);
      appendIfNotNull(form_Data, "SendEmail", formData.SendEmail);
      
      form_Data.append("image", selectedImage);

      // for (let i = 0; i < selectedImage.length; i++) {
      //   form_Data.append(`image[${i}]`, selectedImage[i]);
      // }

      const endpoint =
        formData.PolicyID === 0 ? "Policy/InsertPolicy" : "Policy/EditPolicy";

      api[formData.PolicyID === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          showMessage(result.data.statusCode, result.data.message);
          if (result.status === 200) {
            if (returnID) {
              returnID(result.data.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(400, "An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
          setValidated(false);
          setIsLoading(false);
        });
    }
  };

  const reset = useCallback(() => {
    setFormData(emptyPolicy);
    setImagePreview(null);
    setSelectedImage([]);
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  }, []);

  useEffect(() => {
    if (clearData === false) {
      reset();
    }
  }, [clearData, reset]);

  const handleImageUpload = async (imageFile) => {
    setSelectedImage(imageFile);
  };

  const handlePreviousPolicyNoKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePolicyByPreviousPolicyNo(e.target.value);
    }
  };

  const handlePolicyByPreviousPolicyNo = (previousPolicyNo) => {
    ;
    setIsLoading(true);
    api
      .get(
        `Policy/SinglePolicyByPreviousPolicyNo?PreviousPolicyNo=${previousPolicyNo}`
      )
      .then((result) => {
        if (result.data.statusCode === 200) {
          const PreviousData = result.data.data;
          const policyStartDate = new Date(PreviousData.policyEndDate);
          policyStartDate.setDate(policyStartDate.getDate() + 1);

          setFormData((prevData) => ({
            ...prevData,
            PolicyID: 0,
            ClientMasterID: PreviousData.clientMasterID,
            InsuranceCompanyID: PreviousData.insuranceCompanyID,
            AgentMasterID: PreviousData.agentMasterID || 0,
            TPAMasterID: PreviousData.tpaMasterID,
            PolicyNo: "",
            PolicyTypeID: PreviousData.policyTypeID,
            PreviousPolicyNo: PreviousData.policyNo,
            PolicyStartDate:
              policyStartDate && format(policyStartDate, "yyyy-MM-dd"),
            PolicyEndDate:
              policyStartDate &&
              calculateEndDate(format(policyStartDate, "yyyy-MM-dd")),
            PremiumAmt: formData.PremiumAmt,
            ServiceTax: formData.ServiceTax,
            RenewalFlag: PreviousData.renewalFlag,
            Mobile: PreviousData.mobile,
            EmailIdTo: PreviousData.emailIdTo,
            EmailIdCC: PreviousData.emailIdCC,
            NetAmount: formData.NetAmount,
            ServiceTaxAmount: formData.ServiceTaxAmount,
            InternalRemarks:
              PreviousData.internalRemarks && PreviousData.internalRemarks,
            ExternalRemarks:
              PreviousData.externalRemarks && PreviousData.externalRemarks,
            PolicyHolderName: PreviousData.policyHolderName,
            DateOfBirth:
              PreviousData.dateOfBirth &&
              format(PreviousData.dateOfBirth, "yyyy-MM-dd"),
          }));
          ShowMsgAtPreviousPolicyNo(previousPolicyNo);
        } else {
          showMessage(400, result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showMessage(400, "An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ShowMsgAtPreviousPolicyNo = (No) => {
    api
      .get(`Policy/TotalCountRenewalofPolicy?PreviousPolicyNo=${No}`)
      .then((result) => {
        Swal.fire({
          title: "Previous PolicyNo",
          text: result.data.message,
          showCancelButton: false,
          showConfirmButton: true,
          cancelButtonColor: "#d33",
        });
        return result.data.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handelClientSelect = async (id) => {
    const Result = await getSingleDataReturn(
      `ClientMaster/SingleClient?ClientMasterID=${id}`,
      setIsLoading
    );
    if (Result) {
      setFormData((prevData) => ({
        ...prevData,
        Mobile: Result.clientMobileNo,
        EmailIdTo: Result.clientEmailID,
      }));
    }
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={PolicyFormRef}
        formName="PolicyForm"
      >
        <Row>
          {/* <InputGroupWithIcon
            Label="Previous PolicyNo"
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            icon="far fa-file-alt"
            type="text"
            name="PreviousPolicyNo"
            placeholder="Enter Previous PolicyNo"
            value={formData.PreviousPolicyNo}
            onChange={handleInputChange}
            onKeyPress={handelPreviousPolicyNo}
          /> */}
          <Col md={6} sm={12} lg={3}>
            <Form.Group controlId="PolicyNo">
              <Form.Label>Previous PolicyNo:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-file-alt"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="PreviousPolicyNo"
                  placeholder="Enter Policy No"
                  value={formData.PreviousPolicyNo}
                  onChange={handleInputChange}
                  onKeyPress={handlePreviousPolicyNoKeyPress}
                  className="RemoveBorderRadiusTextBox"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter policyNo
                </Form.Control.Feedback>
                <InputGroup.Text id="basic-addon1" style={{ padding: "1px" }}>
                  <Button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#20336b",
                      color: "white",
                      borderRadius: "0px",
                    }}
                    variant="primary"
                    onClick={() => {
                      handlePolicyByPreviousPolicyNo(formData.PreviousPolicyNo);
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
          <InputGroupWithIcon
            Label="Policy Number"
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            icon="far fa-file-alt"
            type="text"
            name="PolicyNo"
            placeholder="Enter Policy Number"
            value={formData.PolicyNo}
            onChange={handleInputChange}
            required
          />
          <GlobalDDComponent
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            formLabel="Client"
            apiEndpoint="ClientMaster/AllClients"
            valueOfDD="clientMasterID"
            labelOfDD="clientName"
            onSelectOption={(clientID) => {
              setFormData((prevData) => ({
                ...prevData,
                ClientMasterID: clientID,
              }));
              setClientDDValidationError(false);
              handelClientSelect(clientID);
            }}
            selectedOptionOfComponent={formData.ClientMasterID}
            ValidationError={clientDDValidationError}
            offcanvasTitle="Add Client"
            offcanvasBodyComponent={<ClientMasterComponent />}
          />
          <InputGroupWithIcon
            Label="Policy HolderName"
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            icon="far fa-user"
            type="text"
            name="PolicyHolderName"
            placeholder="Enter Policy Holder Name"
            value={formData.PolicyHolderName}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <GlobalDDComponent
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            formLabel="Agent"
            apiEndpoint="AgentMaster/AllAgents"
            valueOfDD="agentMasterID"
            labelOfDD="agentMasterName"
            onSelectOption={(agentMasterID) => {
              setFormData((prevData) => ({
                ...prevData,
                AgentMasterID: agentMasterID,
              }));
              setAgentDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.AgentMasterID}
            ValidationError={agentDDValidationError}
            offcanvasTitle="Add Insurance Company"
            offcanvasBodyComponent={<AgentMasterComponent />}
          />
          <InputGroupWithIcon
            Label="DateOfBirth"
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            icon="fas fa-calendar-alt"
            type="date"
            name="DateOfBirth"
            // placeholder="Enter Previous Policy Number"
            value={formData.DateOfBirth}
            onChange={handleInputChange}
          />
          <GlobalDDComponent
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            formLabel="Insurance Company"
            apiEndpoint="InsuranceCompany/AllInsuranceCompanies"
            valueOfDD="insuranceCompanyID"
            labelOfDD="insuranceCompanyName"
            onSelectOption={(insuranceCompanyID) => {
              setFormData((prevData) => ({
                ...prevData,
                InsuranceCompanyID: insuranceCompanyID,
              }));
              setInsuranceCompanyDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.InsuranceCompanyID}
            ValidationError={insuranceCompanyDDValidationError}
            offcanvasTitle="Add Insurance Company"
            offcanvasBodyComponent={<InsuranceCompanyComponent />}
          />

          <GlobalDDComponent
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            formLabel="TPA Master"
            apiEndpoint="TPAMaster/AllTPAMasters"
            valueOfDD="tpaMasterID"
            labelOfDD="tpaMasterName"
            onSelectOption={(tpaMasterID) => {
              setFormData((prevData) => ({
                ...prevData,
                TPAMasterID: tpaMasterID,
              }));
              setTpaMasterDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.TPAMasterID}
            ValidationError={tpaMasterDDValidationError}
            offcanvasTitle="Add TPA Master"
            offcanvasBodyComponent={<TPAMasterComponent />}
          />

          <GlobalDDComponent
            ColSize={{ sm: 12, md: 6, lg: 3 }}
            formLabel="Policy Type"
            apiEndpoint="PolicyType/AllPolicyType"
            valueOfDD="policyTypeID"
            labelOfDD="policyTypeName"
            onSelectOption={(policyTypeID) => {
              setFormData((prevData) => ({
                ...prevData,
                PolicyTypeID: policyTypeID,
              }));
              setPolicyTypeDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.PolicyTypeID}
            ValidationError={policyTypeDDValidationError}
            offcanvasTitle="Add Policy Type"
            offcanvasBodyComponent={<PolicyTypeComponent />}
          />
        </Row>
        <Row className="mt-4 ">
          <Col lg={6} md={12} sm={12} className="p-0">
            <Card className="p-3">
              <Row>
                <InputGroupWithIcon
                  Label="Policy Start Date"
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  icon="fas fa-calendar-alt"
                  type="date"
                  name="PolicyStartDate"
                  // placeholder="Enter Previous Policy Number"
                  value={formData.PolicyStartDate}
                  onChange={handleInputChange}
                  required
                />
                <InputGroupWithIcon
                  Label="Policy End Date"
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  icon="fas fa-calendar-alt"
                  type="date"
                  name="PolicyEndDate"
                  // placeholder="Enter Previous Policy Number"
                  value={formData.PolicyEndDate}
                  onChange={handleInputChange}
                  required
                />
              </Row>
              <Row>
                <InputGroupWithIcon
                  Label="NetAmount"
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="NetAmount"
                  placeholder="Enter NetAmount"
                  value={formData.NetAmount}
                  onChange={handleInputChange}
                  required
                />
                <InputGroupWithIcon
                  Label="Service Tax"
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  icon="fas fa-percentage"
                  type="number"
                  name="ServiceTax"
                  placeholder="Enter Service Tax"
                  value={formData.ServiceTax}
                  onChange={handleInputChange}
                  required
                />
              </Row>
              <Row>
                <InputGroupWithIcon
                  Label="Service TaxAmount"
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="ServiceTaxAmount"
                  placeholder="Enter Service TaxAmount"
                  value={formData.ServiceTaxAmount}
                  onChange={handleInputChange}
                  disabled
                />

                <InputGroupWithIcon
                  Label="Premium Amount"
                  ColSize={{ sm: 12, md: 6, lg: 6 }}
                  icon="fas fa-rupee-sign"
                  type="number"
                  name="PremiumAmt"
                  placeholder="Enter Premium Amount"
                  value={formData.PremiumAmt}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </Row>
            </Card>
          </Col>

          <Col lg={6} md={12} sm={12} className="p-0">
            <Card className="p-3">
              <InputGroupWithIcon
                Label="Mobile Number"
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                icon="fas fa-mobile-alt"
                type="text"
                name="Mobile"
                placeholder="Enter Mobile Number"
                value={formData.Mobile}
                onChange={handleInputChange}
                required
              />
              <InputGroupWithIcon
                Label="Email To"
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                icon="fas fa-envelope"
                type="text"
                name="EmailIdTo"
                placeholder="Enter Email To"
                value={formData.EmailIdTo}
                onChange={handleInputChange}
              />
              <InputGroupWithIcon
                Label="Email CC"
                ColSize={{ sm: 12, md: 12, lg: 12 }}
                icon="fas fa-envelope"
                type="text"
                name="EmailIdCC"
                placeholder="Enter Email CC"
                value={formData.EmailIdCC}
                onChange={handleInputChange}
              />
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <TextArea
            ColSize={{ sm: 12, md: 12, lg: 4 }}
            rows={5}
            Label="Internal Remarks"
            icon="fas fa-pencil-alt"
            name="InternalRemarks"
            placeholder="Internal Remarks"
            value={formData.InternalRemarks}
            onChange={handleInputChange}
          />
          <TextArea
            ColSize={{ sm: 12, md: 12, lg: 4 }}
            rows={5}
            Label="External Remarks"
            icon="fas fa-pencil-alt"
            name="ExternalRemarks"
            placeholder="External Remarks"
            value={formData.ExternalRemarks}
            onChange={handleInputChange}
          />
          <Col md={12} lg={4} sm={12}>
            <ImageAddAndPreview
              imagePreview={imagePreview}
              ClearImages={refreshKey}
              Label="Image"
              onFileUpload={handleImageUpload}
              ColSize={{ sm: 12, md: 12, lg: 12 }}
              required={editedData ? false : true}
            />
            {editedData && (
              <a href={imagePreview} target="_blank" rel="noopener noreferrer">
                <Button className="mb-3">Show Policy</Button>
              </a>
            )}

            {editedData && (
              <GlobalCheckbox
                label="Renewal Flag"
                name="RenewalFlag"
                checked={formData.RenewalFlag}
                onChange={handleInputChange}
              />
            )}
            {/* <div className="d-flex">
              <GlobalCheckbox
                label="SendWhatsAppSMS"
                name="SendWhatsAppSMS"
                checked={formData.SendWhatsAppSMS}
                onChange={handleInputChange}
              />
              <GlobalCheckbox
                label="SendTextSMS"
                name="SendTextSMS"
                checked={formData.SendTextSMS}
                onChange={handleInputChange}
              />
              <GlobalCheckbox
                label="Send Email"
                name="SendEmail"
                checked={formData.SendEmail}
                onChange={handleInputChange}
              />
            </div> */}
            {!editedData && (
              <div className="d-flex">
                <CustomCheckbox
                  icon="fab fa-whatsapp"
                  iconColor="Green"
                  name="SendWhatsAppSMS"
                  checked={formData.SendWhatsAppSMS}
                  onChange={handleInputChange}
                />
                <CustomCheckbox
                  icon="fas fa-sms"
                  iconColor="#4290f5"
                  name="SendTextSMS"
                  checked={formData.SendTextSMS}
                  onChange={handleInputChange}
                />
                <CustomCheckbox
                  icon="fas fa-envelope"
                  iconColor="#ed4a3d"
                  name="SendEmail"
                  checked={formData.SendEmail}
                  onChange={handleInputChange}
                />
              </div>
            )}
          </Col>
        </Row>
        <OnClickSubmitButtonOfForm
          onClick={
            formData?.PolicyID > 0 ? insertPolicy : InsertAndCheckPolicyNo
          }
          HeadID={formData?.PolicyID}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
