import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import api from "../Utils/api";

export default function TypeaheadComponent({ onClientSelect, onClear, editTimeSelectedClient ,style}) {

  const [clientOptions, setClientOptions] = useState([]);
  const [selectedclients, setSelectedClients] = useState([]); // Changed to an array

  useEffect(() => {

    if (editTimeSelectedClient) {
      setSelectedClients(editTimeSelectedClient);
    }
  }, [editTimeSelectedClient]);

  useEffect(() => {

    if (onClear) {
      setClientOptions([]);
      setSelectedClients([]);
    }
  }, [onClear]);

  const handleSearch = async (query) => {
    try {
      setSelectedClients([]);
      if (query.length >= 2) {
        api
          .get(`ClientMaster/SearchClient?ClientName=${query}`)
          .then((result) => {
            var data = result.data.data;
            const options = data.map((item) => ({
              clientID: item.clientMasterID,
              clientName: item.clientName,
            }));
            setClientOptions(options);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setClientOptions([]);
      }
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handleInputChange = (selectedOptions) => {

    if (selectedOptions.length > 0) {
      setSelectedClients(
        selectedOptions.map((option) => ({
          clientID: option.clientID,
          clientName: option.clientName,
        }))
      );
      var ClientID = selectedOptions[0].clientID;
      onClientSelect(ClientID);
    };
  }

  return (
    <Typeahead
      id="clientTypeahead"
      labelKey="clientName" // Corrected to match the key in clientOptions
      options={clientOptions}
      placeholder={`Search for a Client`}
      onChange={handleInputChange}
      selected={selectedclients}
      onInputChange={handleSearch}
      className="typeHade"
      style={style}
    />
  );
}
