import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import AgGridComponent from "../../Common/AgGridComponent";
import api from "../../Utils/api";
import {
  Card,
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  Table,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

export default function RoleTemplate({ insertRecord, hideAgGrid }) {
  const [roleTemplateData, setRoleTemplateData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [agGridHide, setAgGridHide] = useState(hideAgGrid);
  const [formData, setFormData] = useState({
    roleTemplateID: 0,
    roleTemplateName: "",
  });
  const { setIsLoading } = useLoadingContext();
  const [listOfForms, setListOfForms] = useState([]);
  const [selectAllView, setSelectAllView] = useState(false);
  const [selectAllAdd, setSelectAllAdd] = useState(false);
  const [selectAllEdit, setSelectAllEdit] = useState(false);
  const [selectAllDelete, setSelectAllDelete] = useState(false);

  useEffect(() => {
    getRoleTemplateName();
    getForm();
  }, []);

  // Fetch all Areas
  const getRoleTemplateName = () => {
    api
      .get("RoleTemplateDetail/AllRoleTemplate")
      .then((result) => {
        setRoleTemplateData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch all departments
  const getForm = () => {
    api
      .get("Form/AllForm")
      .then((result) => {
        const data = result.data?.data || [];
        setListOfForms(
          data.map((form) => ({
            formID: form.formID,
            formName: form.formName,
            viewPermission: false,
            addPermission: false,
            editPermission: false,
            deletePermission: false,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (formID, field) => {
    setListOfForms((prevForms) =>
      prevForms.map((item) =>
        item.formID === formID ? { ...item, [field]: !item[field] } : item
      )
    );
  };

  // Save role template details
  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);

    if (!formData.roleTemplateName.trim()) {
      showMessage(400,"Role template name is required.");
      return;
    }
    setIsLoading(true); // Set loading to true
    const data = {
      RoleTemplateDetailList: listOfForms,
      RoleTemplateName: formData.roleTemplateName,
      RoleTemplateID: formData.roleTemplateID,
    };
    const endpoint =
      formData.roleTemplateID === 0
        ? "RoleTemplateDetail/InsertRoleTemplate"
        : "RoleTemplateDetail/EditRoleTemplate";

    api[formData.roleTemplateID === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        getRoleTemplateName();
        getForm();
        setSelectAllView(false);
        setSelectAllAdd(false);
        setSelectAllEdit(false);
        setSelectAllDelete(false);
        setValidated(false);
        setIsLoading(false); // Reset validation state
        formData?.area_id === 0
          ? showMessage(200,result.data.message)
          : showMessage(200,result.data.message);
        if (agGridHide !== undefined) {
          insertRecord(result.data.data);
          setAgGridHide(false);
        }
      })
      .catch((error) => {
        console.log(error);
        showMessage(400,"An error occurred")
      });
    resetForm();
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Role Template Name",
      field: "roleName",
      headerClass: "widthTableColum",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Edit",
      width: 100,
      field: "roleTemplateID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleRoleTemplate(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "roleTemplateID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteRoleTemplate(value)}
        ></ion-icon>
      ),
    },
  ];

  // Get data of a single Area
  const getSingleRoleTemplate = (id) => {
    api
      .get(`RoleTemplateDetail/SingleRoleTemplate?RoleTemplateID=${id}`)
      .then((result) => {
        const RoleTemplateData = result.data.data;
        const uniqueFormIDs = [
          ...new Set(RoleTemplateData.map((form) => form.formID)),
        ];
        api.get(`Form/AllForm`).then((formResult) => {
          const allForms = formResult.data.data;
          // Filter out forms that are not in RoleTemplateData
          const missingForms = allForms.filter(
            (form) => !uniqueFormIDs.includes(form.formID)
          );
          const updatedListOfForms = RoleTemplateData.map((form) => ({
            roleTemplateDetailID: form.roleTemplateDetailID,
            formID: form.formID,
            formName: form.formName,
            viewPermission: form.viewPermission,
            addPermission: form.addPermission,
            editPermission: form.editPermission,
            deletePermission: form.deletePermission,
          }));

          // Add missing forms to the updated list
          updatedListOfForms.push(
            ...missingForms.map((form) => ({
              roleTemplateDetailID: 0,
              formID: form.formID,
              formName: form.formName,
              viewPermission: false,
              addPermission: false,
              editPermission: false,
              deletePermission: false,
            }))
          );

          // Sort the updatedListOfForms by formName in alphabetical order
          updatedListOfForms.sort((a, b) =>
            a.formName.localeCompare(b.formName)
          );
          setFormData({
            roleTemplateID: RoleTemplateData[0].roleTemplateID,
            roleTemplateName: RoleTemplateData[0].roleName,
          });
          setListOfForms(updatedListOfForms);
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const emptyRoleTemplate = {
    roleTemplateID: 0,
    roleTemplateName: "",
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyRoleTemplate);
  };

  // Delete Area
  const deleteRoleTemplate = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`RoleTemplateDetail/DeleteRoleTemplate?RoleTemplateID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getRoleTemplateName();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const handleSelectAll = (permissionType) => {
    switch (permissionType) {
      case "view":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({ ...item, viewPermission: !selectAllView }))
        );
        setSelectAllView(!selectAllView);
        break;
      case "add":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({ ...item, addPermission: !selectAllAdd }))
        );
        setSelectAllAdd(!selectAllAdd);
        break;
      case "edit":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({ ...item, editPermission: !selectAllEdit }))
        );
        setSelectAllEdit(!selectAllEdit);
        break;
      case "delete":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({
            ...item,
            deletePermission: !selectAllDelete,
          }))
        );
        setSelectAllDelete(!selectAllDelete);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Card className="RoleTemplate">
        <Card.Header>
          <span>New Role Template</span>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="roleTemplateName">
                <Form.Label>Role Template Name:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fas fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="roleTemplateName"
                    placeholder="Enter Role Template Name"
                    value={formData.roleTemplateName}
                    onChange={handleInputChange}
                    required
                  />
                  {validated && !formData.roleTemplateName && (
                    <div className="invalid-feedback">
                      Please Enter Role Template Name.
                    </div>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="RoleTemplate RoleTemplateScrollable-container " >
        <Card.Header>
          <span>Role Template Details List</span>
        </Card.Header>
        <Card.Body>
          <Table bordered>
            <thead style={{ color: "#fff", backgroundColor: "#263369" }}>
              <tr>
                <th>FormName</th>
                <th>
                  <div className="d-flex align-items-center">
                    Show
                    <Form.Check
                      type="checkbox"
                      className="ms-2"
                      checked={selectAllView}
                      onChange={() => handleSelectAll("view")}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Add
                    <Form.Check
                      type="checkbox"
                      className="ms-2"
                      checked={selectAllAdd}
                      onChange={() => handleSelectAll("add")}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Edit
                    <Form.Check
                      type="checkbox"
                      className="ms-2"
                      checked={selectAllEdit}
                      onChange={() => handleSelectAll("edit")}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Delete
                    <Form.Check
                      type="checkbox"
                      className="ms-2"
                      checked={selectAllDelete}
                      onChange={() => handleSelectAll("delete")}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {listOfForms &&
                listOfForms.map((item) => (
                  <tr key={item.formID}>
                    <td>{item.formName}</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={item.viewPermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "viewPermission")
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={item.addPermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "addPermission")
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={item.editPermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "editPermission")
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={item.deletePermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "deletePermission")
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="card-footer">
            <Form onSubmit={handleSubmit}>
              <Button type="submit" className="btn btn-tapti">
                {formData.roleTemplateID > 0 ? "Update" : "Save"}
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>

      {!agGridHide && (
        <Card>
          <Card.Header>
            <span>Role Template List</span>
          </Card.Header>
          <Card.Body>
            <AgGridComponent
              rowData={roleTemplateData}
              columnDefs={columnDefs}
              height={500} paginationPageSize={25}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

