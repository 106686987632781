import api from "../Utils/api";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHECK_TOKEN_VALIDITY,
  LOGOUT,
} from "../actionTypes";
import {
  encryptAndSetItemCookiesStorage,
  decryptAndGetItemCookiesStorage,
  removeEncryptCookiesStorage,
} from "../Utils/CookiesStorage";
import {showMessage} from "../Utils/showMessageUtils";

export const login = (credentials) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const response = await api.post("/Login/CheckLogin", credentials);
    showMessage(response.data.statusCode, response.data.message);
    if (response.data.statusCode === 200) {
      const { token, userNameForPrint, uid } = response.data.data;

      encryptAndSetItemCookiesStorage("token", token);
      encryptAndSetItemCookiesStorage("userName", userNameForPrint);
      encryptAndSetItemCookiesStorage("user_ID", uid);
      encryptAndSetItemCookiesStorage("WhatsAppStatus", true);
      window.location.reload(false);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token: token, userName: userNameForPrint, userId: uid },
      });

      return response.data;
    } else {
      dispatch({
        type: LOGIN_FAILURE,
        error: response.data.message || "Login failed",
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      error: error.response?.data?.message || "Login failed",
    });
  }
};

export const checkTokenValidity = () => async (dispatch) => {
  const token = decryptAndGetItemCookiesStorage("token");
  if (!token) {
    dispatch(Logout());
    return false;
  }
  try {
    const response = await api.post(`Login/IsTokenExpired?token=${token}`);
    if (response.data.data) {
      dispatch({ type: CHECK_TOKEN_VALIDITY, payload: true });
      return true;
    } else {
      dispatch(Logout());
      window.location.href = "/logout";
      return false;
    }
  } catch (error) {
    dispatch(Logout());
    window.location.href = "/logout";
    return false;
  }
};

export const Logout = () => (dispatch) => {
  removeEncryptCookiesStorage("token");
  removeEncryptCookiesStorage("userName");
  removeEncryptCookiesStorage("user_ID");
  removeEncryptCookiesStorage("WhatsAppStatus");
  dispatch({ type: LOGOUT });
};

export const isLoggedIn = () => {
  return !!decryptAndGetItemCookiesStorage("token");
};
