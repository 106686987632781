import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import DocumentTypeComponent from "./DocumentTypesComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function DocumentType() {
  const [documentTypeData, setDocumentTypeData] = useState([]);
  const [editDocumentTypeData, setEditDocumentTypeData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getDocumentTypeData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "DocumentType",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all document types
  const getDocumentTypeData = async () => {
    await getAllData(
      "DocumentType/AllDocumentTypes",
      setIsLoading,
      setDocumentTypeData
    );
  };

  const getSingleDocumentType = async (id) => {
    await getSingleData(
      `DocumentType/SingleDocumentType?DocumentTypeID=${id}`,
      setEditDocumentTypeData,
      setIsLoading
    );
  };

  // Delete document type
  const deleteDocumentType = async (id) => {
    // You'll need to replace "DocumentType/DeleteDocumentType" with the appropriate API endpoint
    await deleteData(
      `DocumentType/DeleteDocumentType?DocumentTypeID=${id}`,
      getDocumentTypeData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("Document Type Name", "documentTypeName", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "documentTypeID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleDocumentType(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "documentTypeID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteDocumentType(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Document Type</span>
          </Card.Header>
          <Card.Body>
            <DocumentTypeComponent
              returnID={getDocumentTypeData}
              editedData={editDocumentTypeData}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>Document Type List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={documentTypeData}
            columnDefs={columnDefs}
            height={500} paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
