import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import {
  getAllData,
  getSingleData,
  deleteData,
  CheckRoleTemplatePermissions,
} from "../../Utils/CallApiUtils";
import { createColumnDef } from "../../Common/AgGridColumnDef";
import HospitalMasterComponent from "./HospitalMasterComponent"; // Assuming you'll create this component
import { useLoadingContext } from "../../Loading/LoadingContext";

export default function HospitalMaster() {
  const [hospitalData, setHospitalData] = useState([]);
  const [editHospitalData, setEditHospitalData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getHospitalData();
    UserPermissions();
  }, []);

  const UserPermissions = async () => {
    await CheckRoleTemplatePermissions(
      "HospitalMaster",
      setRoleTemplatePermissions,
      setIsLoading
    );
  };

  // Fetch all hospitals
  const getHospitalData = async () => {
    await getAllData("HospitalMaster/AllHospitals", setIsLoading ,setHospitalData);
  };

  const getSingleHospital = async (id) => {
    await getSingleData(
      `HospitalMaster/SingleHospital?HospitalMasterID=${id}`,
      setEditHospitalData,
      setIsLoading
    );
  };

  // Delete hospital
  const deleteHospital = async (id) => {
    await deleteData(
      `HospitalMaster/DeleteHospital?HospitalMasterID=${id}`,
      getHospitalData,
      setIsLoading
    );
  };

  const columnDefs = [
    createColumnDef("Hospital Name", "hospitalName", 150),
    createColumnDef("Address", "hospitalAddress", 150),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "hospitalMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleHospital(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "hospitalMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "centered-header",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteHospital(value)}
              ></ion-icon>
            ),
            pinned: window.innerWidth > 768 ? "right" : "",
          },
        ]
      : []),
  ];

  return (
    <>
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Hospital</span>
          </Card.Header>
          <Card.Body>
            <HospitalMasterComponent
              returnID={getHospitalData}
              editedData={editHospitalData}
            />
          </Card.Body>
        </Card>
      )}

      <Card>
        <Card.Header>
          <span>Hospital List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={hospitalData} columnDefs={columnDefs} height={500} paginationPageSize={25}/>
        </Card.Body>
      </Card>
    </>
  );
}
