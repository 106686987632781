import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyInsuranceCompany = {
  insurance_company_id: 0,
  insurance_company_name: "",
};

export default function InsuranceCompanyComponent({ returnID, editedData, clearData, onClose }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyInsuranceCompany);
  const insuranceCompanyFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        insurance_company_id: editedData.insuranceCompanyID,
        insurance_company_name: editedData.insuranceCompanyName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertInsuranceCompany = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = insuranceCompanyFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        InsuranceCompanyID: formData?.insurance_company_id || 0,
        InsuranceCompanyName: formData.insurance_company_name,
      };

      const endpoint =
        formData.insurance_company_id === 0
          ? "InsuranceCompany/InsertInsuranceCompany"
          : "InsuranceCompany/EditInsuranceCompany";

      api[formData.insurance_company_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);           
            if (returnID) {
              returnID(result.data.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyInsuranceCompany);
  };

  return (
    <>
      <GlobalFormTagWithSubmit validated={validated} refName={insuranceCompanyFormRef} formName="InsuranceCompanyForm">
        <Row>
          <InputGroupWithIcon
             ColSize={{ sm: 12, md: 8, lg: 6 }}
            Label="Insurance Company Name"
            icon="fas fa-user"
            type="text"
            name="insurance_company_name"
            placeholder="Enter Insurance Company Name"
            value={formData.insurance_company_name}
            onChange={handleInputChange}
            required
          />
        </Row>
        <OnClickSubmitButtonOfForm onClick={insertInsuranceCompany} HeadID={formData?.insurance_company_id} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
