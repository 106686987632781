import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Common/AgGridComponent";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import {
  getCurrentDate,
  convertDateFormat,
  GetPre30DayDate,
  filterParams
} from "../../Utils/commonFunction";
import { useLoadingContext } from "../../Loading/LoadingContext";

const DateTimeFilterData = {
  start_date: GetPre30DayDate(),
  end_date: getCurrentDate(),
  mobileNo: "",
};

export default function SMSHistory() {
  const [SMSHistory, setSMSHistory] = useState([]);
  const [formData, setFormData] = useState(DateTimeFilterData);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    getSMSHistory();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const getSMSHistory = () => {
    api
      .get(
        `SMSHistory/SMSHistory?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&MobileNo=${formData.mobileNo}`
      )
      .then(({ data }) => setSMSHistory(data.data))
      .catch(handleApiError);
  };

  const ClearDataAndDD = () => {
    setFormData(DateTimeFilterData);
    getSMSHistory();
  };

  // Export to Exel If Needed TO DateWise
  const exportToExcel = () => {
    setIsLoading(true);
    setTimeout(() => {
      const fileName = "SMSHistory_data.xlsx";

      // Format the data to be exported
      const formattedData = SMSHistory.map((item) => ({
        SMSDate: convertDateFormat(item.smsDate),
        Number: item.number,
        Msg: item.msg,
        Response: item.smsRes,
      }));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("SMS History");

      worksheet.columns = [
        { header: "SMS Date", key: "SMSDate", width: 20 },
        { header: "Number", key: "Number", width: 20 },
        { header: "Message", key: "Msg", width: 50 },
        { header: "Response", key: "Response", width: 50 },
      ];

      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });
      setIsLoading(false);
    }, 1000);
  };

  const columnDefs = [
    {
      headerName: "SMSDate",
      field: "smsDate",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 200,
      flex: 1,
      cellRenderer: ({ value }) => convertDateFormat(value),
    },
    {
      headerName: " Number",
      field: "number",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Msg",
      field: "msg",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 700,
      flex: 1,
    },
    {
      headerName: "Response",
      field: "smsRes",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 600,
      flex: 1,
    },
  ];

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <span> SMSHistory</span>
          <Button
            type="button"
            variant="success"
            className="mb-20px mr-4"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="mobileNo"
                    placeholder="Enter MobileNo"
                    value={formData.mobileNo}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={1} sm={1} lg={1}>
              <div className="d-flex">
                <Button variant="outline-success" onClick={getSMSHistory}>
                  Search
                </Button>
                <Button
                  onClick={ClearDataAndDD}
                  className="mx-2 outline-primaryOfTapti text-start"
                >
                  <i className="fa fa-refresh"></i>
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <AgGridComponent
            rowData={SMSHistory}
            columnDefs={columnDefs}
            height="70vh"
            paginationPageSize={25}
          />
        </Card.Body>
      </Card>
    </>
  );
}
