import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import GlobalFormTagWithSubmit from "../../Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Common/OnClickSubmitButtonOfForm";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { showMessage } from "../../Utils/showMessageUtils";

const emptyClient = {
  client_master_id: 0,
  client_name: "",
  client_mobileNo: "",
  client_email: "",
};

export default function ClientMasterComponent({
  returnID,
  editedData,
  clearData,
  onClose,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyClient);
  const clientFormRef = useRef(null);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (editedData) {
      setFormData({
        client_master_id: editedData.clientMasterID,
        client_name: editedData.clientName,
        client_mobileNo: editedData.clientMobileNo,
        client_email: editedData.clientEmailID,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedData]);

  useEffect(() => {
    if (clearData === false) {
      resetForm();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const insertClientMaster = (event) => {
    setValidated(true);
    setIsLoading(true);
    const form = clientFormRef.current;
    if (!form.checkValidity()) {
      setIsLoading(false);
      return;
    } else {
      const data = {
        ClientMasterID: formData?.client_master_id || 0,
        ClientName: formData.client_name,
        ClientMobileNo: formData.client_mobileNo,
        ClientEmailID: formData.client_email,
      };

      const endpoint =
        formData.client_master_id === 0
          ? "ClientMaster/InsertClient"
          : "ClientMaster/EditClient";

      api[formData.client_master_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          showMessage(result.data.statusCode,result.data.message)
          if (result.status === 200) {
            setValidated(false);
            if (returnID) {
              returnID(result.data.data);
            }
          } 
        })
        .catch((error) => {
          console.log(error);
          showMessage(400,"An error occurred")
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setFormData(emptyClient);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        refName={clientFormRef}
        formName="ClientForm"
      >
        <Row>
          <InputGroupWithIcon
           ColSize={{ sm: 12, md: 12, lg: 4 }}
            Label="Client Name"
            icon="fas fa-user"
            type="text"
            name="client_name"
            placeholder="Enter Client Name"
            value={formData.client_name}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
           ColSize={{ sm: 12, md: 12, lg: 4 }}
            Label="Mobile No"
            icon="fas fa-mobile"
            type="text"
            name="client_mobileNo"
            placeholder="Enter Mobile No"
            value={formData.client_mobileNo}
            onChange={handleInputChange}
            required
          />
          <InputGroupWithIcon
           ColSize={{ sm: 12, md: 12, lg: 4 }}
            Label="Email ID"
            icon="fas fa-envelope"
            type="text"
            name="client_email"
            placeholder="Enter Email"
            value={formData.client_email}
            onChange={handleInputChange}
            required
          />
        </Row>

        <OnClickSubmitButtonOfForm
          onClick={insertClientMaster}
          HeadID={formData?.client_master_id}
        />
      </GlobalFormTagWithSubmit>
    </>
  );
}
