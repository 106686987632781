import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";

export default function GlobalCheckbox({
  name,
  checked,
  onChange,
  required,
  label,
  ColSize,
}) {
  return (
    <>
      <Col
        md={ColSize ? ColSize : 4}
        sm={ColSize ? ColSize : 4}
        lg={ColSize ? ColSize : 4}
      >
        <Form.Group controlId="isActive">
          <Form.Check
            type="checkbox"
            label={label}
            name={name}
            checked={checked}
            onChange={onChange}
            required={required}
          />
        </Form.Group>
      </Col>
    </>
  );
}

// How to use
// const MyComponent = () => {
// const [details, setDetails] = useState("");
// const [ckValidated,setCkValidated] = useState(false);

// const handleInputChange = (UserMaster) => {
//   const { name, value, type, checked } = UserMaster.target;
//   const inputValue = type === "checkbox" ? checked : value;
//   setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
// };

//   return (
//        <GlobalCheckbox
// label="is Active"
// name="isActive"
// checked={formData.isActive}
// onChange={handleInputChange}
// />
//   );
// };

// export default MyComponent;
